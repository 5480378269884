import { CONTRACTORS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import schedulePageActions from '../schedulePageActions';
import { getStoredWorklogs } from './sheets.subsciption';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';

const handlers = {
  [CONTRACTORS_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;

    const currentURL = window.location.href;
    if (!currentURL.includes('worklog') || currentURL.includes('worklog/submissions')) return;

    const { storedSheets } = getStoredWorklogs(currentURL, store);

    const updatedSheets = storedSheets.map((el) => {
      return [
        el[0],
        el[1].map((sheet) => {
          if (sheet.contractorId === data._id && !sheet.published) {
            return {
              ...sheet,
              project: { ...sheet.project, contractor: data },
            };
          } else return sheet;
        }),
      ];
    });
    store.dispatch(worklogsPageActions.updateActiveWorklogs(updatedSheets));
  },
};

export const contractorsSubscriptionMiddleware = subscriptionsFabric(
  schedulePageActions,
  CONTRACTORS_EVENT,
  handlers,
  { assignedToLocations: (store) => store.getState().schedule.showAllLocations }
);
