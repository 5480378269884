import { useState, useRef, useEffect } from 'react';
import { Warning } from 'components/icons/Warning';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import { format } from 'date-fns';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RESOURCE_TYPE } from '../ResourcesList/ResourcesList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const getDateTimeoffForUser = (user, timeoffsArr, scheduleDate) => {
  const formattedScheduleDate = format(new Date(scheduleDate), globalBEDateFormat);
  for (const obj of timeoffsArr.filter(timeoff => timeoff.tenantLocationId === user?.homeBaseLocationId)) {
    if (obj.userId === user._id && obj?.inAllDays?.includes(formattedScheduleDate)) {
      return obj;
    }
  }

  return false;
};

const getDateTimeoffForEquip = (equip, timeoffsArr) => {
  for (const obj of timeoffsArr.filter(event => event.tenantLocationId === equip?.homeBaseLocationId)) {
    if (obj.resourceId === equip._id) {
      return obj;
    }
  }

  return false;
};

const getHolidayForUser = (user, holidaysArr) => {
  return holidaysArr.filter(holiday => holiday.tenantLocationId === user?.homeBaseLocationId).find((holiday) => {
    if (holiday.isIndividual) {
      return holiday.userIds.includes(user?._id);
    } else {
      return !holiday.userIds.includes(user?._id);
    }
  });
};

export const WarningToolTip = ({
  resource,
  classes,
  timeoffs,
  dateFormatWithTime,
  scheduleDate,
  type,
  containerRef,
  holidays,
  dateFormat,
}) => {
  const isMobile = useMediaQuery('(max-width: 750px)');

  const [isInfoOpen, setInfoOpen] = useState(false);

  const timeoff =
    type === RESOURCE_TYPE.equipment
      ? getDateTimeoffForEquip(resource, timeoffs)
      : getDateTimeoffForUser(resource, timeoffs, scheduleDate);

  const currentHoliday =
    type === RESOURCE_TYPE.equipment
      ? null
      : getHolidayForUser(resource, holidays, scheduleDate);

  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const iconRef = useRef(null);
  const tooltipRef = useRef(null);

  function Modal({ children }) {
    return ReactDOM.createPortal(children, document.body);
  }

  const updateTooltipPosition = () => {
    if (iconRef.current && tooltipRef.current) {
      if (!isIconVisible()) {
        return setInfoOpen(false);
      }
      const rect = iconRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY,
        left: rect.right + window.scrollX + rect.width / 2,
      });
    }
  };

  const isIconVisible = () => {
    if (iconRef.current && containerRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      return iconRect.top >= containerRect.top && iconRect.bottom <= containerRect.bottom;
    }
    return false;
  };

  useEffect(() => {
    updateTooltipPosition();
  }, [isInfoOpen, iconRef.current]);

  useEffect(() => {
    const handleScroll = () => {
      if (isInfoOpen) {
        updateTooltipPosition();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInfoOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (isInfoOpen) {
        updateTooltipPosition();
      }
    };

    const scrollContainer = containerRef.current || window;
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [isInfoOpen, containerRef]);

  const getTimeoffText = () => {
    if (!timeoff) return '';

    let timeoffText = '';

    if (type === RESOURCE_TYPE.equipment) {
      timeoffText = `${resource.name} is unavailable.`;
    } else {
      timeoffText = `${resource.username} has ${timeoff.status || 'Approved'} Timeoff.`;
    }

    return (
      <div>
        <Typography variant="body1" style={{ color: '#fff' }}>
          {timeoffText}
        </Typography>
        <br />
        <Typography variant="body1" style={{ color: '#fff' }}>
          From {format(new Date(timeoff?.utcStartDate), dateFormatWithTime)}
        </Typography>
        <Typography variant="body1" style={{ color: '#fff' }}>
          to {format(new Date(timeoff?.utcEndDate), dateFormatWithTime)}
        </Typography>
      </div>
    );
  };

  const getHolidayText = () => {
    if (!currentHoliday) return '';
    let holidayText = `${resource.username} has Approved Holiday (or Individual Day(s)-off)`;

    return (
      <div>
        <Typography variant="body1" style={{ color: '#fff' }}>
          {holidayText}
        </Typography>
        <Typography variant="body1" style={{ color: '#fff' }}>
          on {format(new Date(currentHoliday?.utcStartDate), dateFormat)}
        </Typography>
      </div>
    );
  };

  const handleIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    updateTooltipPosition();
    setInfoOpen(true);
    // setTimeout(() => {
    //   setInfoOpen(false);
    // }, 3000);
  };

  if (!timeoff && !currentHoliday) return null;

  return (
    <div className={classes.passInfoIcon}>
      <div
        ref={iconRef}
        onClick={handleIconClick}
        className={cn(type === RESOURCE_TYPE.equipment ? classes.warningEquip : classes.warning)}
      >
        <Warning />
      </div>
      {isInfoOpen && (
        <Modal>
          <ClickAwayListener onClickAway={() => setInfoOpen(false)}>
            <div
              ref={tooltipRef}
              className={classes.passInfoBlock}
              style={{
                position: 'absolute',
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
                transform: isMobile && type === RESOURCE_TYPE.equipment ? 'translateX(-50%)' : '',
              }}
            >
              {getTimeoffText()}
              {getHolidayText()}
            </div>
          </ClickAwayListener>
        </Modal>
      )}
    </div>
  );
};
