import classnames from 'classnames';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { format, isToday, parse } from 'date-fns';
import { useMemo, useState, useEffect, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MonthPreviewModal from '../MonthPreviewModal/MonthPreviewModal';
import { createWorklogName } from 'pages/Schedule/components/ScheduleCalendar/components/ScheduleEvent/helpers/createWorklogName';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Drawer } from '@material-ui/core';
import WorklogDetails from 'components/WorklogDetails';
import ResourcesForm from 'components/ResourcesForm';
import CommentsForm from 'components/CommentsForm';
import MaterialForm from 'components/MaterialForm';
import TimeForm from 'components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from 'components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from 'components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from 'components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import SubmittedIcon from 'assets/images/done-icon.svg';
import PublishedIcon from 'assets/images/hourglass-icon.svg';
import unpublishedIcon from 'assets/images/not-icon.svg';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const DayCell = ({
  classes,
  data,
  clickedDay,
  setClickedDay,
  openSnackbar,
  sheetsWithoutForemanIds,
  showAllLocations,
  locations,
}) => {
  const { dateFormat } = useDateSettingsFormat();
  const getDayNumber = (date) => format(parse(date, dateFormat, new Date()), 'dd');
  const modalRef = useRef(null);
  const popperRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedWLId, setOpenedWLId] = useState(null);
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });

  const isClickedDay = useMemo(() => clickedDay === data.dayNumber, [clickedDay]);
  const previewItems = useMemo(
    () =>
      data.events
        ?.slice()
        .sort((a, b) => new Date(b.createdAtDate) - new Date(a.createdAtDate))
        .slice(0, 3) || [],
    [clickedDay, data.events]
  );

  const openWLAccordion = (id) => {
    setOpenedWLId(id);
  };

  const openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      setAdditionalDrawer({ type, subType, isOpen: true, material });
    };

  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isClickedDay) setAnchorEl(modalRef.current);
  }, []);

  const handleClose = (e) => {
    const clickedElement = e.target;
    const rootElement = document.getElementById('root');

    if (
      //? to close MonthPreviewModal for the day ->
      rootElement?.contains(clickedElement) && // clickedElement is not an activity in a drawer
      clickedElement.textContent != '+ Add worklog' && //and clickedElement is not an '+ Add worklog' btn
      !(clickedElement.parentNode.id === 'manageButtons' || clickedElement.id === 'manageButtons')
    ) {
      e?.stopPropagation();
      setAnchorEl(null);
    }
  };

  const getStatusIcon = (sheet) => {
    if (sheet.submittedAt) return SubmittedIcon;
    if (sheet.published) return PublishedIcon;
    if (!sheet.published && !sheet.submittedAt) return unpublishedIcon;
  };

  const getStatusColor = (sheet) => {
    if (sheet.submitted) return '#3E82FF';
    if (sheet.published) return '#979797';
    // if (sheet.reconciled) return '#0C842B';
    return '#D14646';
  }
  const getStatusLetter = (sheet) => {
    if (sheet.submitted) return 'S';
    if (sheet.published) return 'P';
    // if (sheet.reconciled) return 'V';
    return 'U';
  }

  const getLocationColor = (sheet) => {
    return locations.find(({ _id }) => sheet.tenantLocationId === _id)?.color;
  }

  const open = Boolean(anchorEl);

  const handleUpdate = () => {
    if (popperRef.current && popperRef.current.update) {
      setTimeout(() => {
        popperRef.current.update();
      }, 0);
    }
  };
  const id = 'simple-popover' + data.dayNumber;
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classnames(classes.dayCellInner)}>
        <div
          ref={modalRef}
          onClick={(e) => {
            handleClick(e);
            setClickedDay(data.dayNumber);
          }}
          className={classnames(
            classes.square,
            clickedDay === data.dayNumber && classes.clickedDay
          )}
        >
          <div className={classnames(classes.dayCellHeder)}>
            <span
              className={classnames(
                classes.dayNumber,
                isToday(parse(data.dayNumber, dateFormat, new Date())) && classes.todayNumber
              )}
            >
              {getDayNumber(data.dayNumber)}
            </span>

            <div
              className={classnames(
                classes.sheetQuantity,
                isClickedDay && classes.clickedDayQuantity
              )}
            >
              {data.events?.length}
            </div>
          </div>
          <div className={classnames(classes.dayCellBody)}>
            {previewItems.map((sheet) => (
              <div
                key={sheet?._id}
                className={classnames(
                  classes.previewCellItemWrapper,
                  sheetsWithoutForemanIds.includes(sheet._id) && classes.missingCL
                )}
              >
                <div className={classnames(classes.previewItemInner)}>
                  <div
                    className={classnames(
                      classes.previewCellImgWrapper,
                      clickedDay === data.dayNumber
                        ? classes.activeIconWrapper
                        : classes.notActiveIconWrapper,
                      showAllLocations ? classes.locationMark : ''
                    )}
                    style={{
                      color: getStatusColor(sheet),
                      borderColor: showAllLocations ? getLocationColor(sheet) : getStatusColor(sheet),
                    }}
                  >
                    {showAllLocations ? ' ' : getStatusLetter(sheet)}
                  </div>
                  <Typography
                    variant="body1"
                    className={classnames(
                      classes.previewCellItem,
                      Boolean(sheet.canceledAt) && classes.canceled
                    )}
                    color="textPrimary"
                  >
                    {createWorklogName(sheet)}
                  </Typography>
                </div>
                <ArrowRightIcon
                  color="secondary"
                  fontSize={'small'}
                  className={classnames(classes.previewCellArrow)}
                  onClick={() => {}}
                />
              </div>
            ))}
          </div>
        </div>
        <Popper
          id={id}
          ref={popperRef}
          open={open}
          disablePortal
          anchorEl={anchorEl || modalRef}
          placement={'right-start'}
          className={classnames(classes.popper)}
          flip={false}
          popperRef={(node) => {
            popperRef.current = node;
          }}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
          }}
        >
          <MonthPreviewModal
            data={data}
            onClose={handleClose}
            openedWLId={openedWLId}
            openWLAccordion={openWLAccordion}
            sheetsWithoutForemanIds={sheetsWithoutForemanIds}
            handleUpdate={handleUpdate}
            showAllLocations={showAllLocations}
            locations={locations}
          />
        </Popper>
        {open && (
          <WorklogDetails
            openSnackbar={openSnackbar}
            updateVisibleSheets={() => {}}
            openDrawer={() => {}}
            openAdditionalDrawer={openAdditionalDrawer}
            updateDrawerFromRouter={() => {}}
            sheetIds={[]}
            hasMore={false}
            isSchedule
          />
        )}

        <Drawer anchor="left" open={additionalDrawer.isOpen}>
          {additionalDrawer.type === 'resources' && (
            <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} />
          )}
          {additionalDrawer.type === 'comments' && (
            <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
          )}
          {additionalDrawer.type === 'material' && (
            <MaterialForm
              closeDrawer={closeAdditionalDrawer}
              openSnackbar={openSnackbar}
              type={additionalDrawer.subType}
              material={additionalDrawer.material}
            />
          )}
          {additionalDrawer.type === 'time' && (
            <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
          )}
          {additionalDrawer.type === 'weather' && (
            <WeatherForm closeDrawer={closeAdditionalDrawer} />
          )}
          {additionalDrawer.type === 'notes' && (
            <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
          )}
          {additionalDrawer.type === 'general' && (
            <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
          )}
        </Drawer>
      </div>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(DayCell);
