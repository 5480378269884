import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import styles from './styles';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const PrintPage = ({
  needToPrint,
  classes,
  worklogsForPrint,
  selectedDate,
  updatePrintWorklogsControl,
}) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  const locationSettings = useSelector((state) => state.personalProfile.organizationLocation?.settings);

  useEffect(() => {
    if (needToPrint) {
      window.print();
      updatePrintWorklogsControl(false)();
    }
  }, [needToPrint]);
  const { dateFormatWithTime } = useDateSettingsFormat();
  const companyName = useSelector(
    (state) => state.personalProfile?.organization?.name || '3208 Technology, Inc.'
  );

  const formatDate = () => format(selectedDate, GLOBAL_DATE_FORMAT.fullDateWithDay);

  const fullFormatDate = (date) => format(date, dateFormatWithTime);

  const isCanceled = (canceledAt) => Boolean(canceledAt);

  const formatHours = (hours) => format(parseISO(hours), formatForTimePars);

  const sortedWorklogsForPrint = worklogsForPrint.sort((a, b) => {
    if (a.contractorNickname < b.contractorNickname) return -1;
    if (a.contractorNickname > b.contractorNickname) return 1;
    if (a.createdAtDate < b.createdAtDate) return -1;
    if (a.createdAtDate > b.createdAtDate) return 1;
    return 0;
  });

  return (
    <div className={classes.showForPrint}>
      <h3 className={classes.textCenter}>{companyName} Daily Schedule</h3>
      <h3 className={classes.textCenter}>{formatDate()}</h3>
      <div>
        {sortedWorklogsForPrint.map((worklog) => (
          <div key={worklog.id} className={classes.worklogContainer}>
            <header className={classes.worklogHeader}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <span>{worklog.contractorNickname || ''}</span>
                </Grid>
                <Grid item xs={3}>
                  <span>{worklog.routeSection || ''}</span>
                </Grid>
                <Grid item xs={3}>
                  <span>{worklog.county || ''}</span>
                </Grid>
                {locationSettings?.timeclock && (
                  <Grid item xs={3}>
                    <span>Certified Payroll: {worklog.rated ? 'Yes' : 'No'}</span>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <span>{worklog.jobNumber || ''}</span>
                </Grid>
                <Grid item xs={3}>
                  <span>Start Time</span>
                </Grid>
                <Grid item xs={3}>
                  <span>Job Time</span>
                </Grid>
                <Grid item xs={3}>
                  <span>Equipment Name</span>
                </Grid>
              </Grid>
            </header>
            <Grid container>
              {isCanceled(worklog.canceledAt) ? (
                <Grid container className={classes.resourcesList}>
                  {worklog.cancelNote && (
                    <Grid item xs={4}>
                      <span>Canceled: {worklog.cancelNote}</span>
                    </Grid>
                  )}
                  {worklog.canceledBy && (
                    <Grid item xs={4}>
                      <span>Canceled by: {worklog.canceledBy}</span>
                    </Grid>
                  )}
                  {worklog.canceledAt && (
                    <Grid item xs={4}>
                      <span>{fullFormatDate(new Date(worklog.canceledAt))}</span>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <>
                  <Grid item xs={9} className={classes.resourcesList}>
                    {worklog.workers &&
                      worklog.workers.map((worker) => (
                        <Grid key={worker._id} container>
                          <Grid item xs={4}>
                            <span>
                              {worker ? worker.username : ''}
                              {worker.employeeNum ? ` - ${worker.employeeNum}` : ''}
                            </span>
                          </Grid>
                          <Grid item xs={3}>
                            <span>{worker?.startTime}</span>
                          </Grid>
                          <Grid item xs={5} className={classes.textCenterHours}>
                            {worker.hours && worker.hours.length ? (
                              worker.hours.map((h) => (
                                <div key={h.id}>
                                  {formatHours(h.start)} - {formatHours(h.end)}
                                </div>
                              ))
                            ) : (
                              <span>
                                {worker.start} - {worker.end}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>

                  <Grid item xs={3} className={classes.equipmentList}>
                    {worklog.equipment &&
                      worklog.equipment.map((item) => (
                        <Grid key={item?._id} container>
                          <Grid item xs={12}>
                            <span>{item?.name}</span>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

PrintPage.propTypes = {
  classes: propTypes.object.isRequired,
  selectedDate: propTypes.object.isRequired,
  worklogsForPrint: propTypes.array.isRequired,
  updatePrintWorklogsControl: propTypes.func.isRequired,
  needToPrint: propTypes.bool.isRequired,
};

export default withStyles(styles)(PrintPage);
