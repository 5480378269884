import { createAction } from '@reduxjs/toolkit';

const moveWorkers = createAction('schedule/moveWorkers');
const moveEquipment = createAction('schedule/moveEquipment');
const setScheduleView = createAction('schedule/setScheduleView');
const setLoading = createAction('schedule/setLoading');
const setSelectedDate = createAction('schedule/setSelectedDate');
const setSelectedMonthDate = createAction('schedule/setSelectedMonthDate');
const setSelectedMonthDayDate = createAction('schedule/setSelectedMonthDayDate');
const updateMonthSheet = createAction('schedule/updateMonthSheet');
const addSheetToMonthSchedule = createAction('schedule/addSheetToMonthSchedule');
const removeSheetFromMonthSchedule = createAction('schedule/removeSheetFromMonthSchedule');
const updateCrewLeaderMonthCalendar = createAction('schedule/updateCrewLeaderMonthCalendar');
const setFilters = createAction('schedule/setFilters');
const setFilterOptions = createAction('schedule/setFilterOptions');
const setUnpublishedSchedule = createAction('schedule/setUnpublishedSchedule');
const setUnpublishedChanges = createAction('schedule/setUnpublishedChanges');
const setResourcesWorkers = createAction('schedule/setResourcesWorkers');
const setResourcesAllWorkers = createAction('schedule/setResourcesAllWorkers');
const addWorker = createAction('schedule/addWorker');
const addToAllWorkers = createAction('schedule/addToAllWorkers');
const updateWorkerResource = createAction('schedule/updateWorkerResource');
const removeWorkerResource = createAction('schedule/removeWorkerResource');
const setCrewLeaderAction = createAction('schedule/setCrewLeaderAction');
// Equipment actions
const setResourcesEquipment = createAction('schedule/setResourcesEquipment');
// Sheets actions
const setSheets = createAction('schedule/setSheets');
const setMonthSheets = createAction('schedule/setMonthSheets');
const updateSheet = createAction('schedule/updateSheet');
const setReturnToShop = createAction('schedule/setReturnToShop');
const updateCrewLeader = createAction('schedule/updateCrewLeader');
const swapGridsPosition = createAction('schedule/swapGridsPosition');
const setGridPosition = createAction('schedule/setGridPosition');
const unpublishWorklog = createAction('schedule/unpublishWorklog');
const publishWorklogs = createAction('schedule/publishWorklogs');
const setCalendarTypeView = createAction('schedule/setCalendarTypeView');
const reconcileWorklogs = createAction('schedule/reconcileWorklogs');
const setSheetSubmitted = createAction('schedule/setSheetSubmitted');
const setSheetCanceled = createAction('schedule/setSheetCanceled');
const deleteWorklog = createAction('schedule/deleteWorklog');
const updateSheetsEquipment = createAction('schedule/updateSheetsEquipment');
// Calendar events actions
const setCalendarEvents = createAction('schedule/setCalendarEvents');
const addCalendarEvent = createAction('schedule/addCalendarEvent');
const removeCalendarEvents = createAction('schedule/removeCalendarEvent');
// Time offs actions
const setScheduleResourcesAvailability = createAction('schedule/timeoffs')
// SelectedResources
const setSelectedResourcesWorklogId = createAction('schedule/selectResources/setWorklogId');
const selectWorklogPeopleResource = createAction('schedule/selectResources/selectPeople');
const selectWorklogEquipmentResource = createAction('schedule/selectResources/selectEquipment');
const setSelectedResources = createAction('schedule/selectResources/setSelectedResources');
const deselectAllResources = createAction('schedule/selectResources/deselectAll');
const setShowAllLocations = createAction('schedule/setShowAllLocations');
// Socket actions
const socketConnect = createAction('schedule/socketConnect');
const socketDisconnect = createAction('schedule/socketDisconnect');

export default {
  moveWorkers,
  moveEquipment,
  setScheduleView,
  setLoading,
  setSelectedDate,
  setSelectedMonthDate,
  setSelectedMonthDayDate,
  updateMonthSheet,
  addSheetToMonthSchedule,
  removeSheetFromMonthSchedule,
  updateCrewLeaderMonthCalendar,
  setFilters,
  setFilterOptions,
  setUnpublishedSchedule,
  setUnpublishedChanges,
  setResourcesWorkers,
  setResourcesAllWorkers,
  addWorker,
  addToAllWorkers,
  updateWorkerResource,
  removeWorkerResource,
  setCrewLeaderAction,
  // Equipment events
  setResourcesEquipment,
  // Sheets events
  setSheets,
  setMonthSheets,
  updateSheet,
  setReturnToShop,
  updateCrewLeader,
  swapGridsPosition,
  setGridPosition,
  unpublishWorklog,
  publishWorklogs,
  setCalendarTypeView,
  reconcileWorklogs,
  setSheetSubmitted,
  setSheetCanceled,
  deleteWorklog,
  updateSheetsEquipment,
  // Calendar events
  setCalendarEvents,
  addCalendarEvent,
  removeCalendarEvents,
  // Time offs actions
  setScheduleResourcesAvailability,
  // Selected resources actions
  setSelectedResourcesWorklogId,
  selectWorklogPeopleResource,
  selectWorklogEquipmentResource,
  setSelectedResources,
  deselectAllResources,
  setShowAllLocations,
  // Socket actions
  socketConnect,
  socketDisconnect,
};
