import React, { useState, useEffect, useCallback } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import ProjectList from './components/ProjectList';
import ProjectForm from './components/ProjectForm';
import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import FiltersBar from 'components/FiltersBar';
import PageLoader from '../../../../components/PageLoader';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import { fetchProjectTypes, fetchProjects } from 'store/projectTypes/projectTypesOperations';
import { fetchAllContractorsData } from 'store/contractors/contractorsPageOperations';
import { hasPermissionsFor } from 'helpers/_helpers';
import { useMemo } from 'react';
import ProjectTypesActions from 'store/projectTypes/projectTypesActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { exportData, importData } from 'helpers/downloadFile';

const ProjectPage = ({ openSnackbar, classes }) => {
  const dispatch = useDispatch();
  const getFilterStatusName = (index) => {
    return index === 0 ? 'Active' : 'Inactive';
  };
  const locationSettings = useSelector(state => state.personalProfile?.organizationLocation?.settings);

  const {
    data: projects,
    quantity,
    isLoading,
    hasMore,
    isError,
  } = useSelector((store) => store.projectTypes.projects);
  const projectTypesOptions = useSelector((state) => state.projectTypes.projectTypes.projectTypes);
  const projectOptions = useMemo(() => [...projectTypesOptions], [projectTypesOptions]);

  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
    project: {},
  });
  const [tabValue, setTabValue] = useState(0);
  const [userFilter, setUserFilter] = useState({
    sortBy: 'Job Number',
    sortOrder: 'asc',
    shift: 'All',
    rated: 'All',
    status: getFilterStatusName(tabValue),
    type: 'All',
  });

  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const filteredProjects = useMemo(
    () =>
      projects.filter((project) =>
        tabValue === 0 ? project.active === true : project.active === false
      ),
    [projects, tabValue]
  );

  useEffect(() => {
    dispatch(fetchProjectTypes());
    dispatch(fetchAllContractorsData());
    return () => {
      dispatch(ProjectTypesActions.resetProjects());
    };
  }, []);

  useEffect(() => {
    updateVisibleProjects(userFilter, null, searchValue);
  }, [tabValue]);

  const updateSearchValue = (value) => {
    setSearchValue(value);
    setPage(0);
    updateVisibleProjects(userFilter, 0, value);
  };

  const updateFilter = (filter) => {
    setUserFilter(filter);
    setPage(0);
    updateVisibleProjects(filter, 0, searchValue);
    closeDrawer();
  };

  const updateProjectsByPage = () => {
    updateVisibleProjects(userFilter, page, searchValue);
  };

  const updateVisibleProjects = useCallback(
    async (filter, pages, searchValue) => {
      setLoading(true);
      const usedFilter = filter || userFilter;

      await dispatch(fetchProjects({
        filters: usedFilter, 
        page: pages,
        searchValue
      }))
        .then((e) => setPage(pages + 1))
        .catch((err) => console.log(''));

      setLoading(false);
      setInitialLoad(false);
    },
    [tabValue]
  );

  const hasFullAccess = useMemo(
    () => hasPermissionsFor('projectsFullAccess') || hasPermissionsFor('projectsEdit'),
    []
  );

  const openDrawer = useCallback(
    (type, project = {}) =>
      () => {
        setDrawer({ type, isOpen: true, project });
      },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawer({ type: 'filters', isOpen: false, project: {} });
  }, []);

  const handleChangeTab = (event, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
    setUserFilter((prevFilter) => ({ ...prevFilter, status: getFilterStatusName(newValue) }));
  };

  const onExportExcel = () => {
    exportData(
      'projects/csv',
      {
        searchValue,
        ...userFilter,
      },
      openSnackbar
    );
  };

  const onImportExcel = async (data) => {
    importData('projects/csv', data, openSnackbar).then((e) =>
      updateVisibleProjects(userFilter, 0, searchValue)
    );
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label={`(${quantity?.active || 0}) Active`} disableRipple />
        <Tab label={`(${quantity?.inactive || 0}) Inactive`} disableRipple />
      </Tabs>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={updateSearchValue}
        isDynamicSearch={true}
        hasImport={hasPermissionsFor('projectsFullAccess')}
        hasExport={hasPermissionsFor('projectsFullAccess')}
        onExportData={onExportExcel}
        onImportData={onImportExcel}
      />
      <PageLoader loading={loading} style={{ overflow: 'auto' }}>
        <ProjectList
          openDrawer={openDrawer}
          projects={filteredProjects}
          updateProjectsByPage={updateProjectsByPage}
          hasMore={isError ? false : hasMore}
          hasFullAccess={hasFullAccess}
          loading={loading}
          page={page}
          initialLoad={initialLoad}
          locationSettings={locationSettings}
        />
      </PageLoader>
      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.type !== drawerTypes.filters ? (
          <ProjectForm
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
            project={drawer.project}
            type={drawer.type}
            locationSettings={locationSettings}
          />
        ) : (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={userFilter}
            updateFilter={updateFilter}
            isLoading={isLoading}
            initialStatus={getFilterStatusName(tabValue)}
            projectOptions={projectOptions}
            locationSettings={locationSettings}
          />
        )}
      </Drawer>
    </div>
  );
};

export default ProjectPage;
