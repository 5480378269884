import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDndLabels } from './hooks/useDndLabels';
import { GridDND, SortableItem, Item } from './components/dndComponents';
import styles from './styles';

const DndComponent = ({
  classes,
  values,
  remove,
  push,
  replace,
  move,
  typeOfLabel,
  getItemName,
  findItemById,
  allowDeleteItem,
}) => {
  const { items, setItems, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel } =
    useDndLabels({ values, move, findItemById });

  useEffect(() => {
    setItems(values);
  }, [setItems, values])

  const showItemDelete = (elem) => {
    if (allowDeleteItem) return allowDeleteItem(elem, items)
      return true;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragEnd={handleDragCancel}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <GridDND columns={5} classes={classes}>
          <ul className={classes.itemsList}>
            {items.map((elem, index) => (
              <li key={index} style={{ margin: '0 8px 8px 0' }}>
                <SortableItem
                  classes={classes}
                  key={elem}
                  id={elem}
                  elem={elem}
                  index={index}
                  remove={remove}
                  typeOfLabel={typeOfLabel}
                  setItems={setItems}
                  showDelete={showItemDelete(elem)}
                  getItemName={getItemName}
                />
              </li>
            ))}
          </ul>
        </GridDND>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Item
            classes={classes}
            id={activeId}
            elem={items.find((el) => el === activeId)}
            typeOfLabel={typeOfLabel}
            isDragging
            getItemName={getItemName}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};


export default withStyles(styles)(DndComponent);
