import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import LocationDetails from './LocationDetails';
import { palette } from './constants'
import { postLocation, updateLocation } from 'store/settings/settingsOperations';
import styles from './styles';

const validation = (values) => {
  const errors = {};
  if (!values.name.trim()) {
    errors.name = "This field can't be blank";
  }
  if (!values.address.trim()) {
    errors.address = "This field can't be blank";
  }
  
  return errors;
};

const OrganizationLocationForm = ({
  classes,
  closeDrawer,
  location,
  allLocations,
  type,
  openSnackbar,
}) => {
  const dispatch = useDispatch();

  const usedColors = useMemo(() => allLocations.map((location) => location.color), [allLocations])

  const initialValues = location
    ? {
        name: location.name,
        address: location.address,
        color: location.color || palette[0],
        active: location.active,
      }
    : {
        name: '',
        address: '',
        color: palette[0],
        active: true,
      };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const data = {
            ...values,
          };

          if (type === 'create') {
            try {
              await dispatch(postLocation(data))
                .then(closeDrawer);
              openSnackbar('success', 'Successfully Added!');
            } catch (err) {
              openSnackbar('error', err?.response?.data?.message || err.message);
              setSubmitting(false);
            }
          } else {
            try {
              await dispatch(updateLocation(location._id, data))
                .then(closeDrawer)
              openSnackbar('success', 'Successfully Updated!');
            } catch (err) {
              openSnackbar('error', err?.response?.data?.message || err.message);
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '40px' }}>
                  {type === 'create' ? 'Create' : 'Edit'} Location
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <LocationDetails
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  palette={palette}
                  usedColors={usedColors}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

OrganizationLocationForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  equipment: propTypes.object,
};

export default withStyles(styles)(OrganizationLocationForm);
