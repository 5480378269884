import searchIcon from '../../../../assets/images/search-icon-grey.svg';

export default (theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    marginBottom: '20px',
  },
  options: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 41,
    zIndex: 1300,
    maxHeight: '215px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.21)',
  },
  optionsContainer: {
    position: 'relative',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    maxWidth: '404px',
  },
  emptyOption: {
    color: '#A4A5B3',
    fontSize: 14,
    letterSpacing: '0.27px',
    lineHeight: '21px',
    padding: '15px 24px'
  },
  loaderContainer: {
    padding: '15px 24px',
    textAlign: 'center'
  },
  option: {
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    color: '#000000',
    fontSize: '14px',
    letterSpacing: '0.17px',
    lineHeight: '21px',
    cursor: 'pointer',
    '&:hover, &[data-focus="true"]': {
      backgroundColor: '#f4f7fe',
      '& > svg': {
        visibility: 'visible'
      }
    },
    '&[aria-selected="true"]': {
      color: '#5A87EF',
    }
  },
  highlighted: {
    color: '#5A87EF'
  },
  selectButton: {
    display: 'flex',
    width: '100%',
    maxWidth: '403px',
    margin: '0 !important',
    overflow: 'visible',
    padding: '8px 16px !important',
    height: '40px !important',
    border: '1px solid #D9DAE3 !important',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    color: 'rgba(0,0,0,0.75)',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    boxShadow: 'none !important',
    '&:hover': {
      color: 'rgba(0,0,0,0.9)',
    },
    '&:focus': {
      border: '1px solid #D9DAE3 !important',
    },
    '&::placeholder': {
      color: '#A4A5B3 !important',
      fontSize: '14px !important',
      lineHeight: '21px !important',
    },
    '& > span': {
      flexGrow: 1,
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  },
  textInputWrapper: {
    padding: '3px 0',
  },
  textInput: {
    margin: '0',
    overflow: 'visible',
    padding: '2px 8px',
    // height: '40px !important',
    border: '1px solid #D9DAE3 !important',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    color: 'rgba(0,0,0,0.75) !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    boxShadow: 'none !important',
  },
  textInputRoot: {
    margin: '0px',
    width: '100%',
  },
  doneIcon: {
    width: 17,
    height: 17,
    marginRight: '5px',
    marginLeft: '-2px',
    visibility: 'hidden',
  },
  optionText: {
    flexGrow: 1,
  },
  optionSelected: {
    visibility: 'visible'
  },
  inputAdornment: {
    margin: 0,
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    marginRight: 0,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
})
