import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';

const InjuryReportEmails = ({ classes, pageType, control, errors }) => {

  const {
    field: { onChange, value: injuryReportEmailsValue },
  } = useController({
    name: 'injuryReportEmails',
    control,
  });

  const [isShowEmails, setShowEmails] = useState(false);


  const handleEmailChange = (i) => (e) => {
    const { value } = e.target;
    const nextValue = injuryReportEmailsValue?.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = injuryReportEmailsValue?.filter((_, index) => index !== i);
    onChange(nextValue);
  };
  const handleAddEmail = () => {
    setShowEmails(true);
    onChange([...injuryReportEmailsValue?.filter((v) => v.length), '']);
  };

  return (
    <div>
      <div className={classes.actionsWrapper}>
        {!!injuryReportEmailsValue?.length &&
          injuryReportEmailsValue
            .slice(0, isShowEmails ? injuryReportEmailsValue.length : 3)
            .map((email, index) => (
              <Grid container spacing={2} key={`injuryReportEmails-${index}`} alignItems="center">
                <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                  {index === 0 && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.regularFontWeight}
                    >
                      Email:
                    </Typography>
                  )}
                  <TextField
                    id={`injuryReportEmails[${index}]`}
                    name={`injuryReportEmails[${index}]`}
                    type="email"
                    value={email}
                    className={classes.textField}
                    onChange={handleEmailChange(index)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Injury Report' }}
                    fullWidth
                    disabled={pageType === 'read'}
                  />
                </Grid>
                {pageType === 'edit' && (
                  <span
                    className={classes.removeEmail}
                    onClick={handleEmailRemoving(index)}
                    style={index === 0 ? { marginTop: 15 } : {}}
                  >
                    <img src={CloseIcon} alt="delete" />
                  </span>
                )}
              </Grid>
            ))}
        {injuryReportEmailsValue?.length > 3 && (
          <div
            className={classes.buttonWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowEmails((prev) => !prev);
            }}
          >
            <div className={classes.nonActionsIcon}>
              <ExpandMoreIcon className={cn(isShowEmails ? '' : classes.reversIcon)} />
            </div>
            <p className={classes.textIconMore}>{`${
              isShowEmails ? 'Hide' : 'Show'
            } additional email addresses`}</p>
          </div>
        )}
        {pageType === 'edit' && (
          <Button
            variant="text"
            className={classes.primaryTextButton}
            onClick={handleAddEmail}
            disableRipple
          >
            + Add Email
          </Button>
        )}
      </div>
    </div>
  );
};

InjuryReportEmails.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(InjuryReportEmails);
