import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';


export const getSettings = async () => {
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/organization-locations/settings`, {
      ...getOptions(),
    });

    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};
