import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { findUsed } from '../../../../../helpers/findUsed';
import { useResourcesUsers } from './useResourcesUsers';
import { useResourcesEquipment } from './useResourcesEquipment';

export const useResources = () => {
  const calendarEvents = useSelector((state) => state.schedule.calendarEvents);
  const sheets = useSelector((state) => state.schedule.sheets);
  const showAllLocations = useSelector((state) => state.schedule.showAllLocations);
  const usedWorkers = useMemo(() => findUsed(sheets, 'workers'), [sheets]);
  const usedEquipment = useMemo(() => findUsed(sheets, 'equipment'), [sheets]);

  const { userList } = useResourcesUsers({ usedWorkers });
  const { equipmentList } = useResourcesEquipment({
    usedEquipment,
    unavailableResources: calendarEvents,
  });

  const userPermissions = useSelector((state) => state.personalProfile.fullUserPermissions);
  const canDragResources = useMemo(
    () => (
      !showAllLocations &&
      (userPermissions['scheduleEdit'] || userPermissions['scheduleFullAccess']
    )
  ), [userPermissions, showAllLocations]);

  return { userList, equipmentList, canDragResources };
};
