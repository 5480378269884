import React from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import $api from 'http/index';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import HICodes from 'components/HICodes/HICodes';
import styles from './styles';
import { isIOS, Option } from '../../../../../../components/Option';
import { createOptions } from 'helpers/createOptions';
import { useTimeStates } from 'hooks/useTimeStates';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import {
  postHiCode,
  putHICode,
  deleteHICode
} from 'store/people/hiCodesOperations';

const UserDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  userInfo,
  openSnackbar,
  // isCreate,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const { hiCodes, hiCodesLoading } = useSelector((state) => state.people.hiCodes);
  const locationSettings = useSelector(state => state.personalProfile?.organizationLocation?.settings)
  
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const getTimeFormatHook = useTimeSettingsFormat();

  const handleEmploymentStatusChange = (e) => {
    return handleChange({
      target: {
        name: 'timeOfDay',
        value: +e.target.value ? 'Day' : 'Inactive',
      },
    });
  };

  const handleClearIndividualHours = (e) => {
    e.preventDefault();
    handleChange({ target: { name: 'individualHours', value: null } });
    handleChange({ target: { name: 'individuaMinutes', value: null } });
    handleChange({ target: { name: 'individualAmPm', value: null } });
  };

  const handleResetPassword = async () => {
    const options = {
      headers: {
        'X-Auth-Token': localStorage.getItem('authorization-token'),
      },
    };

    try {
      await $api.post(
        `${process.env.REACT_APP_BASE_URL}/users/${values.userId}/reset-password`,
        {},
        options
      );
      openSnackbar('success', 'Link to reset password was successfully sent');
    } catch (error) {
      openSnackbar('error', error.response.data.message);
    }
  };

  const createHICodeAction = (input) => dispatch(postHiCode(input));
  const updateHICodeAction = (input) => dispatch(putHICode(input));
  const deleteHICodeAction = (input) => dispatch(deleteHICode(input));

  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="name">
            <Typography variant="body1" color="textSecondary">
              Username<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="name"
              value={values.name}
              onChange={handleChange}
              error={Boolean(errors.name) && Boolean(touched.name)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Username' }}
              fullWidth
            />
            {errors.name && touched.name ? (
              <div className={classes.error}>{errors.name}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="fullName">
            <Typography variant="body1" color="textSecondary">
              Full Name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="fullName"
              value={values.fullName}
              onChange={handleChange}
              error={Boolean(errors.fullName) && Boolean(touched.fullName)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Full Name' }}
              fullWidth
            />
            {errors.fullName && touched.fullName ? (
              <div className={classes.error}>{errors.fullName}</div>
            ) : null}
          </label>
        </Grid>
        {userInfo && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              style={{ marginTop: '15px', cursor: 'pointer' }}
              onClick={handleResetPassword}
            >
              Reset password
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="email">
            <Typography variant="body1" color="textSecondary">
              Email<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="email"
              autoComplete="false"
              value={values.email}
              error={Boolean(errors.email) && Boolean(touched.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Email' }}
              fullWidth
              // disabled={!isCreate}
            />
            {errors.email && touched.email ? (
              <div className={classes.error}>{errors.email}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="phoneNumber">
            <Typography variant="body1" color="textSecondary">
              Phone Number:
            </Typography>
            <TextField
              type="text"
              id="phoneNumber"
              value={values.phoneNumber}
              error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
              onBlur={handleBlur}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Phone number' }}
              fullWidth
              // disabled={!isCreate}
            />
            {errors.phoneNumber && touched.phoneNumber ? (
              <div className={classes.error}>{errors.phoneNumber}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>
      <Divider className={classes.marginVertical} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="employeeNum">
            <Typography variant="body1" color="textSecondary">
              Employee Number<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="employeeNum"
              value={values.employeeNum}
              onChange={handleChange}
              error={Boolean(errors.employeeNum) && Boolean(touched.employeeNum)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Employee number' }}
              fullWidth
            />
            {errors.employeeNum && touched.employeeNum ? (
              <div className={classes.error}>{errors.employeeNum}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="HICode">
            <Typography variant="body1" color="textSecondary">
              Health Insurance Code<span className={classes.requiredSign}>*</span>:
            </Typography>
            <HICodes
              value={values.HICode || ''}
              changeValue={(code) => setFieldValue('HICode', code)}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              openSnackbar={openSnackbar}
              options={hiCodes}
              loading={hiCodesLoading}
              createAction={createHICodeAction}
              editAction={updateHICodeAction}
              deleteAction={deleteHICodeAction}
            />
          </label>
          {errors.HICode && touched.HICode ? (
            <div className={classes.error}>{errors.HICode}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="timeOfDay">
            <Typography variant="body1" color="textSecondary">
              Shift:
            </Typography>
            <TextField
              select
              id="timeOfDay"
              name="timeOfDay"
              value={values.timeOfDay === 'Inactive' ? 'Day' : values.timeOfDay}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Shift' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
              disabled={values.timeOfDay === 'Inactive'}
            >
              <Option value="Day">
                {isIOS ? '🌤 ' : <img src={IconSunny} alt="sunny" className={classes.shiftImage} />}
                Day
              </Option>
              <Option value="Night">
                {isIOS ? '🌙 ' : <img src={IconMoon} alt="moon" className={classes.shiftImage} />}
                Night
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="weekDays">
            <Typography variant="body1" color="textSecondary">
              Days:
            </Typography>
            <TextField
              select
              id="weekDays"
              name="weekDays"
              value={values.weekDays}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Days' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option value="Mon-Fri">Mon &mdash; Fri</Option>
              <Option value="Sun-Thu">Sun &mdash; Thu</Option>
              <Option value="Tue-Sat">Tue &mdash; Sat</Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="allowedTime">
            <Typography variant="body1" color="textSecondary">
              PTO Hours Per Year:
            </Typography>
            <TextField
              type="text"
              id="allowedTime"
              value={values.allowedTime}
              onChange={handleChange}
              error={Boolean(errors.allowedTime) && Boolean(touched.allowedTime)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'PTO hours per year' }}
              fullWidth
            />
            {errors.allowedTime && touched.allowedTime ? (
              <div className={classes.error}>{errors.allowedTime}</div>
            ) : null}
          </label>
        </Grid>
        {locationSettings?.timeclock && (
          <Grid item xs={12} md={6} lg={4}>
            <label htmlFor={'individualHours'} className={classes.label}>
              <Typography variant="body1" color="textSecondary">
                Individual Start Time:
              </Typography>
              <div className={classes.wrapper}>
                <TextField
                  select
                  id="individualHours"
                  variant="outlined"
                  value={values.individualHours || ''}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'individualHours',
                        value: `${e.target.value}`,
                      },
                    });
                  }}
                  className={classes.select}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  <Option key={'defaultHour'} value={''}></Option>
                  {hours.map(createOptions)}
                </TextField>
                <div variant="body1" className={classes.delimiter}>
                  :
                </div>
                <TextField
                  select
                  id="individuaMinutes"
                  variant="outlined"
                  value={values.individuaMinutes || ''}
                  className={classes.select}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'individuaMinutes',
                        value: `${e.target.value}`,
                      },
                    });
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  <Option key={'defaultMinute'} value={''}></Option>
                  {minutes.map(createOptions)}
                </TextField>
                {getTimeFormatHook().is12Format && (
                  <TextField
                    select
                    id="individualAmPm"
                    variant="outlined"
                    value={values.individualAmPm || ''}
                    className={classes.select}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: 'individualAmPm',
                          value: `${e.target.value}`,
                        },
                      });
                    }}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    <Option key={'defaultAmPm'} value={''}></Option>
                    {dayPart.map(createOptions)}
                  </TextField>
                )}
                <CloseIcon className={classes.clearSelect} onClick={handleClearIndividualHours} />
              </div>
            </label>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="employmentStatus">
            <Typography variant="body1" color="textSecondary">
              Employment Status:
            </Typography>
            <TextField
              select
              type="text"
              id="employmentStatus"
              value={Number(values.timeOfDay !== 'Inactive')}
              onChange={handleEmploymentStatusChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Employment Status' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option value={1}>Active</Option>
              <Option value={0}>Inactive</Option>
            </TextField>
          </label>
        </Grid>
      </Grid>

      {!!values?.emergencyContacts?.length && (
        <>
          <Divider xs={9} className={'settings-devider'} />
          <Typography variant="h5" style={{ marginBottom: '25px' }}>
            Emergency contacts
          </Typography>
          {values.emergencyContacts.map((el, i) => (
            <div key={el.id} className={'emergency-block'}>
              <Typography variant="h6">{el.relationship}</Typography>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`emergencyContacts[${i}].name`}>
                    <Typography variant="body1" color="textSecondary">
                      Name:
                    </Typography>
                    <TextField
                      type="text"
                      id={`emergencyContacts[${i}].name`}
                      value={values.emergencyContacts[i].name}
                      disabled
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Name' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`values.emergencyContacts[${i}].phoneNumber`}>
                    <Typography variant="body1" color="textSecondary">
                      Phone number:
                    </Typography>
                    <TextField
                      type="text"
                      id={`values.emergencyContacts[${i}].phoneNumber`}
                      value={values.emergencyContacts[i].phoneNumber}
                      disabled
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Phone number' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`values.emergencyContacts[${i}].address`}>
                    <Typography variant="body1" color="textSecondary">
                      Address:
                    </Typography>
                    <TextField
                      type="text"
                      disabled
                      id={`values.emergencyContacts[${i}].address`}
                      value={values.emergencyContacts[i].address}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Address' }}
                      fullWidth
                    />
                  </label>
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  );
};

UserDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
};

export default withStyles(styles)(UserDetails);
