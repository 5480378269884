import { startOfWeek, endOfWeek, differenceInDays } from "date-fns";
import { checkHasUserPermissions } from './check-user-permission';
import { userTypes } from 'common/permissions/userTypes';

export const canEditWorklog = (worklog, user, locationSettings) => {
  const weekStartsOn = /mon/i.test(locationSettings.weekStart) ? 1 : 0;

    const requestedPermissions = [
      'worklogsCreate',
      'worklogsEdit',
    ];
    const submittedPermissions = [
      'worklogsEditSubmitted',
      'worklogsFullAccess',
    ];
    const permissionsByRole = {
      [userTypes.projectManagement]: ['scheduleEdit', 'scheduleFullAccess'],
      [userTypes.accounting]: ['scheduleFullAccess'],
    }

    const isAssignedWorker = !!(worklog.workers?.find(w => w._id === user?._id));
    const isOwner = worklog.owner === user?._id;
    const isForemanWithinWeek = !!(worklog.workers?.find(w => w.foreman && w._id === user._id)) &&
      startOfWeek(new Date(), { weekStartsOn }).getTime() <= new Date(worklog.createdAt).getTime() &&
      endOfWeek(new Date(), { weekStartsOn }).getTime() >= new Date(worklog.createdAt).getTime();
    const hasPermissionsByRole = permissionsByRole[user?.profile?.role?.roleName]?.length &&
      checkHasUserPermissions(permissionsByRole[user?.profile?.role?.roleName], user);
    const isFieldTech24HoursRule = isAssignedWorker && user.profile?.role?.roleName === userTypes.fieldTechnician && differenceInDays(new Date(), worklog.hours.end) < 24;

    const hasLocationAccess = user.assignedToLocations?.includes(worklog.tenantLocationId);

    const hasDefaultAccess = checkHasUserPermissions(requestedPermissions, user) || hasPermissionsByRole || isAssignedWorker || isOwner;
    const hasSubmittedAccess = checkHasUserPermissions(submittedPermissions, user) || isForemanWithinWeek || isFieldTech24HoursRule || isFieldTech24HoursRule;

    if (worklog?.submittedAt) {
      return hasSubmittedAccess;
    }

    return hasLocationAccess && (hasDefaultAccess || hasSubmittedAccess);
}