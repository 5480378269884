import { createAction } from '@reduxjs/toolkit';

const setLoading = createAction('personalProfile/setLoading');
const getPersonalProfile = createAction('personalProfile/getPersonalProfile');
const updatePersonalProfile = createAction('personalProfile/updatePersonalProfile');
const partiallyUpdatePersonalProfile = createAction('personalProfile/partiallyUpdatePersonalProfile');
const clearPersonalProfile = createAction('personalProfile/clearPersonalProfile');
const setSpecialProfilePermissions = createAction('personalProfile/setSpecialProfilePermissions');
const setOrganization = createAction('personalProfile/setOrganization');
const setOrganizationLocation = createAction('personalProfile/setOrganizationLocation');
const updateOrganizationLocation = createAction('personalProfile/updateOrganizationLocation');
const setOrganizationSettings = createAction('personalProfile/setOrganizationSettings');
const setOrganizationLocationSettings = createAction('personalProfile/setOrganizationLocationSettings');
const socketConnect = createAction('personalProfile/socketConnect');
const socketDisconnect = createAction('personalProfile/socketDisconnect');

export default {
  setLoading,
  getPersonalProfile,
  updatePersonalProfile,
  partiallyUpdatePersonalProfile,
  clearPersonalProfile,
  setSpecialProfilePermissions,
  setOrganization,
  setOrganizationLocation,
  updateOrganizationLocation,
  setOrganizationSettings,
  setOrganizationLocationSettings,
  // personal profile sockets
  socketConnect,
  socketDisconnect,
};
