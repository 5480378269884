import { withStyles } from '@material-ui/core/styles';
import { Controller, useFormContext, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { isIOS, Option } from 'components/Option';
import styles from './styles';
import IOSSwitch from '../../../../../../../components/IOSSwitch/IOSSwitch';
import classnames from 'classnames';

const WeekShift = ({ classes, control, pageType, getValues, watch }) => {
  const locationSettings = useSelector((state) => state.settings?.locationSettings || {});

  const timeclockValue = watch('timeclock');

  const {
    field: { onChange: onWeekStartChange },
  } = useController({
    name: 'weekStart',
    control,
    defaultValue: locationSettings?.weekStart || 'Sun',
  });
  const {
    // field: { onChange: onInjuryReportChange },
  } = useController({
    name: 'injuryReport',
    control,
    defaultValue: locationSettings?.injuryReport || false,
  });

  const {
    // field: { onChange: onMiscStipendChange },
  } = useController({
    name: 'miscStipend',
    control,
    defaultValue: locationSettings?.miscStipend || false,
  });
  const { setValue } = useFormContext();

  return (
    <>
      <Grid item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
        <Controller
          name="timeclock"
          control={control}
          defaultValue={locationSettings?.timeclock || false}
          render={({ name, value, onChange }) => (
            <IOSSwitch
              name={name}
              small
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          )}
        />
        <span className={classnames(classes.secondaryText, classes.inlineBlock)}>
          Time Clock
        </span>
      </Grid>
      {timeclockValue && (
        <Grid item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
          <Controller
            control={control}
            name="miscStipend"
            defaultValue={locationSettings?.miscStipend || false}
            render={({ name, value, onChange }) => (
              <IOSSwitch
                name={name}
                small
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
          <span className={classnames(classes.secondaryText, classes.inlineBlock)}>
            Miscellaneous Stipend
          </span>
        </Grid>
      )}
      {timeclockValue && (
        <Grid item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
          <Controller
            name="injuryReport"
            control={control}
            defaultValue={locationSettings?.injuryReport || false}
            render={({ name, value, onChange }) => (
              <IOSSwitch
                name={name}
                small
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
          <span className={classnames(classes.secondaryText, classes.inlineBlock)}>
            Injury Report
          </span>
        </Grid>
      )}
      <Grid xs={12}>
        <Typography variant="h4" style={{ padding: '10px 20px' }}>
          Shifts
        </Typography>
      </Grid>
      <Grid container item alignItems="center" className={classes.workShiftBlock}>
        {pageType === 'edit' ? (
          <Grid item xs={12} md={6} lg={5}>
            <label htmlFor="weekStart" className={classnames(classes.specificField)}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                Default Work Week:
              </Typography>
              <Controller
                name="weekStart"
                control={control}
                defaultValue={locationSettings?.weekStart || 'Sun'}
                as={
                  <TextField
                    select
                    id="weekStart"
                    name="weekStart"
                    variant="outlined"
                    onChange={onWeekStartChange}
                    className={classnames(classes.textField, classes.specificFieldInput)}
                    inputProps={{ 'aria-label': 'weekStart' }}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    <Option key={'Sun'} value={'Sun'}>
                      Sun - Sat
                    </Option>
                    <Option key={'Mon'} value={'Mon'}>
                      Mon - Sun
                    </Option>
                  </TextField>
                }
              />
            </label>
          </Grid>
        ) : (
          <Grid item xs={12} md={6} lg={3}>
            <label htmlFor="weekStart">
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                Selected Week Shift:
              </Typography>
              <Typography variant="h6">
                {locationSettings?.weekStart === 'Mon' ? 'Monday - Sunday' : 'Sunday - Saturday'}
              </Typography>
            </label>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default withStyles(styles)(WeekShift);
