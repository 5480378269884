import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import classnames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { FieldArray } from 'formik';

import styles from './styles';
import LocationAccessPermission from 'components/LocationAccessPermission/LocationAccessPermission';

const EquipmentDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  palette,
  setFieldValue,
  setErrors,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="name">
          <Typography variant="body1" color="textSecondary">
            Name<span className={classes.requiredSign}>*</span>:
          </Typography>
          <TextField
            id="name"
            name="name"
            value={values.name}
            error={Boolean(errors.name) && Boolean(touched.name)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Name' }}
            fullWidth
          />
          {errors.name && touched.name ? <div className={classes.error}>{errors.name}</div> : null}
        </label>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="number">
          <Typography variant="body1" color="textSecondary">
            Number<span className={classes.requiredSign}>*</span>:
          </Typography>
          <TextField
            id="number"
            name="number"
            value={values.number}
            error={Boolean(errors.number) && Boolean(touched.number)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Number' }}
            fullWidth
          />
          {errors.number && touched.number ? (
            <div className={classes.error}>{errors.number}</div>
          ) : null}
        </label>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="yearMade">
          <Typography variant="body1" color="textSecondary">
            Year made:
          </Typography>
          <TextField
            id="yearMade"
            name="yearMade"
            value={values.yearMade}
            error={Boolean(errors.yearMade) && Boolean(touched.yearMade)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Year made' }}
            fullWidth
          />
          {errors.yearMade && touched.yearMade ? (
            <div className={classes.error}>{errors.yearMade}</div>
          ) : null}
        </label>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="model">
          <Typography variant="body1" color="textSecondary">
            Model:
          </Typography>
          <TextField
            id="model"
            name="model"
            value={values.model}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Model' }}
            fullWidth
          />
        </label>
      </Grid>

      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="color">
          <Typography variant="body1" color="textSecondary">
            Color:
          </Typography>
          <TextField
            select
            id="color"
            name="color"
            value={values.color}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            inputProps={{ 'aria-label': 'Color' }}
            SelectProps={{
              renderValue: (value) => (
                <div className={classes.valueColor} style={{ backgroundColor: value }} />
              ),
              MenuProps: {
                classes: {
                  list: classes.customSelect,
                },
              },
            }}
            fullWidth
          >
            {palette.map((color, i) => (
              <MenuItem
                key={i}
                value={color}
                className={classnames(
                  classes.colorItemWrapper,
                  values.color === color && classes.checkedOuter
                )}
              >
                <div
                  style={{ backgroundColor: color }}
                  className={classnames(
                    classes.colorItem,
                    values.color === color && classes.checkedInner
                  )}
                >
                  {values.color === color && <DoneIcon className={classes.checkedIcon} />}
                </div>
              </MenuItem>
            ))}
          </TextField>
        </label>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="description">
          <Typography variant="body1" color="textSecondary">
            Description:
          </Typography>
          <TextField
            id="description"
            name="description"
            rowsMax={4}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textArea}
            variant="outlined"
            multiline
          />
        </label>
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <FieldArray name="assignedToLocations">
          {({ remove, push, move, replace }) => (
            <LocationAccessPermission
              homeBaseLocationId={values.homeBaseLocationId}
              useSettingsLocations={true}
              values={values.assignedToLocations}
              remove={remove}
              push={push}
              move={move}
              replace={replace}
              setFieldValue={setFieldValue}
              error={errors?.assignedToLocations}
              onChange={() => setErrors('assignedToLocations', '')}
            />
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
};

EquipmentDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  palette: propTypes.array.isRequired,
};

export default withStyles(styles)(EquipmentDetails);
