import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from 'components/AutocompleteMui/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import classnames from 'classnames';
import { FieldArray, Field } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import DatePickerInput from 'components/DatePickerInput';
import { getProjectName, checkUserRole } from 'helpers/_helpers';
import styles from './styles';
import { isIOS, Option } from 'components/Option';
import LocationCreator from './components/LocationCreator';
import { AlternateContacts } from './components/AlternateContacts/AlternateContacts';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuItem from '@material-ui/core/MenuItem';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const SheetDetails = ({
  classes,
  contractors,
  projects,
  projectLocations,
  hours,
  minutes,
  dayPart,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSelectContractor,
  handleSelectProject,
  handleChangeTemperature,
  type,
  handleChangeStartTimeHours,
  handleChangeStartTimeMinutes,
  handleChangeStartTimeDayPart,
  handleChangeShift,
  days,
  sheet,
  shortDate,
  handleSelectLocation,
  handleRemoveLocation,
  handleAppendLocation,
  locationEditor,
  rallyPointEditor,
  handleLocationEditor,
  handleRallyPointEditor,
  setLocations,
  contractorAlternateContacts,
  setContractorAlternateContacts,
  documents,
  calendarStartOfWeek,
  calendarEndOfWeek,
  tenantLocationId,
  setTenantLocationId,
}) => {
  const matches = useMediaQuery('(max-width: 999px)');
  const defaultValue = { _id: '', name: '' };
  const [value, setValue] = useState(defaultValue);
  const getTimeFormatHook = useTimeSettingsFormat();
  const settings = useSelector((state) => state.personalProfile?.organization?.settings);
  const activeLocations = useSelector((state) => state.personalProfile.activeLocations);

  useEffect(() => {
    setValue(contractors.find((c) => c._id === values.contractorId) || defaultValue);
  }, [values.contractorId]);

  const formatLocationName = (location) => {
    const a = location.address;
    const b = location.zipcode ? `, ${location.zipcode}` : '';
    const c = location.name ? ` - ${location.name}` : '';
    return `${a}${b}${c}`;
  };

  const isFieldTechnician = checkUserRole('Field Technician');

  const handleTenantLocationChange = (e) => {
    setTenantLocationId(e.target.value);
  }

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={4}>
        {isFieldTechnician && type === 'create' && (
          <Grid item xs={12} className={classes.zeroPaddingTop}>
            <label htmlFor="contractorId">
              <Typography variant="body1" color="textSecondary">
                Location<span className={classes.requiredSign}>*</span>:
              </Typography>

              <div style={{ position: 'relative' }}>
                <TextField
                  select
                  id="tenantLocationId"
                  className={classes.textField}
                  value={tenantLocationId}
                  onChange={handleTenantLocationChange}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Company Location' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {activeLocations.map(location => (
                    <Option key={location._id} value={location._id}>
                      {location.name}
                    </Option>
                  ))}
                </TextField>
              </div>
            </label>
          </Grid>
        )}
        <Grid item xs={12} lg={8} className={classes.zeroPaddingTop}>
          <label htmlFor="contractorId">
            <Typography variant="body1" color="textSecondary">
              Contractor<span className={classes.requiredSign}>*</span>:
            </Typography>

            <div style={{ position: 'relative' }}>
              <Autocomplete
                id="contractorId"
                options={[{ _id: '', name: '' }, ...contractors]}
                getOptionLabel={(option) => option.name || ''}
                classes={{
                  inputRoot: classnames(classes.inputRoot, {
                    [classes.inputRootError]: errors.contractorId,
                  }),
                  input: classes.input,
                  endAdornment: classes.endAdornment,
                  option: classes.option,
                }}
                disablePortal
                value={value}
                onChange={handleSelectContractor(setFieldValue)}
                getOptionSelected={(option, value) => option._id === value._id}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </div>
            {errors.contractorId && touched.contractorId ? (
              <div className={classes.error}>{errors.contractorId}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="forceAccount">
            <Typography variant="body1" color="textSecondary">
              Time & Material:
            </Typography>
            <TextField
              select
              id="forceAccount"
              name="forceAccount"
              className={classes.textField}
              value={values.forceAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Time & Material' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={0} value="No">
                No
              </Option>
              <Option key={1} value="Full">
                Full
              </Option>
              <Option key={2} value="Partial">
                Partial
              </Option>
            </TextField>
          </label>
        </Grid>
        {values.forceAccount === 'Partial' && (
          <>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <label htmlFor="forceAccountHours">
                <Typography variant="body1" color="textSecondary">
                  T&M hours:
                </Typography>
                <TextField
                  id="forceAccountHours"
                  name="forceAccountHours"
                  className={classes.textField}
                  value={values.forceAccountHours}
                  error={Boolean(errors.forceAccountHours) && Boolean(touched.forceAccountHours)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Time & Material hours' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                />
                {errors.forceAccountHours && touched.forceAccountHours ? (
                  <div className={classes.error}>{errors.forceAccountHours}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <label htmlFor="forceAccountMinutes">
                <Typography variant="body1" color="textSecondary">
                  T&M minutes:
                </Typography>
                <TextField
                  select
                  id="forceAccountMinutes"
                  name="forceAccountMinutes"
                  className={classes.textField}
                  value={values.forceAccountMinutes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Time & Material minutes' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {minutes.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
              </label>
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={8} className={classes.zeroPaddingTop}>
          <label htmlFor="projectId">
            <Typography variant="body1" color="textSecondary">
              Project<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              select
              id="projectId"
              name="projectId"
              value={values.projectId}
              error={Boolean(errors.projectId) && Boolean(touched.projectId)}
              onChange={handleSelectProject(setFieldValue)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Project' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={0} value="" />
              {projects
                .sort((a, b) => a.route.localeCompare(b.route))
                .map((project) => (
                  <Option key={project._id} value={project._id}>
                    {getProjectName(project, { jobNumber: true })}
                  </Option>
                ))}
            </TextField>
            {errors.projectId && touched.projectId ? (
              <div className={classes.error}>{errors.projectId}</div>
            ) : null}
          </label>
        </Grid>
        {type === 'create' && (
          <Grid item xs={12} md={4} className={classnames(classes.zeroPaddingTop)}>
            <label htmlFor="startDate">
              <Typography variant="body1" color="textSecondary">
                Date:
              </Typography>
              <DatePickerInput
                selectedDay={values.startDate}
                setFieldValue={setFieldValue}
                field="startDate"
                alignRight
                fullWidth
                minDate={calendarStartOfWeek}
                maxDate={calendarEndOfWeek}
              />
            </label>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={12}
          className={classnames(classes.zeroPaddingTop, classes.hideForDesktop)}
        >
          <label htmlFor="gridDate">
            <Typography variant="body1" color="textSecondary">
              Grid Date:
            </Typography>
            <DatePickerInput
              selectedDay={values.gridDate}
              setFieldValue={setFieldValue}
              field="gridDate"
              alignRight
              fullWidth
              minDate={calendarStartOfWeek}
              maxDate={calendarEndOfWeek}
            />
          </label>
        </Grid>
        {!!values.contractorId && (
          <>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              <Divider />
            </Grid>

            <AlternateContacts
              CloseIcon={CloseIcon}
              classes={classes}
              values={values}
              setFieldValue={setFieldValue}
              contractorAlternateContacts={contractorAlternateContacts}
              setContractorAlternateContacts={setContractorAlternateContacts}
            />
          </>
        )}

        <Grid item xs={12} className={classes.zeroPaddingTop} style={{ marginBottom: 15 }}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container xs={12} lg={12} className={classnames(classes.zeroPaddingTop)}>
        <Grid
          item
          xs={12}
          lg={9}
          className={classnames(classes.zeroPaddingTop, classes.flexColumn)}
        >
          {values.rallyPoints.length ? (
            values.rallyPoints.map((locationId, index) => (
              <Grid container xs={12} lg={11} className={classes.zeroPaddingTop}>
                <div
                  className={classnames(classes.flexColumn, classes.paddingBottom)}
                  key={`${locationId}_${index}`}
                >
                  <Typography variant="body1" color="textSecondary">
                    Rally Point{values.rallyPoints.length === 1 ? null : ` ${index + 1}`}:
                  </Typography>
                  <div className={classnames(classes.flexBox, classes.fullWidth)}>
                    <TextField
                      select
                      id={`rallyPoints[${index}]`}
                      name={`rallyPoints[${index}]`}
                      className={classes.textField}
                      value={locationId}
                      onChange={handleSelectLocation(
                        setFieldValue,
                        values.rallyPoints,
                        index,
                        'rallyPoint'
                      )}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Location' }}
                      SelectProps={{
                        native: isIOS,
                      }}
                      fullWidth
                    >
                      <Option key={'001'} value="" />
                      {projectLocations
                        .filter(
                          (location) =>
                            (location.type === 'rallyPoint' &&
                              !values.rallyPoints.find((loc) => location._id === loc)) ||
                            location._id === locationId
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((location) => (
                          <Option key={location._id} value={location._id}>
                            {formatLocationName(location)}
                          </Option>
                        ))}
                      {values.projectId && (
                        <Option key={'add'} value={'add'} className={classes.primary}>
                          + Create New Rally Point
                        </Option>
                      )}
                    </TextField>
                    <IconButton
                      className={classes.deleteLocationButton}
                      onClick={handleRemoveLocation(
                        setFieldValue,
                        values.rallyPoints,
                        index,
                        'rallyPoints'
                      )}
                      aria-label="Delete"
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                {index + 1 === values.rallyPoints.length &&
                  values.rallyPoints &&
                  projectLocations.filter((location) => location.type === 'rallyPoint').length >
                    values.rallyPoints.length &&
                  !rallyPointEditor && (
                    <div className={classes.flexBox}>
                      <Button
                        variant="text"
                        className={classes.primaryTextButton}
                        onClick={handleAppendLocation(
                          setFieldValue,
                          values.rallyPoints,
                          'rallyPoints'
                        )}
                        disableRipple
                      >
                        + Add Rally Point
                      </Button>
                    </div>
                  )}
              </Grid>
            ))
          ) : (
            <div className={classnames(classes.flexColumn, classes.paddingBottom)}>
              <Typography variant="body1" color="textSecondary">
                Rally Point:
              </Typography>
              <div className={classnames(classes.flexBox, classes.fullWidth)}>
                <TextField
                  select
                  id="rallyPointsId"
                  name="rallyPointsId"
                  className={classes.textField}
                  value=""
                  onChange={handleSelectLocation(
                    setFieldValue,
                    values.rallyPoints,
                    0,
                    'rallyPoint'
                  )}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Location' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  <Option key={`index_0`} value="" />
                  {projectLocations
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((location) => (
                      <Option key={location._id} value={location._id}>
                        {formatLocationName(location)}
                      </Option>
                    ))}
                </TextField>
                <IconButton
                  className={classes.deleteLocationButton}
                  onClick={handleRemoveLocation(
                    setFieldValue,
                    values.rallyPoints,
                    0,
                    'rallyPoints'
                  )}
                  aria-label="Delete"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          className={classnames(classes.zeroPaddingTop, classes.flexColumn)}
        >
          <Grid
            item
            xs={12}
            md={12}
            className={classnames(classes.zeroPaddingTop, classes.hideForMob)}
          >
            <label htmlFor="gridDate">
              <Typography variant="body1" color="textSecondary">
                Grid Date:
              </Typography>
              <DatePickerInput
                selectedDay={values.gridDate}
                setFieldValue={setFieldValue}
                field="gridDate"
                alignRight
                fullWidth
                minDate={calendarStartOfWeek}
                maxDate={calendarEndOfWeek}
              />
            </label>
          </Grid>
        </Grid>
        {rallyPointEditor ? (
          <LocationCreator
            locationEditor={rallyPointEditor}
            values={values}
            handleLocationEditor={handleRallyPointEditor}
            setFieldValue={setFieldValue}
            setLocations={setLocations}
            type={'rallyPoint'}
            fieldName={'rallyPoints'}
          />
        ) : null}
      </Grid>
      <Grid container spacing={4} className={classes.paddingBottomBig} style={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} className={classnames(classes.zeroPaddingTop, classes.flexColumn)}>
        <Grid
          item
          xs={12}
          lg={9}
          className={classnames(classes.zeroPaddingTop, classes.flexColumn)}
        >
          {values.locations.length ? (
            values.locations.map((locationId, index) => (
              <Grid container xs={12} lg={11} className={classes.zeroPaddingTop}>
                <div
                  className={classnames(classes.flexColumn, classes.paddingBottom)}
                  key={`${locationId}_${index}`}
                >
                  <Typography variant="body1" color="textSecondary">
                    Project Location{values.locations.length === 1 ? null : ` ${index + 1}`}:
                  </Typography>
                  <div className={classnames(classes.flexBox, classes.fullWidth)}>
                    <TextField
                      select
                      id={`locations[${index}]`}
                      name={`locations[${index}]`}
                      className={classes.textField}
                      value={locationId}
                      onChange={handleSelectLocation(
                        setFieldValue,
                        values.locations,
                        index,
                        'projectLocation'
                      )}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Location' }}
                      SelectProps={{
                        native: isIOS,
                      }}
                      fullWidth
                    >
                      <Option key={'000'} value="" />
                      {projectLocations
                        .filter(
                          (location) =>
                            (location.type === 'projectLocation' &&
                              !values.locations.find((loc) => location._id === loc)) ||
                            location._id === locationId
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((location) => (
                          <Option key={location._id} value={location._id}>
                            {formatLocationName(location)}
                          </Option>
                        ))}
                      {values.projectId && (
                        <Option key={'add'} value={'add'} className={classes.primary}>
                          + Create New Project Location
                        </Option>
                      )}
                    </TextField>
                    <IconButton
                      className={classes.deleteLocationButton}
                      onClick={handleRemoveLocation(
                        setFieldValue,
                        values.locations,
                        index,
                        'locations'
                      )}
                      aria-label="Delete"
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                {index + 1 === values.locations.length &&
                  values.locations &&
                  projectLocations.filter((location) => location.type === 'projectLocation')
                    .length > values.locations.length &&
                  !locationEditor && (
                    <div className={classes.flexBox}>
                      <Button
                        variant="text"
                        className={classes.primaryTextButton}
                        onClick={handleAppendLocation(setFieldValue, values.locations, 'locations')}
                        disableRipple
                      >
                        + Add Project Location
                      </Button>
                    </div>
                  )}
              </Grid>
            ))
          ) : (
            <div className={classnames(classes.flexColumn, classes.paddingBottom)}>
              <Typography variant="body1" color="textSecondary">
                Project Location:
              </Typography>
              <div className={classnames(classes.flexBox, classes.fullWidth)}>
                <TextField
                  select
                  id="locationId"
                  name="locationId"
                  className={classes.textField}
                  value=""
                  onChange={handleSelectLocation(
                    setFieldValue,
                    values.locations,
                    0,
                    'projectLocation'
                  )}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Location' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  <Option key={`index_0`} value="" />
                  {projectLocations
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((location) => (
                      <Option key={location._id} value={location._id}>
                        {formatLocationName(location)}
                      </Option>
                    ))}
                </TextField>
                <IconButton
                  className={classes.deleteLocationButton}
                  onClick={handleRemoveLocation(setFieldValue, values.locations, 0, 'locations')}
                  aria-label="Delete"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
        </Grid>

        {locationEditor ? (
          <LocationCreator
            locationEditor={locationEditor}
            values={values}
            handleLocationEditor={handleLocationEditor}
            setFieldValue={setFieldValue}
            setLocations={setLocations}
            type={'projectLocation'}
            fieldName={'locations'}
          />
        ) : null}
      </Grid>
      <Grid container spacing={4} className={classes.paddingBottomBig} style={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {!!documents?.length && (
        <>
          <Grid
            item
            xs={12}
            lg={12}
            className={classnames(classes.zeroPaddingTop, classes.paddingBottom)}
          >
            <Typography variant="body1" color="textSecondary">
              Documents:
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            className={classnames(classes.zeroPaddingTop)}
            spacing={matches ? 0 : 3}
          >
            {documents?.length === 1 ? (
              <Grid item xs={12} lg={8}>
                <span>
                  <Link
                    variant="body1"
                    className={classes.primaryAdd}
                    href={documents[0].url}
                    target="_blank"
                  >
                    {documents[0].name}
                  </Link>
                  &nbsp;
                </span>
              </Grid>
            ) : (
              <Grid item xs={12} lg={8} className={classnames(classes.zeroPaddingTop)}>
                <TextField
                  select
                  id="documentStorageId"
                  name="documentStorageId"
                  className={classes.textFieldSelect}
                  variant="outlined"
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  label={'Select the document to open'}
                  inputProps={{
                    shrink: true,
                    'aria-label': 'Shift',
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  fullWidth
                  size="small"
                  value={''}
                >
                  {[...documents]
                    .sort((a, b) => a?.name?.localeCompare(b?.name))
                    .map((doc) => (
                      <MenuItem
                        key={doc.name}
                        value={doc.name}
                        onClick={(e) => {
                          window.open(doc.url, '_black', 'noreferrer');
                        }}
                      >
                        {doc.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4} className={classes.paddingBottomBig} style={{ marginTop: 5 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="timeOfDay">
            <Typography variant="body1" color="textSecondary">
              Shift:
            </Typography>
            <TextField
              select
              id="timeOfDay"
              name="timeOfDay"
              className={classes.textField}
              value={values.timeOfDay}
              onChange={handleChangeShift(setFieldValue, values)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Shift' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={0} value="Day">
                {isIOS ? '🌤 ' : <img src={IconSunny} alt="sunny" className={classes.shiftImage} />}
                Day
              </Option>
              <Option key={1} value="Night">
                {isIOS ? '🌙 ' : <img src={IconMoon} alt="moon" className={classes.shiftImage} />}
                Night
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label>
            <Typography variant="body1" color="textSecondary">
              Start Time:
            </Typography>
          </label>
          <div className={classes.tripleSelectWrapper}>
            <TextField
              select
              id="startTimeHours"
              name="startTimeHours"
              className={classes.textField}
              value={values.startTimeHours}
              onChange={handleChangeStartTimeHours(setFieldValue, values)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Start time hours' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              {hours.map((value, index) => (
                <Option key={index} value={value}>
                  {value}
                </Option>
              ))}
            </TextField>
            :
            <TextField
              select
              id="startTimeMinutes"
              name="startTimeMinutes"
              className={classes.textField}
              value={values.startTimeMinutes}
              onChange={handleChangeStartTimeMinutes(setFieldValue)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Start time minutes' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              {minutes.map((value, index) => (
                <Option key={index} value={value}>
                  {value}
                </Option>
              ))}
            </TextField>
            {getTimeFormatHook().is12Format && (
              <TextField
                select
                id="startTimeDayPart"
                name="startTimeDayPart"
                className={classes.textField}
                value={values.startTimeDayPart}
                onChange={handleChangeStartTimeDayPart(setFieldValue, values)}
                onBlur={handleBlur}
                variant="outlined"
                inputProps={{ 'aria-label': 'Start time' }}
                SelectProps={{
                  native: isIOS,
                }}
                fullWidth
              >
                {dayPart.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </TextField>
            )}
          </div>
        </Grid>
        <Grid container direction="row" alignItems="center" item xs={12} lg={4}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Field
              type="checkbox"
              name="travelTimeOnly"
              render={({ field }) => {
                return (
                  <Checkbox
                    color="primary"
                    {...field}
                    checked={field.value}
                    className={classes.primaryCheckbox}
                  />
                );
              }}
            />
            <Typography variant="body1" className={classes.primaryAdd}>
              {values?.isRainPayEnabled ? 'Omit Job Time' : 'Travel Time Only'}
            </Typography>
          </label>
        </Grid>
        {type === 'create' ? (
          <>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <label>
                <Typography variant="body1" color="textSecondary">
                  On Job Time:
                </Typography>
              </label>
              <div className={classes.tripleSelectWrapper}>
                <TextField
                  select
                  id="onJobHours"
                  name="onJobHours"
                  className={classes.textField}
                  value={values.onJobHours}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.travelTimeOnly}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'On job hours' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {hours.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
                :
                <TextField
                  select
                  id="onJobMinutes"
                  name="onJobMinutes"
                  className={classes.textField}
                  value={values.onJobMinutes}
                  onChange={handleChange}
                  disabled={values.travelTimeOnly}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'On job minutes' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {minutes.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
                {getTimeFormatHook().is12Format && (
                  <TextField
                    select
                    id="onJobDayPart"
                    name="onJobDayPart"
                    className={classes.textField}
                    value={values.onJobDayPart}
                    disabled={values.travelTimeOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'On job' }}
                    SelectProps={{
                      native: isIOS,
                    }}
                    fullWidth
                  >
                    {dayPart.map((value, index) => (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <label>
                <Typography variant="body1" color="textSecondary">
                  Estimated Time:
                </Typography>
              </label>
              <div className={classes.doubleSelectWrapper}>
                <TextField
                  select
                  id="estimatedHours"
                  name="estimatedHours"
                  className={classes.textField}
                  value={values.estimatedHours}
                  disabled={values.travelTimeOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Estimated hours' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {getTimeFormatHook().is12Format && (
                    <Option key={0} value="0">
                      0
                    </Option>
                  )}
                  {hours.map((value, index) => (
                    <Option key={index + 1} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
                <TextField
                  select
                  id="estimatedMinutes"
                  name="estimatedMinutes"
                  className={classes.textField}
                  value={values.estimatedMinutes}
                  disabled={values.travelTimeOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Estimated minutes' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  {minutes.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
              </div>
            </Grid>
            {settings?.miscStipend ? (
              <Grid container direction="row" alignItems="center" item xs={12} lg={4}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <Field
                    type="checkbox"
                    name="isRainPayEnabled"
                    render={({ field }) => {
                      return (
                        <Checkbox
                          color="primary"
                          {...field}
                          checked={field.value}
                          className={classes.primaryCheckbox}
                        />
                      );
                    }}
                  />
                  <Typography variant="body1" className={classes.primaryAdd}>
                    Misc. Stipend
                  </Typography>
                </label>
              </Grid>
            ) : (
              <Grid item xs={0} lg={4} />
            )}
          </>
        ) : (
          <Grid item xs={12} className={classes.zeroPaddingTop}>
            <div>
              <Typography variant="body1" color="textSecondary">
                On Job Time:
              </Typography>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <div className={classes.flexBox}>
                    <TextField
                      select
                      name="dayStart"
                      className={classes.onJobTimeField}
                      value={values.dayStart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.dayStart) && Boolean(touched.dayStart)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {days.map((value, index) => (
                        <Option key={index} value={value}>
                          {shortDate(sheet.createdAt, value, getTimeFormatHook().localDayMonth)}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="hourStart"
                      className={classes.onJobTimeField}
                      value={values.hourStart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.hourStart) && Boolean(touched.hourStart)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {hours.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="minuteStart"
                      className={classes.onJobTimeField}
                      value={values.minuteStart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.minuteStart) && Boolean(touched.minuteStart)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {minutes.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="amPmStart"
                      className={classes.onJobTimeField}
                      value={values.amPmStart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.amPmStart) && Boolean(touched.amPmStart)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {dayPart.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  className={classnames(classes.flexBox, classes.spaceAround)}
                >
                  <Typography variant="body1" color="textSecondary">
                    &mdash;
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.flexBox}>
                    <TextField
                      select
                      name="dayEnd"
                      className={classes.onJobTimeField}
                      value={values.dayEnd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.dayEnd) && Boolean(touched.dayEnd)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {days.map((value, index) => (
                        <Option key={index} value={value}>
                          {shortDate(sheet.createdAt, value, getTimeFormatHook().localDayMonth)}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="hourEnd"
                      className={classes.onJobTimeField}
                      value={values.hourEnd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.hourEnd) && Boolean(touched.hourEnd)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {hours.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="minuteEnd"
                      className={classes.onJobTimeField}
                      value={values.minuteEnd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.minuteEnd) && Boolean(touched.minuteEnd)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {minutes.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="amPmEnd"
                      className={classes.onJobTimeField}
                      value={values.amPmEnd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.amPmEnd) && Boolean(touched.amPmEnd)}
                      SelectProps={{
                        native: isIOS,
                      }}
                      variant="outlined"
                    >
                      {dayPart.map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </TextField>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <div>
            <Typography variant="body1" color="textSecondary">
              Travel Time To Job:
            </Typography>
            <div className={classnames(classes.doubleSelectWrapper, classes.alignTop)}>
              <div>
                <TextField
                  id="travelTimeToHours"
                  name="travelTimeToHours"
                  className={classes.textField}
                  value={values.travelTimeToHours}
                  error={Boolean(errors.travelTimeToHours) && Boolean(touched.travelTimeToHours)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Travel time hours',
                    maxLength: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="travelTimeToHours" className={classes.adornment}>
                          h
                        </label>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                {errors.travelTimeToHours && touched.travelTimeToHours ? (
                  <div className={classes.error}>{errors.travelTimeToHours}</div>
                ) : null}
              </div>
              <div>
                <TextField
                  id="travelTimeToMinutes"
                  name="travelTimeToMinutes"
                  className={classes.textField}
                  value={values.travelTimeToMinutes}
                  error={
                    Boolean(errors.travelTimeToMinutes) && Boolean(touched.travelTimeToMinutes)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Travel time minutes',
                    maxLength: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="travelTimeToMinutes" className={classes.adornment}>
                          min
                        </label>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                {errors.travelTimeToMinutes && touched.travelTimeToMinutes ? (
                  <div className={classes.error}>{errors.travelTimeToMinutes}</div>
                ) : null}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={8} className={classes.zeroPaddingTop}>
          <div>
            <Typography variant="body1" color="textSecondary">
              Travel Time From Job:
            </Typography>
            <div className={classnames(classes.doubleSelectWrapper, classes.alignTop)}>
              <div>
                <TextField
                  id="travelTimeFromHours"
                  name="travelTimeFromHours"
                  className={classes.textField}
                  value={values.travelTimeFromHours}
                  error={
                    Boolean(errors.travelTimeFromHours) && Boolean(touched.travelTimeFromHours)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Travel time hours',
                    maxLength: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="travelTimeFromHours" className={classes.adornment}>
                          h
                        </label>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                {errors.travelTimeFromHours && touched.travelTimeFromHours ? (
                  <div className={classes.error}>{errors.travelTimeFromHours}</div>
                ) : null}
              </div>
              <div>
                <TextField
                  id="travelTimeFromMinutes"
                  name="travelTimeFromMinutes"
                  className={classes.textField}
                  value={values.travelTimeFromMinutes}
                  error={
                    Boolean(errors.travelTimeFromMinutes) && Boolean(touched.travelTimeFromMinutes)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'Travel time minutes',
                    maxLength: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="travelTimeFromMinutes" className={classes.adornment}>
                          min
                        </label>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                {errors.travelTimeFromMinutes && touched.travelTimeFromMinutes ? (
                  <div className={classes.error}>{errors.travelTimeFromMinutes}</div>
                ) : null}
              </div>
            </div>
          </div>
        </Grid>
        {values?.isRainPayEnabled && settings?.miscStipend && (
          <>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <div>
                <Typography variant="body1" color="textSecondary">
                  Misc. Stipend:
                </Typography>
                <div className={classnames(classes.doubleSelectWrapper, classes.alignTop)}>
                  <div>
                    <TextField
                      id="rainPayHours"
                      name="rainPayHours"
                      className={classes.textField}
                      value={values.rainPayHours}
                      error={Boolean(errors.rainPayHours) && Boolean(touched.rainPayHours)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'Rain Pay',
                        maxLength: 2,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <label htmlFor="rainPayHours" className={classes.adornment}>
                              h
                            </label>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    {errors.rainPayHours && touched.rainPayHours ? (
                      <div className={classes.error}>{errors.rainPayHours}</div>
                    ) : null}
                  </div>
                  <div>
                    <TextField
                      id="rainPayMinutes"
                      name="rainPayMinutes"
                      className={classes.textField}
                      value={values.rainPayMinutes}
                      error={Boolean(errors.rainPayMinutes) && Boolean(touched.rainPayMinutes)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'Travel time minutes',
                        maxLength: 2,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <label htmlFor="rainPayMinutes" className={classes.adornment}>
                              min
                            </label>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    {errors.rainPayMinutes && touched.rainPayMinutes ? (
                      <div className={classes.error}>{errors.rainPayMinutes}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
              <div>
                <Typography variant="body1" color="textSecondary">
                  Misc. Stipend Description:
                </Typography>
                <div className={classnames(classes.alignTop)}>
                  <div>
                    <TextField
                      id="rainPayDescription"
                      name="rainPayDescription"
                      className={classes.textField}
                      value={values.rainPayDescription}
                      error={
                        Boolean(errors.rainPayDescription) && Boolean(touched.rainPayDescription)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                    />
                    {errors.rainPayDescription && touched.rainPayDescription ? (
                      <div className={classes.error}>{errors.rainPayDescription}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={4} className={classes.paddingBottomBig}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <Grid container spacing={4}>
            <FieldArray
              name="temperatures"
              render={(arrayHelpers) =>
                values.temperatures.map((temperature, index) => (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    key={index}
                    className={classnames(index > 1 && classes.zeroPaddingTop)}
                  >
                    {index === 0 && (
                      <Typography variant="body1" color="textSecondary">
                        Temperature:
                      </Typography>
                    )}
                    {index === 1 && <div className={classes.constantHeight} />}
                    <TextField
                      id={`temperatures[${index}]`}
                      name={`temperatures[${index}]`}
                      className={classes.textField}
                      value={values.temperatures[index]}
                      error={
                        Boolean(errors[`temperature${index}`] && errors[`temperature${index}`]) &&
                        Boolean(touched.temperatures && touched.temperatures[index])
                      }
                      onChange={handleChangeTemperature(setFieldValue, index, values, arrayHelpers)}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 3 }}
                    />
                  </Grid>
                ))
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2} className={classes.zeroPaddingTop}>
          <label htmlFor="humidity">
            <Typography variant="body1" color="textSecondary">
              Humidity:
            </Typography>
            <TextField
              id="humidity"
              name="humidity"
              className={classes.textField}
              value={values.humidity}
              error={Boolean(errors.humidity) && Boolean(touched.humidity)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Humidity' }}
              fullWidth
            />
            {errors.humidity && touched.humidity ? (
              <div className={classes.error}>{errors.humidity}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
          <label htmlFor="weatherNotes">
            <Typography variant="body1" color="textSecondary">
              Weather Notes:
            </Typography>
            <TextField
              id="weatherNotes"
              name="weatherNotes"
              className={classes.textField}
              value={values.weatherNotes}
              error={Boolean(errors.weatherNotes) && Boolean(touched.weatherNotes)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Weather notes' }}
              fullWidth
            />
            {errors.weatherNotes && touched.weatherNotes ? (
              <div className={classes.error}>{errors.weatherNotes}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.paddingBottomBig}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.zeroPaddingTop}>
          <label htmlFor="notes">
            <Typography variant="body1" color="textSecondary">
              Notes:
            </Typography>
            <TextField
              id="notes"
              name="notes"
              className={classes.textField}
              value={values.notes}
              error={Boolean(errors.notes) && Boolean(touched.notes)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Notes' }}
              fullWidth
            />
            {errors.notes && touched.notes ? (
              <div className={classes.error}>{errors.notes}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} className={classes.zeroPaddingTop}>
          <label htmlFor="schedulerNotes">
            <Typography variant="body1" color="textSecondary">
              Internal Notes:
            </Typography>
            <TextField
              id="schedulerNotes"
              name="schedulerNotes"
              className={classes.textField}
              value={values.schedulerNotes}
              error={Boolean(errors.schedulerNotes) && Boolean(touched.schedulerNotes)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Internal notes' }}
              fullWidth
              disabled={
                !(
                  checkUserRole('Dispatcher') ||
                  checkUserRole('Admin') ||
                  checkUserRole('Super Admin')
                )
              }
            />
            {errors.schedulerNotes && touched.schedulerNotes ? (
              <div className={classes.error}>{errors.schedulerNotes}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>
    </>
  );
};

SheetDetails.propTypes = {
  classes: propTypes.object.isRequired,
  contractors: propTypes.array.isRequired,
  projects: propTypes.array.isRequired,
  projectLocations: propTypes.array.isRequired,
  hours: propTypes.array.isRequired,
  minutes: propTypes.array.isRequired,
  dayPart: propTypes.array.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  handleSelectContractor: propTypes.func.isRequired,
  handleSelectProject: propTypes.func.isRequired,
  handleChangeTemperature: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  handleChangeStartTimeHours: propTypes.func.isRequired,
  handleChangeStartTimeMinutes: propTypes.func.isRequired,
  handleChangeStartTimeDayPart: propTypes.func.isRequired,
  handleChangeShift: propTypes.func.isRequired,
  days: propTypes.array.isRequired,
  sheet: propTypes.object,
  shortDate: propTypes.func.isRequired,
  handleSelectLocation: propTypes.func.isRequired,
  handleRemoveLocation: propTypes.func.isRequired,
  handleAppendLocation: propTypes.func.isRequired,
};

export default withStyles(styles)(SheetDetails);
