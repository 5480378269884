import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import classnames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './styles';

const LocationDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  palette,
  usedColors,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="name">
          <Typography variant="body1" color="textSecondary">
            Name
          </Typography>
          <TextField
            id="name"
            name="name"
            value={values.name}
            error={Boolean(errors.name) && Boolean(touched.name)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Name' }}
            fullWidth
          />
          {errors.name && touched.name ? <div className={classes.error}>{errors.name}</div> : null}
        </label>
      </Grid>


      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="address">
          <Typography variant="body1" color="textSecondary">
            Address
          </Typography>
          <TextField
            id="address"
            name="address"
            value={values.address}
            error={Boolean(errors.address) && Boolean(touched.address)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Address' }}
            fullWidth
          />
          {errors.address && touched.address ? <div className={classes.error}>{errors.address}</div> : null}
        </label>
      </Grid>

      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="color">
          <Typography variant="body1" color="textSecondary">
            Color:
          </Typography>
          <TextField
            select
            id="color"
            name="color"
            value={values.color}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            inputProps={{ 'aria-label': 'Color' }}
            SelectProps={{
              renderValue: (value) => (
                <div className={classes.valueColor} style={{ backgroundColor: value }} />
              ),
              MenuProps: {
                classes: {
                  list: classes.customSelect,
                },
              },
            }}
            fullWidth
          >
            {palette.filter(color => (color === values.color || !usedColors.includes(color))).map((color, i) => (
              <MenuItem
                key={i}
                value={color}
                className={classnames(
                  classes.colorItemWrapper,
                  values.color === color && classes.checkedOuter
                )}
              >
                <div
                  style={{ backgroundColor: color }}
                  className={classnames(
                    classes.colorItem,
                    values.color === color && classes.checkedInner
                  )}
                >
                  {values.color === color && <DoneIcon className={classes.checkedIcon} />}
                </div>
              </MenuItem>
            ))}
          </TextField>
        </label>
      </Grid>

      <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
        <label htmlFor="active">
          <Typography variant="body1" color="textSecondary">
            Active:
          </Typography>
          <TextField
            select
            id="active"
            name="active"
            value={values.active}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            inputProps={{ 'aria-label': 'Active' }}
            fullWidth
          >
            <MenuItem key={'active'} value={true} >
              Active
            </MenuItem>
            <MenuItem key={'inactive'} value={false} >
              Inactive
            </MenuItem>
          </TextField>
        </label>
      </Grid>
    </Grid>
  );
};

LocationDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  palette: propTypes.array.isRequired,
};

export default withStyles(styles)(LocationDetails);
