import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getProjectName } from '../../../helpers/_helpers';
import Typography from '@material-ui/core/Typography';
import { useWorklogStyles } from '../../WorklogDetails/hooks';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { shortDate } from 'helpers/_date-helpers';
import { TimeForm } from './TimeForm/TimeForm';
import { useSelectedUserClasses } from '../../ResourcesForm/components/PeopleBlock/components/SelectedUser/useStyles';
import { addLeadingZero, generateHoursObject } from 'helpers/helpers';
import { checkUserEditSheetPermissions } from 'helpers/_helpers';
import { useWeekStart } from 'hooks/useWeekStart';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';
import { ReactComponent as BusinessIcon } from 'assets/images/ion_business.svg';

export const Sheet = ({
  data,
  workerId,
  update,
  reset,
  error,
  removeSheet,
  workerStartTime,
  onJobDate,
}) => {
  const { weekDayFromO } = useWeekStart();
  const worklogClasses = useWorklogStyles();
  const selectedUserClasses = useSelectedUserClasses();
  const classes = {};
  const [canEdit, setCanEdit] = useState(false);
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const workerStartTimeFormatted = (hourObj) => {
    const {
      hour: hourStart,
      minute: minuteStart,
      amPm: amPmStart,
    } = convertTimeObject(hourObj, is12Format);

    return {
      hourStart: String(Number(hourStart)),
      minuteStart,
      amPmStart,
    };
  };
  const adaptedWorkerStartTime = useMemo(() => {
    return workerStartTimeFormatted(workerStartTime);
  }, [workerStartTime]);

  const settings = useSelector((store) => store.personalProfile?.organization?.settings);

  const getFormattedHoursRange = () => {
    const { createdAt, hours } = data;
    if (!hours || !hours?.start || !hours?.end) return;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(
        hours.start || hours[0].start,
        hours.end || hours[0].end,
        createdAt,
        getTimeFormatHook
      );

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;
    return `${start} \u2013 ${end}`;
  };

  useEffect(() => {
    const response = checkUserEditSheetPermissions(data, weekDayFromO);
    setCanEdit(response);
  }, [data._id]);

  const { hour, minute, amPm } = convertTimeObject(
    { hour: data.startTime.hour, minute: data.startTime.minute },
    is12Format
  );

  const startTimeInfo = getTimeFormatHook([null, hour, minute, amPm]).formattedTime;

  return (
    <>
      <Grid item xs={12}>
        {data.organizationLocation?.name && (
          <div style={{fontSize: '0.85rem', display: 'flex', color: '#3E82FF', alignItems: 'center' }}>
            <BusinessIcon style={{ width: '14px', marginRight: '4px', filter: 'invert(40%) sepia(72%) saturate(1866%) hue-rotate(205deg) brightness(101%) contrast(104%)', }} />
            {data.organizationLocation?.name}
          </div>
        )}
        <Typography variant="h4">
          {getProjectName(data.project)}
          {data.project.county ? `, ${data.project.county}` : ''}

          {data.project.jobNumber && (
            <span className={worklogClasses.jobNumber}>#{data.project.jobNumber}</span>
          )}
        </Typography>
      </Grid>
      <Grid container item xs={10} spacing={1}>
        {!data.travelTimeOnly && (
          <Grid item xs={12} md={5}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight, classes.paddingBottom)}
            >
              On Job Time:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getFormattedHoursRange()}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <div className={classnames(classes.header, classes.paddingBottom)}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight)}
            >
              Start time:
            </Typography>
          </div>
          <Typography variant="body1" color="textPrimary">
            {startTimeInfo}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(classes.regularFontWeight, classes.paddingBottom)}
          >
            Individual Time To:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {addLeadingZero(data.hours?.travelTimeTo?.hours)}h{' '}
            {addLeadingZero(data.hours?.travelTimeTo?.minutes)}m
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(classes.regularFontWeight, classes.paddingBottom)}
          >
            Individual Time From:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {addLeadingZero(data.hours?.travelTimeFrom?.hours)}h{' '}
            {addLeadingZero(data.hours?.travelTimeFrom?.minutes)}m
          </Typography>
        </Grid>

        {settings?.miscStipend && (
          <Grid item xs={12} md={3}>
            <div className={classnames(classes.header, classes.paddingBottom)}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight)}
              >
                Misc. Stipend:
              </Typography>
            </div>
            <Typography variant="body1" color="textPrimary">
              {addLeadingZero(data?.rainPay?.hours)}h {addLeadingZero(data?.rainPay?.minutes)}m
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={2}>
        {canEdit ? (
          <Button
            variant="text"
            onClick={removeSheet}
            className={selectedUserClasses.removeButton}
            disableRipple
            disableTouchRipple
          >
            Remove
          </Button>
        ) : (
          <Typography variant="body1">
            <strong>CL: </strong> {data.crewLeaders ? data.crewLeaders.join(', ') : 'Unknown'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <TimeForm
          sheet={data}
          workerId={workerId}
          update={update}
          reset={reset}
          sheetError={error}
          workerStartTime={adaptedWorkerStartTime}
          onJobDate={onJobDate}
        />
      </Grid>
      {Boolean(error) && (
        <div className={selectedUserClasses.error}>Time periods cross each other</div>
      )}
    </>
  );
};
