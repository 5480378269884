import { PERSONAL_PROFILE_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import personalProfileActions from 'store/personalProfile/personalProfileActions';
import { fetchPersonalProfile } from '../personalProfileOperations';


const handlers = {
  [PERSONAL_PROFILE_EVENT.user.personalProfileUpdated]: (store) => {
    const user = store.getState().personalProfile.user;
    if (!user) return { name: 'undefined', listener: () => {} }
  
    const eventName = PERSONAL_PROFILE_EVENT.user.personalProfileUpdated.replace(`{userId}`, user._id);
    const listener = (data) => {
      const stateUser = store.getState().personalProfile.user;

      const stateUserLocations = stateUser?.assignedToLocations.slice() || [];
      const dataAssignedLocations = data?.assignedToLocations?.slice() || [];
      if (stateUserLocations.sort().join('_') !== dataAssignedLocations?.sort()?.join('_')) {
        store.dispatch(fetchPersonalProfile());
      } else {
        store.dispatch(personalProfileActions.partiallyUpdatePersonalProfile(data))
      }

      if (stateUser?.tenantLocationId !== data?.tenantLocationId) {
        const locations = store.getState().personalProfile.locations;
        const nextLocation = locations.find(location => location._id === data?.tenantLocationId);
        if (nextLocation) {
          store.dispatch(personalProfileActions.setOrganizationLocation(nextLocation));
        }
      }
    }

    return { name: eventName, listener };
  },
};

export const personalProfileSubscriptionMiddleware = subscriptionsFabric(
  personalProfileActions,
  PERSONAL_PROFILE_EVENT.user,
  handlers
);
