import peoplePageActions from './peoplePageActions';
import { USERS_PER_PAGE } from './peoplePageConstants';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';

const catchError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data.message))
      throw new Error(error.response.data.message.join('\n'));
    else throw new Error(error.response.data.message);
  } else {
    throw new Error(error.message);
  }
};

export const fetchUsers =
  (filters = {}, skip = 0, limit, isHomePage) =>
  async (dispatch) => {
    const options = {
      headers: {
        'X-Auth-Token': localStorage.getItem('authorization-token'),
      },
      params: {
        skip,
        limit: limit ? limit : USERS_PER_PAGE,
      },
    };

    if (filters && Object.keys(filters).length) {
      if (filters.sortBy) options.params.sortBy = filters.sortBy;
      if (filters.sortOrder) options.params.sortOrder = filters.sortOrder;
      if (filters.timeOfDay) options.params.timeOfDay = filters.timeOfDay;
      if (filters.weekDays) options.params.weekDays = filters.weekDays;
      if (filters.userTypes) options.params.userTypes = filters.userTypes.join(',');
      if (filters.searchValue) options.params.searchValue = filters.searchValue;
      if (filters.status) options.params.status = filters.status;
      if (filters.companyWide) options.params.companyWide = true;
    }

    if (isHomePage) {
      options.params.isHomePage = isHomePage;
    }

    try {
      const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/users`, options);

      if (skip) dispatch(peoplePageActions.addMorePeoplePageData(response.data));
      else dispatch(peoplePageActions.getUsersPeoplePageData(response.data));
    } catch (error) {
      catchError(error);
    }
  };

export const postUser = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.post(`${process.env.REACT_APP_BASE_URL}/users`, data, options);

    dispatch(peoplePageActions.postUserPeoplePage(response.data));
  } catch (error) {
    catchError(error);
  }
};

export const patchUser = (userId, data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/users/${userId}`,
      data,
      options
    );

    dispatch(peoplePageActions.patchUserPeoplePage(response.data));
  } catch (error) {
    catchError(error);
  }
};

// as we have infinite scroll so after deleting get one
export const deleteUser = (userId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    await $api.delete(`${process.env.REACT_APP_BASE_URL}/users/${userId}`, options);

    dispatch(peoplePageActions.deleteUserPeoplePage(userId));
  } catch (error) {
    catchError(error);
  }
};

export const getSettings = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/organization-locations/settings`, options);

    dispatch(peoplePageActions.getSettingsPeoplePage(response.data));
  } catch (error) {
    catchError(error);
  }
};

export const getCurrentUser = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/personal-profile`, options);

    dispatch(peoplePageActions.getCurrentUserPeoplePage(response.data._id));
  } catch (error) {
    catchError(error);
  }
};

export const patchUserRole = (userId, role) => async (dispatch)  => {
  const options = {
    ...getOptions(),
  };

  try {
    const response = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/users/${userId}/role`,
      { roleName: role },
      options
    );

    dispatch(peoplePageActions.patchUserPeoplePage(response.data));
  } catch (error) {
    catchError(error);
  }
}

export const patchUserPermissions = (userId, permissions) => async (dispatch)  => {
  const options = {
    ...getOptions(),
  };

  try {
    const response = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/users/${userId}/permissions`,
      { ...permissions },
      options
    );

    dispatch(peoplePageActions.patchUserPeoplePage(response.data));
  } catch (error) {
    catchError(error);
  }
}