import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Typography, TextField, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/styles';
import btnStyles from '../../styles';
import { LegendMaterialSchema } from '../../schemas';
import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
import { useDndLabels } from '../../../../../../../../components/DndComponent/hooks/useDndLabels';
import { GridDND, SortableItem, Item } from '../materialDndComponents/materialDndComponents';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';
import { calculateLegend } from 'helpers/material-calculations';

const LegendSpecification = ({
  values,
  remove,
  push,
  replace,
  move,
  type,
  classes,
  setIsCorrectRequest,
  measurement,
}) => {
  const [id, setId] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [symbol, setSymbol] = useState('');
  const [ratio, setRatio] = useState('');
  const [testQuantity, setTestQuantity] = useState('');
  const [legendResult, setLegendResult] = useState('');
  const [area, setSquareFootage] = useState('');
  const [errors, setErrors] = useState({});

  const { items, setItems, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel } =
    useDndLabels({ values, move });

  const clearErrors = useCallback(
    (prop) => {
      if (!prop) {
        setErrors({});
      } else {
        const errorsObj = Object.assign({}, errors);
        delete errorsObj[prop];
        setErrors({});
      }
    },
    [errors]
  );

  const resetForm = useCallback(() => {
    setSymbol('');
    setRatio('');
    setSquareFootage('');
    setTestQuantity('');
    setLegendResult('');
    setId('');
    clearErrors();
  }, []);

  const onAddLegend = useCallback(() => {
    try {
      const result = {
        symbol,
        ratio,
        area,
        testQuantity,
        _id: uuidv4(),
      };

      const validated = LegendMaterialSchema(values).validateSync(result, {
        abortEarly: false,
        stripUnknown: true,
      });

      push(validated);
      setItems((dragItems) => [...dragItems, validated]);
      setIsCorrectRequest(true);
      resetForm();
    } catch (err) {
      const errObject = {};
      (err.inner || []).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  }, [push, symbol, ratio, area, testQuantity, id]);

  const editSave = () => {
    try {
      const result = {
        symbol,
        ratio,
        area,
        testQuantity,
        _id: id,
      };

      const validated = LegendMaterialSchema(values, editIndex).validateSync(result, {
        abortEarly: false,
        stripUnknown: true,
      });

      replace(editIndex, validated);
      setItems((dragItems) => dragItems.map((el) => (el._id === validated._id ? validated : el)));
      setIsCorrectRequest(true);
      resetForm();
      setEditIndex(null);
    } catch (err) {
      const errObject = {};
      (err.inner || []).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  };

  useEffect(() => {
    if (Number(ratio) > 0 && Number(area) > 0 && Number(testQuantity) > 0) {
      setLegendResult(`${calculateLegend(testQuantity, area, ratio)}`);
    } else {
      setLegendResult('');
    }
  }, [symbol, ratio, testQuantity, area]);

  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px' }}>
      <Grid item xs={8} md={9} style={{ marginBottom: '17px', marginTop: '10px' }}>
        <Typography variant="h5">Legend Specifications:</Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} style={{ marginBottom: '10px' }}>
          <label htmlFor="legendSymbols">
            <Typography variant="body1" color="textSecondary">
              Available Symbols
            </Typography>
            <TextField
              id="legendSymbols"
              variant="outlined"
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.symbol ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value);
                clearErrors('symbol');
              }}
            />
          </label>
          {errors.symbol && <span className="materials-input--error-text">{errors.symbol}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
        <Grid item xs={6} lg={3}>
          <label htmlFor="area">
            <Typography variant="body1" color="textSecondary">
              {`Legend Square ${MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.area]}`}
            </Typography>
            <TextField
              id="area"
              variant="outlined"
              type="number"
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.area ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              value={area}
              onChange={(e) => {
                setSquareFootage(e.target.value);
                clearErrors('area');
              }}
            />
          </label>
          {errors.area && (
            <span className="materials-input--error-text">{errors.area}</span>
          )}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="legendRatio">
            <Typography variant="body1" color="textSecondary">
              {`Ratio ${MEASURE_UNITS_BY_SYSTEM[measurement][type === 'volume' ? MEASUREMENT_UNITS.areaPerVolume : MEASUREMENT_UNITS.areaPerWeight]}`}
            </Typography>
            <TextField
              id="legendRatio"
              variant="outlined"
              type="number"
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.ratio ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              value={ratio}
              onChange={(e) => {
                setRatio(e.target.value);
                clearErrors('ratio');
              }}
            />
          </label>
          {errors.ratio && <span className="materials-input--error-text">{errors.ratio}</span>}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="legendTestQuantity">
            <Typography variant="body1" color="textSecondary">
              Test Quantity
            </Typography>
            <TextField
              id="legendTestQuantity"
              className="materials-input"
              variant="outlined"
              type="number"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.testQuantity ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              value={testQuantity}
              onChange={(e) => {
                setTestQuantity(e.target.value);
              }}
            />
          </label>
          {errors.testQuantity && (
            <span className="materials-input--error-text">{errors.testQuantity}</span>
          )}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="legendResult">
            <Typography variant="body1" color="textSecondary">
              {`Result ${MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS[type /** volume | weight */]]}`}
            </Typography>
            <TextField
              id="legendResult"
              variant="outlined"
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              fullWidth={true}
              disabled={true}
              value={legendResult}
            />
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
        {!Number.isInteger(editIndex) && (
          <Grid item xs={'auto'}>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              style={{ padding: '2px 7px 2px 3px', marginTop: '15px' }}
              onClick={onAddLegend}
              className={classes.addButton}
            >
              <AddIcon fontSize="small" color="primary" />
              <span>Add</span>
            </Button>
          </Grid>
        )}
        {!!Number.isInteger(editIndex) && (
          <>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                style={{ padding: '2px', marginTop: '15px' }}
                onClick={(e) => {
                  setEditIndex(null);
                  resetForm();
                }}
                className={classes.cancelEddingButton}
              >
                <span>Cancel</span>
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                style={{ padding: '2px 10px', marginTop: '15px' }}
                onClick={editSave}
                className={classes.addButton}
              >
                <span>Save</span>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={1} style={{ margin: 0 }}>
        <Typography variant="body1" color="textSecondary">
          Click to edit
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: '7px' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragEnd={handleDragCancel}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragEnd}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            <GridDND columns={5} classes={classes}>
              <ul className={classes.itemsList}>
                {items.map((elem, index) => (
                  <li key={index} style={{ margin: '0 8px 8px 0' }}>
                    <SortableItem
                      classes={classes}
                      key={elem._id}
                      id={elem._id}
                      elem={elem}
                      index={index}
                      typeOfLabel={'legend'}
                      type={type}
                      measurement={measurement}
                      editIndex={editIndex}
                      remove={remove}
                      setItems={setItems}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setErrors({});
                        setEditIndex(index);
                        setSymbol(elem.symbol);
                        setRatio(elem.ratio);
                        setSquareFootage(elem.area);
                        setId(elem._id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </GridDND>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Item
                classes={classes}
                id={activeId}
                editIndex={editIndex}
                elem={items.find((el) => el._id === activeId)}
                typeOfLabel={'legend'}
                type={type}
                measurement={measurement}
                isDragging
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Grid>
    </Grid>
  );
};
export default withStyles(btnStyles)(LegendSpecification);
