import React, { useCallback, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog'
import AddIcon from '@material-ui/icons/Add';
import IOSSwitch from 'components/IOSSwitch/IOSSwitch';
import EditIcon from 'assets/images/edit-icon.svg';
import Table from './components/Table/Table';
import { updateMultiLocation } from 'store/settings/settingsOperations';
import styles from './styles';
import LocationForm from './components/LocationForm/LocationForm';

const Locations = ({ classes, pageType, control, errors, openSnackbar }) => {
  const dispatch = useDispatch();
  const { organizationLocation } = useSelector(state => state.personalProfile)
  const { settings, locations } = useSelector((state) => state.settings);

  const currentLocationAddress = useMemo(() => organizationLocation?.address, [organizationLocation]);

  const [drawer, setDrawer] = useState({ open: false })
  const [multiLocationsWarning, setMultiLocationsWarning] = useState(false);

  const changeMultiLocation = useCallback((multiLocations) => {
    if (locations?.filter(location => location.active)?.length > 1) {
      return setMultiLocationsWarning(true);
    }
    dispatch(updateMultiLocation({ multiLocations }))
  }, [settings, locations])

  const tableHeadCells = useMemo(() => {
    return [
      { id: 'name', label: 'Name', align: 'left' },
      { id: 'address', label: 'Address', align: 'left' },
      { id: 'activeUsesr', label: 'Active Users' },
      { id: 'active', label: 'Status' },
      { id: 'action', label: 'Action' },
    ]
  }, []);

  const addLocation = () => {
    setDrawer({ open: true, type: 'create' })
  }

  const editLocation = (location) => {
    setDrawer({ open: true, type: 'update', location })
  }

  const closeDrawer = useCallback(() => {
    if (drawer) setDrawer({ open: false })
  }, [drawer, setDrawer])

  const rows = useMemo(() => {
    return locations.map(location => ({
      key: location._id,
      cells: [
        { align: 'left', children: location.name },
        { align: 'left', children: location.address },
        { align: 'center', children: location.activeUsers || 0 },
        {
          children: (
            <div className={classnames('cell-status', location.active && 'active')}>
              {location.active ? 'Active' : 'Inactive'}
            </div>
          ),
        },
        {
          className: 'action-cell',
          onClick: () => editLocation(location),
          children: (
            <div className="cell-button">
              <img className="cell-button__icon" src={EditIcon} alt="edit" />
              Edit
            </div>
          ),
        },
      ]
    }))
  }, [locations])

  return (
    <div>
      <Grid container className={classes.pb3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h3">Locations</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classnames(classes.alignCenter)}>
          <IOSSwitch
            name={'multiLocations'}
            className={classes.floatRight}
            small
            checked={!!settings?.multiLocations}
            onChange={(e) => changeMultiLocation(e.target.checked)}
          />
          <span
            className={classnames(classes.secondaryText, classes.inlineBlock, classes.floatRight)}
            style={{ marginTop: '3px' }}
          >
            Multi-location
          </span>
        </Grid>
      </Grid>
      <Grid container className={classes.pb4}>
        <Grid item xs={12} md={8} className={classes.alignCenter}>
          <Typography className={classes.sizeUp1} style={{ marginTop: settings?.multiLocations ? 0 : '9px' }}>
            <span className={classes.bold}>Current Location:</span> {currentLocationAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classnames(classes.alignCenter)}>
          {settings?.multiLocations && (
            <Button
              type="button"
              variant="outlined"
              size="medium"
              color="primary"
              style={{ padding: '4px 15px 4px 15px', float: 'right' }}
              onClick={addLocation}
              className={classes.addButton}
            >
              <AddIcon fontSize="small" color="primary" style={{ marginRight: '10px' }} />
              <span>Add</span>
            </Button>
          )}
        </Grid>
      </Grid>
      {settings?.multiLocations && (
        <Grid container className={classes.pb4}>
          <Table
            headCells={tableHeadCells}
            rows={rows}
            editLocation={editLocation}
          />
        </Grid>
      )}

      <Drawer anchor="right" open={!!drawer?.open}>
        {drawer && drawer?.type === 'create' && (
          <LocationForm
            type={drawer.type}
            closeDrawer={closeDrawer}
            allLocations={locations}
            openSnackbar={openSnackbar}
          />
        )}
        {drawer && drawer?.type === 'update' && drawer?.location && (
          <LocationForm
            type={drawer.type}
            closeDrawer={closeDrawer}
            allLocations={locations}
            location={drawer.location}
            openSnackbar={openSnackbar}
          />
        )}
      </Drawer>

      {multiLocationsWarning && (
        <Dialog
          open
          onClose={() => setMultiLocationsWarning(false)}
          PaperProps={{
            classes: {
              root: classes.dialogRoot,
            },
          }}
          backdropclick
          disableEscapeKeyDown
        >
          <div className={classes.formWrapper} role="presentation">
            <div>
              <Typography variant="h4" style={{ marginBottom: '20px' }}>
                Unable to Disable Toggle
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                You cannot turn off this setting because more than one location has been created. Please make the locations inactive before making this change.
              </Typography>
            </div>
            <div className={classes.buttonHolder}>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.cancelButton}
                onClick={() => setMultiLocationsWarning(false)}
                disableRipple
                disableTouchRipple
              >
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

Locations.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(Locations);
