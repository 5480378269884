export default (theme) => ({
  itemWrapper: {
    padding: '6px 15px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#F3F5FD',
    },
    '&:focus': {},
  },
  itemInner: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'stretch',
  },
  previewCellArrow: {
    cursor: 'pointer',
  },
  wlWrapper: {
    height: '200px',
    backgroundColor: 'wheat',
  },

  card: {
    paddingTop: 10,
    border: 'none',
    [theme.breakpoints.only('xl')]: {
      // width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  yellowText: {
    color: theme.palette.textColor.yellow2,
  },
  redText: {
    color: theme.palette.textColor.red,
  },
  paddingBox: {
    padding: '0 10px 8px',
  },
  paddingTop: {
    paddingTop: 8,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
  paddingBottomSmall: {
    paddingBottom: 4,
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  selfCenter: {
    alignSelf: 'center',
  },
  flexHeader: {
    alignItems: 'flex-start',
  },
  fixedWidth: {
    width: 'calc(100% - 30px)',
  },
  buttons: {
    width: 'auto',
    alignItems: 'center',
  },
  locationFlex: {
    display: 'flex',
    alignItems: 'center',
    // paddingRight: 10,
    overflow: 'hidden',
    '& > img': {
      marginRight: 10,
      marginLeft: 15,
      cursor: 'pointer',
    },
  },
  location: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  archiveButton: {
    padding: 0,
    minWidth: 24,
    marginRight: 3,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  displayBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
    minWidth: 32,
  },
  photoWrapperContent: {
    borderRadius: '50%',
    transform: 'rotate(135deg)',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 20,
    height: 20,
    transform: 'rotate(135deg)',
    minWidth: 20,
    boxSizing: 'border-box',
    marginRight: 5,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    maxWidth: 26,
    borderRadius: '50%',
    margin: '2px auto 0',
  },
  photoWorker: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 18,
    maxWidth: 18,
    borderRadius: '50%',
    margin: '0 auto',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 0',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
  },
  forceAccount: {
    display: 'inline',
    color: `${theme.palette.textColor.white}`,
    backgroundColor: theme.palette.primary.blue,
    borderRadius: 9,
    padding: '3px 5px',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    minWidth: 65,
    marginLeft: 10,
  },
  paddingTopSmall: {
    paddingTop: '3px',
  },
  itemsFlexStart: {
    alignItems: 'flex-start',
  },
  widthAuto: {
    width: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  itemWrapperContent: {
    border: '1px solid #fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    maxWidth: '100%',
    height: 20,
  },
  equipmentNumber: {
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,
  },
  closedArrow: {
    transform: 'rotate(90deg)',
  },
  openedArrow: {
    transform: 'rotate(270deg)',
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  closeButton: {
    marginLeft: 15,
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },

  activeButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.primary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  inactiveButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.secondary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  missingCL: {
    borderColor: `${theme.palette.borderColor.red}`,
  },
  canceled: {
    textDecoration: 'line-through',
  },
  accordionItemTextWrapper: {
    marginLeft: 10,
  },
  accordionItemImgWrapper: {
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '17px',
    border: '1px solid',
    borderRadius: '3px',
    flexShrink: '0',
  },
  accordionItemLocationWrapper: {
    width: '3px',
    borderLeft: '3px solid',
    textAlign: 'center',
    flexShrink: '0'
  },
  sheetStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  sheetStatusText: {
    fontSize: '0.875rem',
    marginLeft: 6,
    fontWeight: '500',
    padding: '2px 11px',
    border: '1px solid',
    borderRadius: '11px',
    backgroundColor: 'white',
  },
  submittedImgWrapper: {
    borderColor: '#3E82FF',
    color: '#3E82FF',
  },
  verifiedImgWrapper: {
    borderColor: '#0C842B',
    color: '#0C842B',
},
  publishedImgWrapper: {
    borderColor: '#979797',
    color: '#979797',
  },
  unpublishedImgWrapper: {
    borderColor: '#D14646',
    color: '#D14646',
  },
  projectNameBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});
