import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { canEditWorklog } from 'common/permissions/can-edit-worklog';

/**
 * @param {Worklog|Sheet} sheet 
 * @returns {boolean}
 */
export const useCanEditWorklog = (sheet) => {
  const { user, organizationLocation } = useSelector(state => state.personalProfile || {});
  const { showAllLocations } = useSelector(state => state.schedule);
  const settings = organizationLocation?.settings;

  return useMemo(() => {
    if (!user || !settings || !sheet?._id || showAllLocations) return false;

    return canEditWorklog(sheet, user, settings)
  }, [user, settings, showAllLocations, sheet])
}