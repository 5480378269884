import { PERSONAL_PROFILE_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import personalProfileActions from 'store/personalProfile/personalProfileActions';


const handlers = {
  [PERSONAL_PROFILE_EVENT.location.locationUpdated]: (store) => {
    const locations = store.getState().personalProfile.locations;

    const listeners = locations.map(location => {

      return {
        name: PERSONAL_PROFILE_EVENT.location.locationUpdated.replace('{locationId}', location._id),
        listener: (data) => {
          const organizationLocation = store.getState().personalProfile.organizationLocation;

          store.dispatch(personalProfileActions.updateOrganizationLocation(data))
          if (organizationLocation._id === data._id) {
            console.log(data)
            store.dispatch(personalProfileActions.setOrganizationLocation(data))
          }
        }
      }
    });

    return listeners;
  },
};

export const personalProfileLocationsSubscriptionMiddleware = subscriptionsFabric(
  personalProfileActions,
  PERSONAL_PROFILE_EVENT.location,
  handlers
);
