export const palette = [
    '#f0592a',
    '#f00',
    '#f90',
    '#ff0',
    '#0f0',
    '#0ff',
    '#00f',
    '#90f',
    '#f0f',
    '#c00',
    '#e69138',
    '#f1c232',
    '#6aa84f',
    '#45818e',
    '#3d85c6',
    '#674ea7',
    '#a64d79',
    '#900',
    '#b45f06',
    '#bf9000',
    '#38761d',
    '#134f5c',
    '#0b5394',
    '#351c75',
    '#741b47',
    '#600',
    '#783f04',
    '#7f6000',
    '#274e13',
    '#0c343d',
    '#073763',
    '#20124d',
    '#4c1130',
  ]