export default (theme) => ({
  card: {
    paddingTop: 10,
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  yellowText: {
    color: theme.palette.textColor.yellow2,
  },
  redText: {
    color: theme.palette.textColor.red,
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingTop: {
    paddingTop: 8,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
  paddingBottomSmall: {
    paddingBottom: 4,
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  selfCenter: {
    alignSelf: 'center',
  },
  flexHeader: {
    alignItems: 'flex-start',
  },
  fixedWidth: {
    width: 'calc(100% - 30px)',
  },
  buttons: {
    width: 'auto',
    alignItems: 'center',
  },
  locationFlex: {
    display: 'flex',
    alignItems: 'center',
    // paddingRight: 10,
    overflow: 'hidden',
    '& > img': {
      marginRight: 10,
      marginLeft: 15,
      cursor: 'pointer',
    },
  },
  location: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  archiveButton: {
    padding: 0,
    minWidth: 24,
    marginRight: 3,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  displayBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
    minWidth: 32,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    maxWidth: 26,
    borderRadius: '50%',
    margin: '2px auto 0',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 0 8px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
  },
  forceAccount: {
    display: 'inline',
    color: `${theme.palette.textColor.white}`,
    backgroundColor: theme.palette.primary.blue,
    borderRadius: 9,
    padding: '3px 5px',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    minWidth: 65,
    marginLeft: 10,
  },
  paddingTopSmall: {
    paddingTop: '3px',
  },
  itemsFlexStart: {
    alignItems: 'flex-start',
  },
  widthAuto: {
    width: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  sheetLocation: {
    fontSize: '0.75rem',
    display: 'flex',
    color: '#3E82FF',
    alignItems: 'center',
    marginTop: '-4px',
    marginBottom: '4px',
  },
  locationIcon: {
    width: '12px',
    marginRight: '4px',
    filter: 'invert(40%) sepia(72%) saturate(1866%) hue-rotate(205deg) brightness(101%) contrast(104%)'
  }
});
