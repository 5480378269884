import { createReducer } from '@reduxjs/toolkit';
import personalProfileActions from './personalProfileActions';
import { rolePermissions } from 'common/permissions';

const initialState = {
  personalProfile: {
    loading: false,
    user: null,
    organization: null,
    organizationLocation: null,
    locations: [],
    activeLocations: [],
    fullUserPermissions: null,
  },
};

const personalProfile = createReducer(initialState.personalProfile, {
  [personalProfileActions.setLoading]: (state, { payload }) => ({
    ...state,
    loading: payload,
  }),
  [personalProfileActions.getPersonalProfile]: (state, { payload }) => {
    const userRole = payload.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };
    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
      organization: payload.organization,
      organizationLocation: payload.locations?.find(({ _id }) => payload.tenantLocationId === _id),
      locations: payload.locations,
      activeLocations: payload.locations?.filter(location => location?.active)
    };
  },
  [personalProfileActions.updatePersonalProfile]: (state, { payload }) => {
    const userRole = payload.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };

    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
    };
  },
  [personalProfileActions.partiallyUpdatePersonalProfile]: (state, { payload }) => {
    const userRole = payload.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };

    return {
      ...state,
      user: {
        ...state.user,
        ...payload,
        profile: {
          ...state.user?.profile,
          ...payload?.profile,
        }
      },
      fullUserPermissions: permissionsList,
    }
  },
  [personalProfileActions.clearPersonalProfile]: (state, _) => {
    return {
      user: null,
      fullUserPermissions: null,
    };
  },
  [personalProfileActions.setSpecialProfilePermissions]: (state,  { payload }) => {
    const userRole = payload?.profile?.role;

    let permissionsList = {};
    if(userRole.extPermissions.remoteKiosk) permissionsList.remoteKiosk = true;
    if(userRole.extPermissions.cipKioskMode) permissionsList.cipKioskMode = true;
    if(userRole.extPermissions.kioskMode) permissionsList.kioskMode = true;

    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
    };
  },
  [personalProfileActions.setOrganization]: (state, { payload }) => ({
    ...state,
    organization: payload,
  }),
  [personalProfileActions.setOrganizationLocation]: (state, { payload }) => ({
    ...state,
    organizationLocation: payload,
    locations: state.locations.map(location => location?._id === payload?._id ? payload : location),
    activeLocations: state.locations.map(location => location?._id === payload?._id ? payload : location).filter(location => location.active)
  }),
  [personalProfileActions.updateOrganizationLocation]: (state, { payload }) => ({
    ...state,
    locations: state.locations.map(location => location?._id === payload?._id ? payload : location),
    activeLocations: state.locations.map(location => location?._id === payload?._id ? payload : location).filter(location => location.active)
  }),
  [personalProfileActions.setOrganizationSettings]: (state, { payload }) => ({
    ...state,
    organization: {
      ...(state?.organization || {}),
      settings: payload,
    },
  }),
  [personalProfileActions.setOrganizationLocationSettings]: (state, { payload }) => ({
    ...state,
    organizationLocation: {
      ...(state?.organizationLocation || {}),
      settings: {
        ...(state?.organizationLocation?.settings || {}),
        ...payload
      },
    },
    locations: state.locations
      .map(location =>
        (location?._id === state.organizationLocation?._id)
        ? ({ ...state.organizationLocation, settings: { ...state.organizationLocation.settings, ...payload } })
        : location
      ),
    activeLocations: state.locations
      .map(location =>
        (location?._id === state.organizationLocation?._id)
        ? ({ ...state.organizationLocation, settings: { ...state.organizationLocation.settings, ...payload } })
        : location
      ).filter(location => location.active)
  }),
});

export default personalProfile;
