import React from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';

import styles from './styles';
import ProjectCard from './components/ProjectCard';

const ProjectList = ({
  classes,
  projects,
  openDrawer,
  updateProjectsByPage,
  hasMore,
  hasFullAccess,
  page,
  loading,
  initialLoad,
  locationSettings,
}) => {
  const loadMore = () => {
    if (loading) return
    if (hasMore){
      updateProjectsByPage();}
  };
  return projects?.length ? (
    <InfiniteScroll
      pageStart={page}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <div className={classes.loader} key={0}>
          Loading ...
        </div>
      }
      threshold={400}
    >
      <Grid container spacing={2} justifyContent="flex-start" className={classes.paddingBottom}>
        {projects.map((project) => (
          <Grid key={project._id} item xs={12} lg={3} xl="auto">
            <ProjectCard project={project} openDrawer={openDrawer} hasFullAccess={hasFullAccess} locationSettings={locationSettings}/>
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  ) : (
    <Typography variant="h3" align="center" className={classes.noResultsBlock}>
      {!initialLoad && 'No Results'}
    </Typography>
  );
};

ProjectList.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  projects: propTypes.array.isRequired,
  updateProjectsByPage: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
};

export default withStyles(styles)(ProjectList);
