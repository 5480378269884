import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Provider } from 'react-redux';

import theme from 'theme';
import ScheduleEvent from '../ScheduleEvent';
import ConfirmDialog from 'components/ConfirmDialog';
import styles from './styles';
import store from 'store/store';
import { useGridSetPosition } from './hooks/useGridSetPosition';

const GridTable = ({
  classes,
  additionalRowsCount,
  getEvents,
  allSheetsThisDay,
  sheetsInView,
  sheetsWithoutForemanIds,
  navigate,
  rtsAccess,
  canDragResources,
  showAllLocations,
  locations,
}) => {
  const { gridSetPosition } = useGridSetPosition();
  const [rows, setRowsArray] = useState(new Array(6).fill(true));
  const [events, setEvents] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);
  const [isXXLWidth, setIsXXLWidth] = useState(false);

  useEffect(() => {
    setIsXXLWidth(document.querySelector('body').clientWidth >= 1600);
  }, []);

  useEffect(() => {
    if (additionalRowsCount + 6 !== rows.length) {
      const newRows = new Array(6 + additionalRowsCount).fill(true);
      setRowsArray(newRows);
    }
  }, [additionalRowsCount]);

  useEffect(() => {
    setEvents(getEvents());
  }, [sheetsInView]);

  const closeModal = () => setModalInfo(null);

  const isSheetOnCurrentPosition = (position) =>
    Boolean(
      events.find((el) => {
        const calculatedPosition = el.extendedProps.gridPosition || 0;
        return calculatedPosition === position;
      })
    );

  const renderEvent = (position) => {
    const currentEvent = events.find((el) => {
      const calculatedPosition = el.extendedProps.gridPosition || 0;
      return calculatedPosition === position;
    });
    const { sheet } = currentEvent.extendedProps;

    const locationColor = showAllLocations
      ? locations.find(loc => loc._id === sheet.tenantLocationId)?.color
      : false

    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ScheduleEvent
            sheet={sheet}
            hasAccess={rtsAccess[sheet._id]}
            originalTimeHeight={0}
            sheetsWithoutForemanIds={sheetsWithoutForemanIds}
            allSheetsThisDay={allSheetsThisDay}
            sheetsInView={sheetsInView}
            shouldRenderNextDayBlock={false}
            shouldRenderPrevDayBlock={false}
            isXXLWidth={isXXLWidth}
            isGridView
            navigate={navigate}
            canDragResources={canDragResources}
            locationColor={locationColor}
          />
        </Provider>
      </MuiThemeProvider>
    );
  };

  const handleDropEvent = (position) => (e) => {
    e.currentTarget.style.backgroundColor = '#fff';
    const resourceId = e.dataTransfer.getData('resourceId');
    if (resourceId) return;
    const sheetId = e.dataTransfer.getData('sheetsId');
    const currentEvent = events.find((el) => {
      const calculatedPosition = el.extendedProps.gridPosition;
      return calculatedPosition === position;
    });
    const acceptorId = currentEvent ? currentEvent.extendedProps.sheet._id : null;
    if (!sheetId || (acceptorId && sheetId === acceptorId)) return;
    if (acceptorId) {
      setModalInfo({ sheetId, gridPosition: position, conflictAction: true });
    } else {
      gridSetPosition({
        sheetId,
        gridPosition: position,
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const swapEvents = () => {
    gridSetPosition(modalInfo)
    setModalInfo(null);
  };

  const isAncestor = (node, target) => {
    if (node === target) return false;
    while (node.parentNode) {
      if (node.parentNode === target) return true;
      node = node.parentNode;
    }
    return false;
  };

  const handleDragEnter = (e) => {
    const isResourceId = e.dataTransfer.types.includes('resourceid');
    if (isResourceId) return;
    e.currentTarget.style.backgroundColor = '#8ec6ff';
  };
  const handleDragLeave = (e) => {
    if (!isAncestor(e.relatedTarget, e.currentTarget)) {
      e.currentTarget.style.backgroundColor = '#fff';
    }
  };

  return (
    <div id="gridWrapper" className={classes.root}>
      <table className="grid-table">
        <tbody>
          {rows.map((el, i) => (
            <tr key={Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36)}>
              <td
                className={classnames(classes.cell)}
                onDragOver={handleDragOver}
                onDrop={handleDropEvent(i * 5 + 0)}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {isSheetOnCurrentPosition(i * 5 + 0) && renderEvent(i * 5 + 0)}
              </td>
              <td
                className={classnames(classes.cell)}
                onDragOver={handleDragOver}
                onDrop={handleDropEvent(i * 5 + 1)}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {isSheetOnCurrentPosition(i * 5 + 1) && renderEvent(i * 5 + 1)}
              </td>
              <td
                className={classnames(classes.cell)}
                onDragOver={handleDragOver}
                onDrop={handleDropEvent(i * 5 + 2)}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {isSheetOnCurrentPosition(i * 5 + 2) && renderEvent(i * 5 + 2)}
              </td>
              <td
                className={classnames(classes.cell)}
                onDragOver={handleDragOver}
                onDrop={handleDropEvent(i * 5 + 3)}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {isSheetOnCurrentPosition(i * 5 + 3) && renderEvent(i * 5 + 3)}
              </td>
              <td
                className={classnames(classes.cell)}
                onDragOver={handleDragOver}
                onDrop={handleDropEvent(i * 5 + 4)}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {isSheetOnCurrentPosition(i * 5 + 4) && renderEvent(i * 5 + 4)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!!modalInfo && (
        <ConfirmDialog
          isOpen
          onClose={closeModal}
          onSubmit={swapEvents}
          text="Two worklogs cannot occupy the same cell"
          cancelBtn="Keep original"
          confirmBtn="Swap cells"
          loadingOnSubmit
        />
      )}
    </div>
  );
};

GridTable.propTypes = {
  classes: propTypes.object.isRequired,
  additionalRowsCount: propTypes.number.isRequired,
  sheetsWithoutForemanIds: propTypes.array.isRequired,
  allSheetsThisDay: propTypes.array.isRequired,
  sheetsInView: propTypes.array.isRequired,
  rtsAccess: propTypes.object.isRequired,
  getEvents: propTypes.func.isRequired,
  canDragResources: propTypes.bool,
};

export default withStyles(styles)(GridTable);
