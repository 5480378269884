import { useState } from "react";
import { withStyles } from "@material-ui/core/styles"
import { ReactComponent as BusinessIcon } from 'assets/images/ion_business.svg';
import styles from './styles';

const LocationNavMobile = ({
  classes,
  scheduleShowAllLocations,
  organizationLocation,
  allLocations,
  switchLocation
}) => {
  const [isLocationsOpen, setIsLocationsOpen] = useState(false);


  return (
    <li
      className={classes.linkWrapper}
      onClick={() => setIsLocationsOpen((p) => !p)}
    >
      <div className={'link-icon-wrapper header__nav-mobile'}>
        <a
          role="button"
          className={
            isLocationsOpen
              ? 'nested-nav__link--open nested-nav__link nav__link mobile-location'
              : 'nested-nav__link nav__link mobile-location'
          }
        >
          <BusinessIcon className={'link-icon' + (isLocationsOpen ? ' nested-nav__location-icon-blue' : '')} />
          <span>{scheduleShowAllLocations ? 'All Locations' : organizationLocation.name}</span>
        </a>
      </div>

      <div
        style={{
          display: isLocationsOpen ? 'block' : 'none',
        }}
        className={classes.locationMenu}
      >
        <ul className="resources-list header__dropdown-list">
          {allLocations.map(location => (
            <li className={classes.listItem} key={location._id}>
              <a className={`nav__link ${location._id === organizationLocation._id ? 'nested-nav__link--open' : ''}`} onClick={(() => switchLocation(location._id))}>
                {location.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

export default withStyles(styles)(LocationNavMobile);