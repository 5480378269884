export default (theme) => ({
  root:{
    padding: '10px 20px',
    width: '100%',
  },
  settingsDetailsBlock: {
    paddingBottom: 24,
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  regularFontWeight: {
    fontWeight: 300
  },
  paddingBottom: {
    paddingBottom: 16
  },
  paddingTop: {
    paddingTop: 16,
  },
  zeroPaddingTop: {
    padding: '10px 20px !important',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  primaryTextButton: {
    padding: 0,
    marginBottom: 8,
    marginLeft: 6,
    color: theme.palette.textColor.primary,
    '& span': {
      display: 'block',
      '&:first-letter': {
        fontSize: 20,
      }
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`
    }
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%'
    },
    '& input:disabled': {
      backgroundColor: '#FFFFFF',
      height: '100%',
    }
  },
  fieldValue: {
    marginLeft: '10px',
  },
  tripleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 215,
    '& > div': {
      maxWidth: 65
    }
  },
  removeEmail: {
    display: 'flex',
    cursor: 'pointer',
  },
  actionsWrapper: {
    display: 'block',
    width: '100%',
    color: '#5a87ef',
  },
  actionsIcon: {
    transform: 'rotate(0deg) translateY(2px)',
  },
  nonActionsIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  reversIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  textIconMore: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: 0,
    color: '#5a87ef',
  },
  buttonWrapper: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    marginTop: '15px',
    cursor: 'pointer',
  },
  title: {
    marginBottom: 12,
  },
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    color: theme.palette.textColor.grey,
    '& button': {
      minWidth: 110,
    },
    '& button:hover': {
      backgroundColor: 'transparent !important',
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  addButton: {
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  alignCenter: {
    alignContent: 'center',
  },
  floatRight: {
    float: 'right',
  },
  bold: {
    fontWeight: 'bold',
  },
  sizeUp1: {
    fontSize: '0.9rem'
  },
  pb3: {
    paddingBottom: '22px',
  },
  pb4: {
    paddingBottom: '31px',
  },
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 500
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0
    }
  },
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    }
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 18,
    padding: 4
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
})
