import React from 'react';
import '../../styles.scss';
import InfoItemAvatar from '../InfoItemAvatar/InfoItemAvatar';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';

function AccountingRole({ data, locationSettings }) {
  return (
    <>
      {data && data.totalWorkedHours && (
        <>
          <InfoItemAvatar quantity={isCorrectNumber(data.activeUsers)} />
          {locationSettings?.timeclock && (<h2 className="info-block-title">Total hours worked</h2>)}
          {locationSettings?.timeclock && (
            <div className="cards-wrapper">
              <InfoItem quantity={isCorrectNumber(data.totalWorkedHours.week)} info={'This week'} />
              <InfoItem quantity={isCorrectNumber(data.totalWorkedHours.month)} info={'This month'} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AccountingRole;
