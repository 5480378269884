import { format } from 'date-fns';
import scheduleActions from '../schedulePageActions';
import { SHEET_EVENT } from 'subscriptions/events/events.enum';
import { addNewSheet, updateSheets } from '../schedulePageOperation';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';
import { FORMATS } from 'helpers/formats';
import { getMonthDatesByFullWeeksAsKeys } from 'helpers/_helpers';

export const getStoredWorklogs = (currentURL, store) => {
  const data = {
    storedSheets: [],
    action: undefined,
  };

  if (currentURL.includes('worklog/active')) {
    data.storedSheets = store.getState().worklogs.dataActive;
    data.action = worklogsPageActions.setRefreshActiveSheet;
  } else if (currentURL.includes('worklog/submissions')) {
    data.storedSheets = store.getState().worklogs.dataSubmitted;
    data.action = worklogsPageActions.setRefreshSubmittedSheet;
  } else if (currentURL.includes('worklog/worklog-foreman')) {
    data.storedSheets = store.getState().worklogs.dataForeman;
    data.action = worklogsPageActions.setRefreshCrewSheet;
  }
  return data;
};

export const worklogPageFlowHelper = (store, data, noPublishCheck = true) => {
  const currentURL = window.location.href;
  if (!currentURL.includes('worklog')) return;

  const { storedSheets, action } = getStoredWorklogs(currentURL, store);

  if (!storedSheets.length || !action) return;

  let sheetInWorklogPage = undefined;
  storedSheets.forEach((el) => {
    el[1].forEach((sheet) => {
      if (sheet._id === data._id) {
        sheetInWorklogPage = sheet;
      }
    });
  });

  if (sheetInWorklogPage && (noPublishCheck || data.published)) {
    store.dispatch(action({ ...sheetInWorklogPage, ...data }));
  }
};

const handlers = {
  [SHEET_EVENT.created]: (store) => (data) => {
    // sheet obj
    const { calendarTypeView, currentMonthDate } = store.getState().schedule;
    const { weekStart } = store.getState().personalProfile.organization.settings;
    const dateFormat = store.getState().personalProfile.organization?.settings?.dateFormat;

    if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
      let dateKey = '';

      if (data.travelTimeOnly) {
        dateKey = format(new Date(data.createdAt), dateFormat);
      } else {
        dateKey = format(new Date(data.hours.start), dateFormat);
      }
      const currentDateRangeOnMonthlyCalendar = getMonthDatesByFullWeeksAsKeys(
        currentMonthDate,
        weekStart === 'Sun' ? 0 : 1,
        dateFormat
      ); // make empty cells
      if (currentDateRangeOnMonthlyCalendar[dateKey]) {
        store.dispatch(addNewSheet(data));
        store.dispatch(scheduleActions.addSheetToMonthSchedule({ dateKey, data }));
      }
    } else {
      if (data._id) store.dispatch(updateSheets());

      const currentURL = window.location.href;
      if (currentURL.includes('worklog')) store.dispatch(worklogsPageActions.setForceUpdate(true));
    }
  },
  [SHEET_EVENT.updated]: (store) => (data) => {
    const { sheets, calendarTypeView, currentMonthDate } = store.getState().schedule;
    const { weekStart } = store.getState().personalProfile.organization.settings;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
    const dateFormat = store.getState().personalProfile.organization?.settings?.dateFormat;

    if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
      let dateKey = '';

      if (data.travelTimeOnly) {
        dateKey = format(new Date(data.createdAt), dateFormat);
      } else {
        dateKey = format(new Date(data.hours.start), dateFormat);
      }
      const currentDateRangeOnMonthlyCalendar = getMonthDatesByFullWeeksAsKeys(
        currentMonthDate,
        weekStart === 'Sun' ? 0 : 1,
        dateFormat
      ); // make empty cells
      if (currentDateRangeOnMonthlyCalendar[dateKey]) {
        store.dispatch(scheduleActions.removeSheetFromMonthSchedule(data?._id));
        store.dispatch(addNewSheet(data));
        store.dispatch(scheduleActions.addSheetToMonthSchedule({ dateKey, data }));
      }
    }

    if (data._id && sheetInSchedule) {
      store.dispatch(scheduleActions.updateSheet(data));
    }

    worklogPageFlowHelper(store, data);
  },
  [SHEET_EVENT.resourcesUpdated]: (store) => (data) => {
    const { sheets, calendarTypeView } = store.getState().schedule;
    if (data?.length) {
      if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
        for (const sheetUpdates of data) {
          store.dispatch(scheduleActions.updateMonthSheet(sheetUpdates));
        }
      }

      for (const sheetUpdates of data) {
        const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === sheetUpdates?._id);

        if (sheetInSchedule) {
          store.dispatch(
            scheduleActions.updateSheet({ ...sheetInSchedule.sheet, ...sheetUpdates })
          );
        }
      }
    }

    const currentURL = window.location.href;
    if (currentURL.includes('worklog/worklog-foreman')) {
      store.dispatch(worklogsPageActions.setForceUpdate(true));
    }
  },
  [SHEET_EVENT.updatedBunch]: (store) => (data) => {
    const dateFormat = store.getState().personalProfile.organization?.settings?.dateFormat;
    const formattedDate = format(store.getState().schedule.selectedDate, dateFormat);

    if (data.dates.includes(formattedDate)) store.dispatch(updateSheets());
  },
  [SHEET_EVENT.deleted]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const { calendarTypeView } = store.getState().schedule;

    if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
      store.dispatch(scheduleActions.removeSheetFromMonthSchedule(data?._id));
    }

    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
    if (data._id && sheetInSchedule) store.dispatch(scheduleActions.deleteWorklog(data));

    const currentURL = window.location.href;
    if (currentURL.includes('worklog/active')) {
      store.dispatch(worklogsPageActions.setDeletedActiveSheet(data?._id));
    } else if (currentURL.includes('worklog/worklog-foreman')) {
      store.dispatch(worklogsPageActions.setDeletedCrewSheet(data?._id));
    }
  },
  [SHEET_EVENT.submissionDeleted]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);

    if (data._id && sheetInSchedule) {
      store.dispatch(
        scheduleActions.setSheetSubmitted({ _id: data._id, submittedAt: null, submittedBy: null })
      );
    }
    const currentURL = window.location.href;
    if (currentURL.includes('worklog/submissions')) {
      store.dispatch(worklogsPageActions.setForceUpdate(true));
    }
  },
  [SHEET_EVENT.returnToShopChanged]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
    if (data._id && sheetInSchedule) store.dispatch(scheduleActions.setReturnToShop(data));
  },
  [SHEET_EVENT.notes]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);

    if (sheetInSchedule) {
      store.dispatch(scheduleActions.updateSheet({ ...sheetInSchedule.sheet, ...data }));
    }
  },
  [SHEET_EVENT.updatedCrewLeader]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
    const workerData = {
      _id: data?._id,
      workerId: data?.worker?._id,
      foreman: data?.worker?.foreman,
    };

    store.dispatch(scheduleActions.updateCrewLeaderMonthCalendar(workerData));
    if (data._id && sheetInSchedule) {
      store.dispatch(scheduleActions.updateCrewLeader(workerData));
    }
  },
  [SHEET_EVENT.canceled]: (store) => (data) => {
    const { calendarTypeView } = store.getState().schedule;
    if (data._id && data.canceledAt && data.canceledBy) {
      const sheets = store.getState().schedule.sheets;
      const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
      if (sheetInSchedule) store.dispatch(scheduleActions.setSheetCanceled(data));
      if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
        store.dispatch(scheduleActions.updateMonthSheet(data));
      }
      worklogPageFlowHelper(store, data);
    }
  },
  [SHEET_EVENT.uncanceled]: (store) => (data) => {
    const { calendarTypeView } = store.getState().schedule;
    if (data._id) {
      const sheets = store.getState().schedule.sheets;
      const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
      const uncanceled = {
        _id: data._id,
        canceledAt: null,
        canceledBy: null,
        cancelNote: null,
      };
      if (sheetInSchedule) store.dispatch(scheduleActions.setSheetCanceled(uncanceled));
      if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
        store.dispatch(scheduleActions.updateMonthSheet(uncanceled));
      }

      worklogPageFlowHelper(store, uncanceled);
    }
  },
  [SHEET_EVENT.submitted]: (store) => (data) => {
    const sheets = store.getState().schedule.sheets;
    const sheetInSchedule = sheets.find(({ sheet }) => sheet._id === data?._id);
    if (sheetInSchedule && data._id && data.submittedAt && data.submittedBy) {
      store.dispatch(scheduleActions.setSheetSubmitted(data));
    }

    const currentURL = window.location.href;
    if (currentURL.includes('worklog/submissions'))
      store.dispatch(worklogsPageActions.setForceUpdate(true));
  },
  [SHEET_EVENT.reconciledBunch]: (store) => (data) => {
    if (Array.isArray(data)) {
      store.dispatch(scheduleActions.reconcileWorklogs({ sheetIds: data.map(({ _id }) => _id) }));
    }
  },
  [SHEET_EVENT.unpublished]: (store) => (data) => {
    if (data?.length) {
      for (const sheetObject of data) {
        store.dispatch(scheduleActions.unpublishWorklog(sheetObject));
      }
    }
  },
  [SHEET_EVENT.publishedBunch]: (store) => (data) => {
    if (Array.isArray(data)) {
      const { calendarTypeView } = store.getState().schedule;
      store.dispatch(scheduleActions.publishWorklogs({ sheetIds: data.map(({ _id }) => _id) }));

      const published = {
        ...data[0],
      };
      if (calendarTypeView === CALENDAR_VIEW_TYPES.monthly) {
        store.dispatch(scheduleActions.updateMonthSheet(published));
      }

      const currentURL = window.location.href;
      if (!currentURL.includes('worklog')) return;

      const { storedSheets } = getStoredWorklogs(currentURL, store);
      let sheetInWorklogPage = undefined;
      storedSheets.forEach((el) => {
        el[1].forEach((sheet) => {
          if (sheet._id === data._id) {
            sheetInWorklogPage = sheet;
          }
        });
      });

      if (sheetInWorklogPage) {
        worklogPageFlowHelper(store, {
          ...sheetInWorklogPage,
          published: true,
          unpublishedChanges: false,
        });
      }
    }
  },
  [SHEET_EVENT.gridPositionChanged]: (store) => (data) => {
    if (!data?.length || !Array.isArray(data)) return;

    if (data.length > 1) {
      const swappedGrids = data
        .filter((data) => data._id && data.gridPosition >= 0)
        .map((data) => ({ _id: data._id, position: parseInt(data.gridPosition) }));
      store.dispatch(scheduleActions.swapGridsPosition({ swappedGrids }));
    } else if (data?.[0]?._id && data?.[0]?.gridPosition >= 0) {
      const actionData = { _id: data[0]._id, position: parseInt(data[0].gridPosition) };
      store.dispatch(scheduleActions.setGridPosition(actionData));
    }
  },
};

export const sheetsSubscriptionMiddleware = subscriptionsFabric(
  scheduleActions,
  SHEET_EVENT,
  handlers,
  { assignedToLocations: (store) => store.getState().schedule.showAllLocations }
);
