import React, { useState, useMemo, useEffect } from 'react';
import cs from 'classnames';
import { withStyles } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import styles from './styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DoneIcon from '@material-ui/icons/Done';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import useAutocomplete from 'components/AutocompleteMui/useAutocomplete';



const LocationsSelectInput = ({
  options = [],
  values,
  loading,
  classes,
  inputId,
  onChange: onSelectChange,
  getOptionLabel = (option) => (option),
  getOptionKey = (option) => (option),
  error,
}) => {
  const [pendingValue, setPendingValue] = useState(values);

  useEffect(() => {
    setPendingValue(values);
  }, [values])

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: inputId || '',
    options: options,
    value: pendingValue,
    multiple: true,
    getOptionLabel,
    onChange: (e, v, reason) => {
      setPendingValue(v)
    },
    onClose: () => {
      onSelectChange(pendingValue);
    },
    getOptionSelected: (option, value) => getOptionKey(option) === getOptionKey(value),
    disableCloseOnSelect: true,
  });


  const renderOption = (option, index) => {
    const onSelect = getOptionProps({ option, index })['onClick'];
    const selected = getOptionProps({ option, index })['aria-selected'];
    return (
      <li {...getOptionProps({ option, index })} onTouchEnd={onSelect} className={classes.option}>
        <DoneIcon className={cs(classes.doneIcon, selected ? classes.optionSelected : null)} />
        <span className={classes.optionText}>{getOptionLabel(option)}</span>
      </li>
    )
  }

  const shownOptions = useMemo(() => groupedOptions.sort((a, b) => {
    // Display the selected first.
    let ai = values.indexOf(a);
    ai = ai === -1 ? values.length + options.indexOf(a) : ai;
    let bi = values.indexOf(b);
    bi = bi === -1 ? values.length + options.indexOf(b) : bi;
    return ai - bi;
  }), [values, options, groupedOptions])

  return (
    <>
      <div className={classes.root}>
        {/* <ClickAwayListener onClickAway={handleClose}> */}
          <div className={classes.optionsContainer}>
            <div {...getRootProps()} className={classes.textInputWrapper}>
              <TextField
                {...getInputProps()}
                type="text"
                variant="outlined"
                className={classes.textInputRoot}
                placeholder="Enter location name"
                InputProps={{
                  className: classes.textInput,
                  startAdornment: (
                    <InputAdornment position="start" className={classes.inputAdornment}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <input {...getInputProps()} className={classes.textInput} /> */}
            </div>
            {shownOptions.length > 0 && (
              <div {...getListboxProps()} className={classes.options}>
                {shownOptions.map(renderOption)}
              </div>
            )}
            {error ? (
              <div className={classes.error}>{error}</div>
            ) : null}
          </div>
        {/* </ClickAwayListener> */}
      </div>
    </>
  );
};

export default withStyles(styles)(LocationsSelectInput);
