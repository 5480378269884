import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import useStyles from './useStyles';

export const ManageButtons = memo(
  ({ openDrawer, updatePublishControl, reconcile, showReconcileModal, disabled }) => {
    const classes = useStyles();

    const hasPermission = useSelector(
      (state) => state.permissions.scheduleFullAccess
    );
    const { unpublishedSchedule, unpublishedChanges } = useSelector(
      (state) => state.schedule
    );

    if (!hasPermission) return null;

    return (
      <>
        <Button
          variant="text"
          color="secondary"
          className={classes.addButton}
          onClick={openDrawer}
          disableTouchRipple
          disabled={disabled}
        >
          + Add worklog
        </Button>
        <Button
          id='manageButtons'
          variant="contained"
          color="secondary"
          className={classnames(unpublishedChanges && classes.red)}
          onClick={updatePublishControl(true)}
          disableTouchRipple
          disabled={disabled}
        >
          {unpublishedSchedule ? 'Publish' : 'Update'}
        </Button>
        {reconcile.visible && (
          <Button
            variant="contained"
            color="secondary"
            className={reconcile.disabled ? classes.reconcileBtn : classes.red}
            onClick={showReconcileModal}
            disabled={disabled || reconcile.disabled}
            disableTouchRipple
          >
            Reconcile
          </Button>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.openDrawer === nextProps.openDrawer &&
    prevProps.updatePublishControl === nextProps.updatePublishControl &&
    prevProps.showReconcileModal === nextProps.showReconcileModal &&
    prevProps.reconcile.disabled === nextProps.reconcile.disabled &&
    prevProps.reconcile.visible === nextProps.reconcile.visible
);
