import { createReducer } from '@reduxjs/toolkit';
import equipmentPageActions from './equipmentPageActions';

const initialState = {
  data: [],
  isLoading: false,
  isError: null,
  hasMore: true,
};

const equipments = createReducer(initialState, {
  [equipmentPageActions.getEquipmentsData]: (state, { payload }) => {

    return{
    ...state,
    data: payload.data,
    isLoading: false,
    hasMore: payload.hasMore,
    }
  },
  [equipmentPageActions.getEquipmentsDataAdd]: (state, { payload }) => {

    return {
      ...state,
      data: state.data.concat(payload.data),
      isLoading: false,
      isError: null,
      hasMore: payload.hasMore,
    };
  },
  [equipmentPageActions.deleteEquipment]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(el=>el._id !== payload),
    isError: null,
  }),
  [equipmentPageActions.updateEquipment]: (state, { payload }) => {
    const updatedData = state.data.map(el=>{
      if (el._id === payload._id){
        return {...el, ...payload}
      }else return el
    })
    
    return {
    ...state,
    data: updatedData,
    isError: null,
  }},
  [equipmentPageActions.createNewEquipment]: (state, { payload }) => ({
    ...state,
    data: [ payload, ...state.data],
  }),
  // [contractorsPageActions.contractorsRequest]: (state, { payload }) => ({
  //   ...state,
  //   isLoading: payload,
  //   isError: null,
  // }),
  [equipmentPageActions.equipmentsError]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    isError: payload,
  }),
  [equipmentPageActions.resetHasMore]: (state) => {
    return {
    ...state,
    hasMore: true,
  }},
});

export default equipments;
