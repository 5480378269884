import settingsActions from './settingsAction';
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const initialState = {
  locationSettings: {},
  settings: {},
  locations: [],
};

const settings = createReducer(initialState.settings, {
  [settingsActions.getSettings]: (state, { payload }) => ({ ...state, ...payload }),
  [settingsActions.updateSettings]: (state, { payload }) => ({ ...state, ...payload }),
});
const locationSettings = createReducer(initialState.locationSettings, {
  [settingsActions.getLocationSettings]: (state, { payload }) => ({ ...state, ...payload }),
  [settingsActions.updateLocationSettings]: (state, { payload }) => ({ ...state, ...payload }),
});
const locations = createReducer(initialState.locations, {
  [settingsActions.getLocations]: (state, { payload }) => ([ ...payload ]),
  [settingsActions.postLocation]: (state, { payload }) => ([ ...state, { ...payload, activeUsers: 0 } ]),
  [settingsActions.updateLocation]: (state, { payload }) => (
    state.map(location => (location._id === payload._id ? ({ ...location, ...payload }) : location))
  ),
})

export default combineReducers({
  settings,
  locationSettings,
  locations,
});
