import { PROJECTS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import schedulePageActions from '../schedulePageActions';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import { getStoredWorklogs } from './sheets.subsciption';

const handlers = {
  [PROJECTS_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;
    const sheets = store.getState().schedule.sheets;

    const updatedSheets = sheets.map((el) => {
      if (el.sheet?.projectId === data._id && !el.sheet?.published) {
        return {
          ...el,
          project: { ...el.project, ...data },
          sheet: { ...el.sheet, project: { ...el.sheet.project, ...data } },
        };
      } else return el;
    });
    store.dispatch(schedulePageActions.setSheets(updatedSheets));


    const currentURL = window.location.href;
    if (!currentURL.includes('worklog') || currentURL.includes('worklog/submissions')) return;

    const { storedSheets } = getStoredWorklogs(currentURL, store);

    const updatedActiveSheets = storedSheets.map((el) => {
      return [
        el[0],
        el[1].map((sheet) => {
          if (sheet.projectId === data._id && !sheet.published) {
            return {
              ...sheet,
              project: { ...sheet.project, ...data },
            };
          } else return sheet;
        }),
      ];
    });
    store.dispatch(worklogsPageActions.updateActiveWorklogs(updatedActiveSheets));
  },
};

export const projectsSubscriptionMiddleware = subscriptionsFabric(
  schedulePageActions,
  PROJECTS_EVENT,
  handlers,
  { assignedToLocations: (store) => store.getState().schedule.showAllLocations }
);
