import { createAction } from '@reduxjs/toolkit';

const setImage = createAction('settings/setImage');
const getImage = createAction('settings/getImage');
const deleteImage = createAction('settings/deleteImage');

const getLocationSettings = createAction('settings/getLocationSettings');
const updateLocationSettings = createAction('settings/updateLocationSettings');

const getSettings = createAction('settings/getSettings');
const updateSettings = createAction('settings/updateSettings');

const setTitle = createAction('settings/setTitle');
const getTitle = createAction('settings/getTitle');
const deleteTitle = createAction('settings/deleteTitle');

const setTitleColor = createAction('settings/setTitleColor');
const getTitleColor = createAction('settings/getTitleColor');

const setSettingsToggle = createAction('settings/setSettingsToggle');

const getLocations = createAction('settings/getLocations');
const postLocation = createAction('settings/postLocation');
const updateLocation = createAction('settings/updateLocation');

export default {
  setImage,
  getImage,
  deleteImage,
  setTitle,
  getTitle,
  deleteTitle,
  setTitleColor,
  getTitleColor,
  setSettingsToggle,
  getLocationSettings,
  updateLocationSettings,
  getSettings,
  updateSettings,
  getLocations,
  postLocation,
  updateLocation,
};
