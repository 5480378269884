export default ((theme) => ({
  locationMenu: {
    position: 'absolute',
    top: '42px',
    left: '0',
    width: 'calc(100% - 40px)',
    zIndex: '99',
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px 0px #00000029',
    margin: '5px 20px',
    border: '1px solid #D9DAE3',
    borderRadius: '4px',
  },
  linkWrapper: {
    position: 'relative',
  },
  listItem: {
    padding: '0 !important'
  }
}))