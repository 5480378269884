import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useResources } from './hooks/useResources';
import { useGetEquipmentInfo } from '../../../../hooks/useGetEquipmentInfo';
import {
  getCalendarEvents,
  getResourcesTimeoffsAndHolidays,
} from 'store/schedule/schedulePageOperation';
import styles from './styles';
import { WarningToolTip } from '../WarningToolTip/WarningToolTip';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export const RESOURCE_TYPE = {
  equipment: 'equipment',
  people: 'people',
};

const ResourcesList = ({ classes, searchValue, isAdminMobile }) => {
  const { dateFormatWithTime, dateFormat } = useDateSettingsFormat();
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const date = useSelector((state) => state.schedule.selectedDate);
  const scheduleAvailability = useSelector((state) => state.schedule.availability);
  const showAllLocations = useSelector((state) => state.schedule.showAllLocations);
  const locations = useSelector(state => state.personalProfile.activeLocations);
  const currentUser = useSelector(state => state.personalProfile.user);

  const { userList, equipmentList, canDragResources } = useResources();
  const [visibleUsers, setVisibleUsers] = useState(userList);
  const [visibleEquipment, setVisibleEquipment] = useState(equipmentList);
  const [visibleLocations, setVisibleLocations] = useState([]);

  const handleHolidayCreation = () => dispatch(getCalendarEvents(date));

  useEffect(() => {
    setVisibleLocations(locations.map(({ _id }) => _id))
  }, [locations])

  useEffect(() => {
    dispatch(getResourcesTimeoffsAndHolidays(date, showAllLocations));
  }, [date]);

  useEffect(() => {
    const trimmedSearchValue = searchValue.trim();
    if (!trimmedSearchValue) return setVisibleUsers(userList);

    setVisibleUsers(() =>
      userList.filter((user) =>
        user.username.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      )
    );
  }, [userList, searchValue]);

  useEffect(() => {
    const trimmedSearchValue = searchValue.trim();
    if (!trimmedSearchValue) return setVisibleEquipment(equipmentList);

    setVisibleEquipment(() =>
      equipmentList.filter((item) =>
        item.name.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      )
    );
  }, [equipmentList, searchValue]);

  const dataReady = useMemo(
    () => visibleUsers && visibleEquipment,
    [visibleUsers, visibleEquipment]
  );

  const { getEquipmentInfo } = useGetEquipmentInfo(visibleEquipment);

  const setDragElementData = useCallback(
    (resourceId, resourceType) => (e) => {
      e.dataTransfer.setData('resourceId', resourceId);
      e.dataTransfer.setData('resourceType', resourceType);
    },
    []
  );

  const locationsResources = useMemo(() => 
    locations.filter?.(location => showAllLocations || location._id === currentUser.tenantLocationId).map((location) => ({
      location: location,
      onLocationClick: () => {
        if (visibleLocations?.includes(location._id)) {
          setVisibleLocations(visibleLocations?.filter(locationId => locationId !== location._id))
        } else {
          setVisibleLocations([...visibleLocations, location._id])
        }
      },
      isLocationVisible: visibleLocations?.includes(location._id),
      resources: [
        {
          title: 'People',
          data: visibleUsers?.filter(user => user.assignedToLocations?.includes(location._id)),
          unavailableClass: 'unavailableUser',
          resourceType: 'workers',
          timeoffs: scheduleAvailability.timeoffs,
          holidays: scheduleAvailability.holidays,
          type: RESOURCE_TYPE.people,
          Template: ({ item }) => (
            <div
              className={classnames(
                classes.flexBox,
                item.isUsed && classes.usedResource,
                canDragResources && classes.cursorPointer
              )}
            >
              <Typography
                variant="body1"
                color="textPrimary"
                className={classnames(
                  classes.username,
                  classes[item.profile.shifts.timeOfDay.toLowerCase()]
                )}
              >
                {item.username} ({item.profile.shifts.weekDays})
              </Typography>
            </div>
          ),
        },
        {
          title: 'Equipment',
          data: visibleEquipment?.filter(equip => equip.assignedToLocations?.includes(location._id)),
          unavailableClass: 'unavailableItem',
          resourceType: 'equipments',
          holidays: scheduleAvailability.holidays,
          timeoffs: scheduleAvailability.calendarEvents,
          type: RESOURCE_TYPE.equipment,
          Template: ({ item }) => (
            <div
              className={classnames(
                classes.equipmentBlock,
                getEquipmentInfo(item._id, 'className'),
                item.isUsed && classes.usedResource,
                canDragResources && classes.cursorPointer
              )}
              style={{ backgroundColor: getEquipmentInfo(item._id, 'color') }}
            >
              <div className={classes.equipmentNumber}>{getEquipmentInfo(item._id, 'name')}</div>
            </div>
          ),
        },
      ],
    })),
    [visibleEquipment, visibleUsers, classes, getEquipmentInfo, scheduleAvailability, locations, showAllLocations, currentUser, visibleLocations, setVisibleLocations]
  );

  return (
    <div
      ref={containerRef}
      className={classnames(isAdminMobile ? classes.flexColum : classes.resourcesWrapper)}
    >
      <div className={classes.flexRow}>
        <div className={classes.column}>
          <Typography variant="body1" color="textPrimary" className={classes.marginBottomBig}>
            People
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="body1" color="textPrimary" className={classes.marginBottomBig}>
            Equipment
          </Typography>
        </div>
      </div>
      {locationsResources.map(({ location, resources, onLocationClick, isLocationVisible }) => (
        <div className={classes.flexColum}>
          {showAllLocations && (
            <div className={classnames(classes.flexRow, classes.locationRow)} onClick={onLocationClick}>
              <div className={classes.locationName}>
                <span className={classes.locationColorBox} style={{ backgroundColor: location.color }}>{' '}</span>
                {location.name}
              </div>
              <div className={classes.expandButton} >
                {isLocationVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
          )}
          {isLocationVisible && (
            <div className={classes.flexRow}>
              {resources.map(
                ({ title, resourceType, data, unavailableClass, Template, timeoffs, type, holidays }) => (
      
                  <div className={classes.column} key={title}>
                    {dataReady && (
                      <>
                        {!data.length && (
                          <Typography variant="body1" color="textPrimary">
                            No Results
                          </Typography>
                        )}
                        {!!data.length &&
                          data.map((item) => (
                            <div className={classes.resourceWrapper}>
                              <WarningToolTip
                                classes={classes}
                                resource={item}
                                timeoffs={timeoffs}
                                scheduleDate={date}
                                dateFormatWithTime={dateFormatWithTime}
                                dateFormat={dateFormat}
                                containerRef={containerRef}
                                type={type}
                                holidays={holidays}
                              />
                              <div
                                key={item._id}
                                className={classnames(
                                  classes.marginBottom,
                                  classes.itemWrapper,
                                )}
                                draggable={canDragResources}
                                onDragStart={setDragElementData(item._id, resourceType)}
                              >
                                <Template item={item} />
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

ResourcesList.propTypes = {
  classes: propTypes.object.isRequired,
  searchValue: propTypes.string.isRequired,
};

export default withStyles(styles)(ResourcesList);
