import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import PeopleHeader from './components/PeopleHeader';
import UsersList from './components/UsersList';
import UserForm from './components/UserForm';
import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import FiltersBar from '../../components/FiltersBar';
import ConfirmDialog from '../../components/ConfirmDialog';
import PageLoader from '../../components/PageLoader';
import styles from './styles';
import { drawerTypes } from '../Equipment/components/helpers/drawerTypes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  fetchUsers,
  postUser as postUserRequest,
  patchUser as patchUserRequest,
  deleteUser as deleteUserRequest,
  getSettings,
  getCurrentUser as getCurrentUserRequest,
} from 'store/people/peoplePageOperations';
import { fetchHICodes } from 'store/people/hiCodesOperations';

const PeoplePage = ({ classes, openSnackbar }) => {
  const {
    users: storeUsers,
    settings,
    currentUserId,
    hasMore,
  } = useSelector((store) => store.people);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (e, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
  };

  const visibleUsers = useMemo(
    () =>
      storeUsers.filter((user) =>
        tabValue === 1
          ? user.profile.shifts.timeOfDay === 'Inactive'
          : user.profile.shifts.timeOfDay !== 'Inactive'
      ),
    [storeUsers]
  );
  const [drawerInfo, setDrawerInfo] = useState({ type: drawerTypes.filters, isOpen: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const userInfoRef = useRef(null);
  const filtersRef = useRef({
    sortBy: 'username',
    sortOrder: 'asc',
    timeOfDay: 'All',
    weekDays: 'All',
    userTypes: [
      'Admin',
      'Field Technician',
      'Dispatcher',
      'Accounting',
      'Fleet Maintenance',
      'Project Management',
      'Machine Shop',
    ],
  });
  const searchValueRef = useRef('');
  const userToDeleteRef = useRef('');

  const defaultTimeoff = settings && settings.allowedTimeoff;

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      await Promise.all([
        dispatch(getSettings()).catch((err) => openSnackbar('error', err.message)),
        dispatch(getCurrentUserRequest()).catch((err) => openSnackbar('error', err.message)),
        dispatch(fetchHICodes()).catch((err) => openSnackbar('error', err.message))
      ])

      setIsLoading(false);
      setHasBeenUpdated(true);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(
        fetchUsers({
          ...{ ...filtersRef.current, status: tabValue === 0 ? 'Active' : 'Inactive' },
          searchValue: searchValueRef.current,
          companyWide: true,
        })
      ).catch((err) => openSnackbar('error', err.message));
      setIsLoading(false);
    };

    loadData();
  }, [tabValue]);

  const loadUsers = async (showLoading, page) => {
    if (showLoading) setIsLoading(true);

    const pageNum = page === 0 ? 0 : visibleUsers.length;

    await dispatch(
      fetchUsers(
        {
          ...{ ...filtersRef.current, status: tabValue === 0 ? 'Active' : 'Inactive' },
          searchValue: searchValueRef.current,
          companyWide: true,
        },
        pageNum
      )
    ).catch((err) => openSnackbar('error', err.message));

    if (showLoading) setIsLoading(false);
  };

  const createUser = (data) => dispatch(postUserRequest(data));

  const updateUser = (userId, data) => dispatch(patchUserRequest(userId, data));

  // Drawer block, needed here to close drawer on deleting user

  const openDrawer =
    (type, isOpen, userInfo = null) =>
    (e) => {
      e.preventDefault();

      setDrawerInfo({ type, isOpen });
      userInfoRef.current = userInfo;
    };

  const closeDrawer = useCallback(() => {
    setDrawerInfo({ ...drawerInfo, isOpen: false });
    userInfoRef.current = null;
  }, [drawerInfo]);

  const deleteUser = async () => {
    closeDrawer();
    try {
      await dispatch(deleteUserRequest(userToDeleteRef.current));
      openSnackbar('success', 'Successfully Deleted!');
      userToDeleteRef.current = '';
      setIsModalOpen(false);
    } catch (err) {
      openSnackbar('error', err.message);
    }
  };

  const openDeleteUserModal = (userId) => {
    userToDeleteRef.current = userId;
    setIsModalOpen(true);
  };

  const updateSearchValue = (value) => {
    searchValueRef.current = value;
    loadUsers(true, 0);
  };

  const updateFilters = (value) => {
    filtersRef.current = { ...value };
    loadUsers(true, 0);

    closeDrawer();
  };

  return (
    <div className={classes.root}>
      <PeopleHeader openDrawer={openDrawer} />
      <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label="Active" disableRipple />
        <Tab label="Inactive" disableRipple />
      </Tabs>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} isDynamicSearch={true}/>
      {!hasBeenUpdated ? (
        <PageLoader loading>
          <div style={{ height: 100 }} />
        </PageLoader>
      ) : (
        <PageLoader loading={isLoading}>
          <UsersList
            users={visibleUsers}
            openDrawer={openDrawer}
            currentUserId={currentUserId}
            loadUsers={loadUsers}
            hasMore={hasMore}
            openSnackbar={openSnackbar}
          />
        </PageLoader>
      )}

      <Drawer
        anchor="right"
        open={drawerInfo.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        {drawerInfo.type === drawerTypes.filters ? (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filters={filtersRef.current}
            updateFilter={updateFilters}
            isLoading={isLoading}
          />
        ) : (
          <UserForm
            closeDrawer={closeDrawer}
            actionType={drawerInfo.type}
            userInfo={userInfoRef.current}
            deleteUser={openDeleteUserModal}
            currentUserId={currentUserId}
            openSnackbar={openSnackbar}
            defaultTimeoff={defaultTimeoff}
            createUser={createUser}
            updateUser={updateUser}
          />
        )}
      </Drawer>
      <ConfirmDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={deleteUser}
        text="Are you sure you want to delete this user?"
      />
    </div>
  );
};

export default withStyles(styles)(PeoplePage);
