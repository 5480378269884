import React, { useState, useMemo, useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import userPhoto from 'assets/icons/user-photo-placeholder.svg';
import UserDetails from './components/UserDetails';
import UserPermissions from './components/UserPermissions';
import PageLoader from '../../../../components/PageLoader';
import styles from './styles';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import { emitEvent } from 'helpers/emitEvent.sockets';
import { EVENT_NAMESPACES } from 'helpers/eventNamespaces.sockets';
import { USER_EVENTS } from './userEvents.sockets';
import { rolePermissions } from './components/UserPermissions/helpers/rolePermissions';
import { userTypes } from './components/UserPermissions/helpers/userTypes';
import { generateCreateUserBody, generateUpdateUserBody } from './requests-body';
import { fetchHICodes } from 'store/people/hiCodesOperations';
import { useDispatch, useSelector } from 'react-redux';
import { formatToAmPm, convertTimeObject } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { getLocations } from 'store/settings/settingsOperations';

const validation = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "This field can't be blank";
  } else {
    if (!/^\S*$/.test(values.name)) {
      errors.name = 'No spaces allowed';
    }
  }

  if (!values.fullName.trim()) {
    errors.fullName = "This field can't be blank";
  }

  if (!values.email) {
    errors.email = "This field can't be blank";
  } else {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (values.email.length > 50) {
      errors.email = 'The field cannot contain more than 50 symbols. Please, check';
    }
    if (!/^\S*$/.test(values.email)) {
      errors.email = 'No spaces allowed';
    }
  }

  if (!values.employeeNum) {
    errors.employeeNum = "This field can't be blank";
  } else {
    if (!/^[a-zA-Z0-9]+$/i.test(values.employeeNum)) {
      errors.employeeNum =
        'The value is invalid. The field can contain alphanumeric characters only';
    }
    // const uniqueError = 'UsersService'.checkUniqueEmployeeNum(values.employeeNum, values.userId);
    // if (uniqueError) errors.employeeNum = uniqueError;
  }

  if (!values.HICode.trim()) {
    errors.HICode = "This field can't be blank";
  }

  if (values.phoneNumber.trim()) {
    if (!/^[0-9._() +-]+$/i.test(values.phoneNumber.trim())) {
      errors.phoneNumber = 'The phone number is invalid. Please, check';
    }
  }

  // if (!values?.assignedToLocations?.length) {
  //   errors.assignedToLocations = 'User should be assigned to at least 1 location'
  // }

  if (!values?.homeBaseLocationId) {
    errors.homeBaseLocationId = 'Primary Location should be selected'
  }

  const allowedTimeString = String(values.allowedTime);
  if (allowedTimeString) {
    if (allowedTimeString.length > 5) {
      errors.allowedTime = 'The number is invalid. Cannot contain more than 5 symbols';
    }
    if (!/^[0-9]+$/i.test(allowedTimeString)) {
      errors.allowedTime = 'The number is invalid. The field can contain figures only';
    }
  }

  return errors;
};

const UserForm = ({
  classes,
  closeDrawer,
  actionType,
  userInfo = null,
  deleteUser,
  currentUserId,
  openSnackbar,
  defaultTimeoff,
  createUser,
  updateUser,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch()
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const currentLocation = useSelector((state) => state.personalProfile.user.tenantLocationId);

  useEffect(() => {
    dispatch(fetchHICodes())
    dispatch(getLocations())
  }, [])

  const onUsersUpdated = useCallback(
    (values) => {
      const timeOfDayChanged = userInfo.profile.shifts.timeOfDay !== values.timeOfDay;
      const usernameChanged = userInfo.username !== values.name.substr(0, 120);
      if (timeOfDayChanged)
        emitEvent(EVENT_NAMESPACES.users, USER_EVENTS.updateTimeOfDay, {
          timeOfDay: values.timeOfDay,
          _id: userInfo._id,
          username: userInfo.username,
        });
      if (usernameChanged)
        emitEvent(EVENT_NAMESPACES.users, USER_EVENTS.updateUsername, {
          _id: userInfo._id,
          username: values.name.substr(0, 120),
        });
    },
    [userInfo]
  );

  const adminPermissions = useMemo(() => {
    const result = {};
    rolePermissions[userTypes.admin].forEach((permission) => (result[permission] = false));
    return result;
  }, []);

  const initialValues = useMemo(
    () =>
      userInfo
        ? {
            userId: userInfo._id,
            name: userInfo.username,
            fullName: userInfo.profile.fullName || '',
            email: userInfo.profile.email || '',
            employeeNum: userInfo.profile.employeeNum || '',
            HICode: userInfo.profile.HICode,
            phoneNumber: userInfo.profile.phoneNumber || '',
            timeOfDay: userInfo.profile.shifts.timeOfDay,
            weekDays: userInfo.profile.shifts.weekDays,
            allowedTime: userInfo.profile.timeoff.allowedTime || 0,
            userType: userInfo.profile.role.roleName,
            permissions: userInfo.profile.role.extPermissions,
            emergencyContacts: userInfo.profile.emergencyContacts || [],
            individualHours: userInfo.profile?.individualStartTime?.hour
              ? convertTimeObject(userInfo.profile?.individualStartTime, is12Format).hour
              : '',
            individuaMinutes: userInfo.profile?.individualStartTime?.minute || '',
            individualAmPm: userInfo.profile?.individualStartTime?.hour
              ? convertTimeObject(userInfo.profile?.individualStartTime, is12Format).amPm
              : '',
            assignedToLocations: userInfo.assignedToLocations || [currentLocation],
            homeBaseLocationId: userInfo.homeBaseLocationId || currentLocation,
          }
        : {
            name: '',
            fullName: '',
            email: '',
            employeeNum: '',
            HICode: '',
            phoneNumber: '',
            timeOfDay: 'Day',
            weekDays: 'Mon-Fri',
            allowedTime: defaultTimeoff,
            userType: userTypes.admin,
            permissions: adminPermissions,
            individualHours: '',
            individuaMinutes: '',
            individualAmPm: '',
            assignedToLocations: [currentLocation],
            homeBaseLocationId: 'currentLocation',
          },
    [userInfo, currentLocation]
  );

  const handleClickDelete = useCallback(() => {
    deleteUser(userInfo._id);
  }, [userInfo]);

  const isCreate = useMemo(() => actionType === drawerTypes.create, [actionType]);

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (isCreate) {
              await createUser(generateCreateUserBody(values));
              openSnackbar('success', 'Successfully Added!');
            } else {
              await updateUser(values.userId, generateUpdateUserBody(values));
              onUsersUpdated(values);
              openSnackbar('success', 'Successfully Updated!');
            }

            closeDrawer();
          } catch (err) {
            openSnackbar('error', err.message);
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue, setErrors }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3">{isCreate ? 'Create' : 'Edit'} Person</Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                {!isCreate && (
                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <div className={classes.flexBox}>
                        <div className={classes.userPhotoWrapper}>
                          <img src={userPhoto} alt="user photo" className={classes.userPhoto} />
                        </div>

                        {userInfo && (
                          <div className={classes.fixedWidth}>
                            <Typography variant="h5" className={classes.fixedHeight}>
                              {userInfo.profile.fullName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {userInfo.profile.role.roleName}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        color="secondary"
                        className={classes.deleteButton}
                        onClick={handleClickDelete}
                        disabled={isSubmitting || currentUserId === values.userId}
                        disableTouchRipple
                      >
                        <CloseIcon />
                        Delete User
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Tabs
                  value={tabValue}
                  onChange={(e, value) => setTabValue(value)}
                  className={classes.tabs}
                >
                  <Tab label="User details" disableRipple />
                  <Tab label="User permissions" disableRipple />
                </Tabs>
                {tabValue === 0 && (
                  <UserDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    userInfo={userInfo}
                    openSnackbar={openSnackbar}
                    isCreate={isCreate}
                    setFieldValue={setFieldValue}
                  />
                )}
                {tabValue === 1 && (
                  <UserPermissions
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    currentUserId={currentUserId}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

UserForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  actionType: propTypes.string.isRequired,
  userInfo: propTypes.object,
  deleteUser: propTypes.func.isRequired,
  currentUserId: propTypes.string.isRequired,
  openSnackbar: propTypes.func.isRequired,
  defaultTimeoff: propTypes.number,
};

export default withStyles(styles)(UserForm);
