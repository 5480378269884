const getAllSettings = (state) => ({ ...(state.settings?.locationSettings || {}), ...(state.settings?.settings || {}) });
const getDesktopImage = (state) => state.settings.locationSettings.desktopImage;
const getMobileImage = (state) => state.settings.locationSettings.mobileImage;
const getTitle = (state) =>
  state.settings.locationSettings.homepage && state.settings.locationSettings.homepage.quote;
const getTitleColor = (state) =>
  state.settings.locationSettings.homepage && state.settings.locationSettings.homepage.quoteColor;
const getToggleCountdownEnabled = (state) =>
  state.settings.locationSettings.homepage && state.settings.locationSettings.homepage.countdownEnabled;
const getCountdownTitle = (state) =>
  state.settings.locationSettings.homepage && state.settings.locationSettings.homepage.countdownTitle;
const getCountdownDate = (state) =>
  state.settings.locationSettings.homepage && state.settings.locationSettings.homepage.countdownDate;

export default {
  getAllSettings,
  getDesktopImage,
  getMobileImage,
  getTitle,
  getTitleColor,
  getToggleCountdownEnabled,
  getCountdownTitle,
  getCountdownDate,
};
