import { CONTRACTORS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [CONTRACTORS_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;

    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;

    if (currentWorklog?._id && currentWorklog?.contractorId === data._id && !currentWorklog.published) {
      store.dispatch(currentWorklogActions.patchWorklogSheet({ project: { ...currentWorklog.project, contractor: data } }));
    }
  },
};

export const currentWorklogContractorsSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  CONTRACTORS_EVENT,
  handlers,
);
