import { useState, useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomLink from './components/CustomLink/CustomLink';
import { logout } from 'store/login/loginOperations';
import routePermissions from 'api/routes/routes.permissions';
import { checkUserPermissions } from 'helpers/_helpers';
import './styles.scss';
import HeaderLogo from 'assets/images/header-logo1.svg';
import { ReactComponent as HomeIcon } from 'assets/images/home-i.svg';
import { ReactComponent as KioskIcon } from 'assets/images/r-kiosk-i.svg';
import { ReactComponent as ResourcesIcon } from 'assets/images/resources-i.svg';
import { ReactComponent as ScheduleIcon } from 'assets/images/schedule-i.svg';
import { ReactComponent as GearIcon } from 'assets/images/gear-i.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout-i.svg';
import { ReactComponent as BusinessIcon } from 'assets/images/ion_business.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down-icon.svg';
import { loginToLocation } from 'store/personalProfile/personalProfileOperations';
import schedulePageActions from 'store/schedule/schedulePageActions';
import LocationNavMobile from './components/LocationNavMobile/LocationNavMobile';

const Header = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isCipOpen, setIsCipOpen] = useState(false);
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isLocationsOpen, setIsLocationsOpen] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 999px)');

  function makeInitials(str = '') {
    if (!str) return 'Me';
    const words = str.split(' ');
    const res = words.length >= 2 ? words[0][0] + words[1][0] : words[0] ? words[0][0] : 'Me';
    return res.toUpperCase();
  }

  const {
    user,
    fullUserPermissions: userPermissions,
    organization: company,
    organizationLocation,
    activeLocations: locations,
  } = useSelector((store) => store.personalProfile);

  const locationSettings = organizationLocation?.settings || {};
  const scheduleShowAllLocations = useSelector((store) => store.schedule.showAllLocations);
  const allLocations = useMemo(() => {
    const sortedLocations = structuredClone(locations).sort((a, b) => a?.name.localeCompare(b?.name))
    if (/schedule/.test(window.location.pathname)) {
      return [{ name: 'All Locations', _id: 'all' }, ...sortedLocations]
    } else if (/timeclock/.test(window.location.pathname)) {
      return sortedLocations.filter(location => location.settings?.timeclock)
    }
    return sortedLocations;
  }, [locations, window.location.pathname])

  const dispatch = useDispatch();

  const closeAllDropdowns = useCallback(() => {
    setIsProfileMenuOpen(false);
    setIsResourcesOpen(false);
    setIsCipOpen(false);
    setIsEquipmentOpen(false);
    setIsBurgerOpen(false);
    setIsScheduleOpen(false);
    setIsLocationsOpen(false);
    lockScroll('unlock');
  }, [isMobile]);

  const switchLocation = useCallback((locationId) => {
    if (locationId === 'all') {
      dispatch(schedulePageActions.setShowAllLocations(true));
      // window.location.reload();
      setIsLocationsOpen(false);
    } else {
      if (locationId !== user.tenantLocationId) {
        dispatch(loginToLocation(locationId))
          .then(() => dispatch(schedulePageActions.setShowAllLocations(false)))
          .then(() => {
            setIsLocationsOpen(false);
            window.location.reload();
          });
      } else if (scheduleShowAllLocations && locationId) {
        dispatch(schedulePageActions.setShowAllLocations(false));
        setIsLocationsOpen(false);
      }
    }
  }, [user, scheduleShowAllLocations]);

  useEffect(() => {
    if (!/schedule/.test(window.location.pathname) && scheduleShowAllLocations) {
      dispatch(schedulePageActions.setShowAllLocations(false));
    }
  }, [window.location.pathname, locations])

  const handleCleanup = (e) => {
    if (window.location.href === e?.target?.href) {
      closeAllDropdowns();
    }
  };

  const lockScroll = (mode) => {
    if (mode === 'unlock') {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
      document.body.style.position = 'static';
      window.scrollTo(0, window?.scrollNo);
    }
    if (mode === 'lock') {
      window.scrollNo = window.scrollY;
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
      document.body.style.position = 'fixed';
    }
  };

  const userName = user?.profile?.fullName.split(' ')[0] || 'Me';

  return (
    <section className="header__wrapper">
      <div className="header__block" onClick={handleCleanup}>
        <div className={'header-logo-block-wrapper'}>
          <div className={'header-logo-wrapper'}>
            <Link to="/home">
              <img className={'header-logo'} src={HeaderLogo} alt="logo" />
            </Link>
          </div>

          <span id="header__logo-name">{company?.name}</span>

          {!isMobile && user.profile?.role?.roleName !== 'Field Technician' && (
            <ul
              style={{ display: company?.settings?.multiLocations ? 'block' : 'none' }}
              className={`header__nav`}
            >
              <li
                  className="nav__link-wrapper"
                  onMouseLeave={() => setIsLocationsOpen(false)}
                >
                  <div className={'link-icon-wrapper'}>
                    <a
                      role="button"
                      className={
                        isLocationsOpen
                          ? 'nested-nav__link--open nested-nav__link nav__link'
                          : 'nested-nav__link nav__link'
                      }
                      onMouseOver={() => setIsLocationsOpen(true)}
                    >
                      <BusinessIcon className={'link-icon' + (isLocationsOpen ? ' nested-nav__location-icon-blue' : '')} />
                      <span>{scheduleShowAllLocations ? 'All Locations' : organizationLocation.name}</span>
                    </a>
                  </div>

                  <div
                    style={{
                      display: isLocationsOpen ? 'block' : 'none',
                    }}
                    className="header__dropdown-list-wrapper"
                  >
                    <ul className="resources-list header__dropdown-list wide">
                      {allLocations.map(location => (
                        <li className="nav__link-wrapper" key={location._id}>
                          <a className="nav__link" onClick={(() => switchLocation(location._id))}>
                            {location.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
          )}
        </div>
        <nav className={isBurgerOpen ? 'mob-header-navbar' : ''}>
          <ul className={`header__nav ${isMobile ? 'header__nav-mobile' : ''}`}>
            <div className="mobile-profile-info">
              <div className="mobile-me-block">
                <div className="user-img-fallback">{makeInitials(user?.profile?.fullName)}</div>
                <span className="mobile-me-link">{userName}</span>
              </div>
              <div className="mobile-burger-actions">
                <CustomLink
                  className="user-submenu-link"
                  to="/profile-settings"
                  onClick={closeAllDropdowns}
                >
                  <GearIcon />
                </CustomLink>
                <CustomLink
                  className="logout-button user-submenu-link"
                  to="/signin"
                  onClick={() => dispatch(logout())}
                >
                  <LogoutIcon />
                </CustomLink>
              </div>
            </div>
            {isMobile && user.profile?.role?.roleName !== 'Field Technician' && (
              <LocationNavMobile
                scheduleShowAllLocations={scheduleShowAllLocations}
                organizationLocation={organizationLocation}
                allLocations={allLocations}
                switchLocation={switchLocation}
              />
            )}
            <li className="nav__link-wrapper">
              <div className={'link-icon-wrapper'}>
                <CustomLink onClick={closeAllDropdowns} className="nav__link" to="/home">
                  <HomeIcon className={'link-icon'} />
                  Home
                </CustomLink>
              </div>
            </li>
            {checkUserPermissions(routePermissions['My Schedule'], userPermissions) && (
              <li className="nav__link-wrapper">
                <div className={'link-icon-wrapper'}>
                  <CustomLink className="nav__link" to="/schedule-user">
                    <ScheduleIcon className={'link-icon'} />
                    My Schedule
                  </CustomLink>
                </div>
              </li>
            )}
            {checkUserPermissions(routePermissions['Remote Kiosk'], userPermissions) && (
              <li className="nav__link-wrapper">
                <div className={'link-icon-wrapper'}>
                  <CustomLink className="nav__link" to="/remote-kiosk">
                    <KioskIcon className={'link-icon'} />
                    Remote Kiosk
                  </CustomLink>
                </div>
              </li>
            )}
            {checkUserPermissions(routePermissions['Resources'], userPermissions) && (
              <li
                className="nav__link-wrapper"
                onMouseLeave={isMobile ? () => {} : () => setIsResourcesOpen(false)}
              >
                <div
                  className={'link-icon-wrapper'}
                  onClick={!isMobile ? () => {} : () => setIsResourcesOpen((p) => !p)}
                >
                  <a
                    role="button"
                    className={
                      isResourcesOpen
                        ? 'nested-nav__link--open nested-nav__link nav__link'
                        : 'nested-nav__link nav__link'
                    }
                    onMouseOver={isMobile ? () => {} : () => setIsResourcesOpen(true)}
                  >
                    <ResourcesIcon className={'link-icon'} />
                    <span>Resources</span>
                  </a>
                </div>
                <div
                  style={{
                    display: isResourcesOpen ? 'block' : 'none',
                  }}
                  className="header__dropdown-list-wrapper"
                >
                  <ul className="resources-list header__dropdown-list">
                    {checkUserPermissions(routePermissions['Availability'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/availability">
                          Availability
                        </CustomLink>
                      </li>
                    )}
                    {checkUserPermissions(routePermissions['Projects'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink
                          onClick={closeAllDropdowns}
                          className="nav__link"
                          to="/contractors"
                        >
                          Contractors
                        </CustomLink>
                      </li>
                    )}
                    {checkUserPermissions(
                      [
                        ...routePermissions['Cip Hours'],
                        ...routePermissions['Cip Projects'],
                        ...routePermissions['Equipment'],
                      ],
                      userPermissions
                    ) && (
                      <li
                        className="nav__link-wrapper"
                        onMouseLeave={isMobile ? () => {} : () => setIsEquipmentOpen(false)}
                      >
                        <a
                          role="button"
                          className={'nav__link'}
                          onMouseOver={isMobile ? () => {} : () => setIsEquipmentOpen(true)}
                        >
                          <div
                            className={'aside-arr-wrapper'}
                            onClick={!isMobile ? () => {} : () => setIsEquipmentOpen((p) => !p)}
                          >
                            Equipment
                            <div
                              className={`more-arr-aside ${
                                isEquipmentOpen ? 'more-arr-aside--open' : ''
                              }`}
                            ></div>
                          </div>
                        </a>
                        <div className="header__dropdown-list-wrapper--right">
                          <ul
                            style={{
                              display: isEquipmentOpen ? 'block' : 'none',
                            }}
                            className="resources-list header__dropdown-list header__dropdown-list--right"
                          >
                            {checkUserPermissions(
                              routePermissions['Equipment'],
                              userPermissions
                            ) && (
                              <li className="nav__link-wrapper">
                                <CustomLink className="nav__link" to="/equipment">
                                  Equipment
                                </CustomLink>
                              </li>
                            )}

                            {locationSettings.timeclock && checkUserPermissions(
                              [
                                ...routePermissions['Cip Hours'],
                                ...routePermissions['Cip Projects'],
                              ],
                              userPermissions
                            ) && (
                              <>
                                <li
                                  className="nav__link-wrapper mobile-none"
                                  onMouseLeave={isMobile ? () => {} : () => setIsCipOpen(false)}
                                  onClick={!isMobile ? () => {} : () => setIsCipOpen((p) => !p)}
                                >
                                  <a
                                    role="button"
                                    className={'nav__link'}
                                    onMouseOver={isMobile ? () => {} : () => setIsCipOpen(true)}
                                  >
                                    <div className={'aside-arr-wrapper'}>
                                      CIP
                                      <div className={'more-arr-aside'}></div>
                                    </div>
                                  </a>
                                  <div className="header__dropdown-list-wrapper--right">
                                    <ul
                                      style={{
                                        display: isCipOpen ? 'block' : 'none',
                                      }}
                                      className="resources-list header__dropdown-list header__dropdown-list--right"
                                    >
                                      {checkUserPermissions(
                                        routePermissions['Cip Hours'],
                                        userPermissions
                                      ) && (
                                        <li className="nav__link-wrapper">
                                          <CustomLink className="nav__link" to="/cip-hours">
                                            Hours
                                          </CustomLink>
                                        </li>
                                      )}

                                      {checkUserPermissions(
                                        routePermissions['Cip Projects'],
                                        userPermissions
                                      ) && (
                                        <li className="nav__link-wrapper">
                                          <CustomLink className="nav__link" to="/cip-projects">
                                            Projects
                                          </CustomLink>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </li>
                                <ul className="mobile-cip-block">
                                  <span>CIP:</span>
                                  {checkUserPermissions(
                                    routePermissions['Cip Hours'],
                                    userPermissions
                                  ) && (
                                    <li className="nav__link-wrapper">
                                      <CustomLink className="nav__link" to="/cip-hours">
                                        Hours
                                      </CustomLink>
                                    </li>
                                  )}
                                  {checkUserPermissions(
                                    routePermissions['Cip Projects'],
                                    userPermissions
                                  ) && (
                                    <li className="nav__link-wrapper">
                                      <CustomLink className="nav__link" to="/cip-projects">
                                        Projects
                                      </CustomLink>
                                    </li>
                                  )}
                                </ul>
                              </>
                            )}
                          </ul>
                        </div>
                      </li>
                    )}

                    {checkUserPermissions(routePermissions['People Page'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/users">
                          People
                        </CustomLink>
                      </li>
                    )}

                    {checkUserPermissions(
                      [...routePermissions['Projects'], ...routePermissions['Notes']],
                      userPermissions
                    ) && (
                      <li
                        className="nav__link-wrapper"
                        onMouseLeave={isMobile ? () => {} : () => setIsProjectOpen(false)}
                        onClick={!isMobile ? () => {} : () => setIsProjectOpen((p) => !p)}
                      >
                        <a
                          role="button"
                          className={'nav__link'}
                          onMouseOver={isMobile ? () => {} : () => setIsProjectOpen(true)}
                        >
                          <div className={'aside-arr-wrapper'}>
                            Projects
                            <div
                              className={`more-arr-aside ${
                                isProjectOpen ? 'more-arr-aside--open' : ''
                              }`}
                            ></div>
                          </div>
                        </a>
                        <div className="header__dropdown-list-wrapper--right">
                          <ul
                            style={{
                              display: isProjectOpen ? 'block' : 'none',
                            }}
                            className="resources-list header__dropdown-list header__dropdown-list--right"
                          >
                            {checkUserPermissions(
                              routePermissions['Projects'],
                              userPermissions
                            ) && (
                              <li className="nav__link-wrapper">
                                <CustomLink className="nav__link" to="/projects">
                                  Projects
                                </CustomLink>
                              </li>
                            )}
                            {checkUserPermissions(routePermissions['Notes'], userPermissions) && (
                              <li className="nav__link-wrapper">
                                <CustomLink className="nav__link" to="/notes">
                                  Notes
                                </CustomLink>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                    )}
                    {checkUserPermissions(routePermissions['Settings'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/settings">
                          Settings
                        </CustomLink>
                      </li>
                    )}

                    {checkUserPermissions(routePermissions['Supplies'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/supplies">
                          Supplies
                        </CustomLink>
                      </li>
                    )}

                    {locationSettings?.timeclock && checkUserPermissions(routePermissions['Time Clock'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/timeclock/by-individual-week">
                          Time Clock
                        </CustomLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}

            {checkUserPermissions(
              [...routePermissions['Schedule'], ...routePermissions['Worklog']],
              userPermissions
            ) && (
              <li
                className="nav__link-wrapper"
                onMouseLeave={isMobile ? () => {} : () => setIsScheduleOpen(false)}
                onClick={!isMobile ? () => {} : () => setIsScheduleOpen((p) => !p)}
              >
                <div className={'link-icon-wrapper'}>
                  <a
                    role="button"
                    className={
                      isScheduleOpen
                        ? 'nested-nav__link--open nested-nav__link nav__link'
                        : 'nested-nav__link nav__link'
                    }
                    onMouseOver={isMobile ? () => {} : () => setIsScheduleOpen(true)}
                  >
                    <ScheduleIcon className={'link-icon'} />
                    <span>Dispatch</span>
                  </a>
                </div>

                <div
                  style={{
                    display: isScheduleOpen ? 'block' : 'none',
                  }}
                  className="header__dropdown-list-wrapper"
                >
                  <ul className="resources-list header__dropdown-list">
                    {checkUserPermissions(routePermissions['Schedule'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/schedule">
                          Schedule
                        </CustomLink>
                      </li>
                    )}
                    {checkUserPermissions(routePermissions['Worklog'], userPermissions) && (
                      <li className="nav__link-wrapper">
                        <CustomLink className="nav__link" to="/worklog">
                          Work Log
                        </CustomLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
          </ul>
          {user?.profile?.role?.roleName === 'Super Admin' && (
            <div className="mobile-profile-info">
              <CustomLink className="admin-panel-link" to="/admin-panel">
                Admin Panel <ArrowDown />
              </CustomLink>
            </div>
          )}
        </nav>

        <div
          className={isBurgerOpen ? 'burger-menu-open' : 'burger-menu'}
          onClick={() =>
            setIsBurgerOpen((prevValue) => {
              if (prevValue) {
                lockScroll('unlock');
                setIsResourcesOpen(false);
                setIsCipOpen(false);
                setIsEquipmentOpen(false);
                setIsScheduleOpen(false);
              } else {
                lockScroll('lock');
              }
              return !prevValue;
            })
          }
        >
          <div className="menu__btn">
            <span></span>
          </div>
        </div>
        <div className={'burger-place'}></div>

        <CustomLink to="/home" className="header-mobile-logo">
          <div className={'header-logo-wrapper-mobile'} onClick={closeAllDropdowns}>
            <img className={'header-logo'} src={HeaderLogo} alt="logo" />
          </div>
        </CustomLink>

        <div
          className="header__profile-block"
          onMouseOver={isMobile ? () => {} : () => setIsProfileMenuOpen(true)}
          onMouseLeave={isMobile ? () => {} : () => setIsProfileMenuOpen(false)}
          onClick={!isMobile ? () => {} : () => setIsProfileMenuOpen((p) => !p)}
        >
          <div className="me-block">
            <span className="me-link">{userName}</span>
            <div className={isProfileMenuOpen ? 'more-arr more-arr--open' : 'more-arr'}></div>
            {/* <div className="user-img"></div> */}
            <div className="user-img-fallback">{makeInitials(user?.profile?.fullName)}</div>
          </div>

          {isProfileMenuOpen && (
            <div className="user-submenu-wrapper">
              <ul className="user-submenu">
                {user?.profile?.role?.roleName === 'Super Admin' && (
                  <li className="nav-submenu__link-wrapper">
                    <CustomLink className="user-submenu-link" to="/admin-panel">
                      Admin Panel
                    </CustomLink>
                  </li>
                )}
                <li className="nav-submenu__link-wrapper">
                  <CustomLink className="user-submenu-link" to="/profile-settings">
                    Profile settings
                  </CustomLink>
                </li>
                <li className="nav__link-wrapper">
                  <CustomLink
                    className="logout-button user-submenu-link"
                    to="/signin"
                    onClick={() => dispatch(logout())}
                  >
                    Sign Out
                  </CustomLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;
