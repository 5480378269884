import equipmentPageActions from './equipmentPageActions';
import { getTimeZone } from '../../helpers/getTimeZone';
import { getOptions } from 'helpers/getOptions';

import $api from 'http/index';

const EQUIPMENTS_PER_PAGE = 40;

export const fetchEquipmentsData =
  (filters = {}, page = 0, searchValue = null, forceUpdate = false) =>
  async (dispatch, getState) => {
    const {hasMore} = getState().equipments;

    if (!hasMore && !forceUpdate) return;

    const options = {
      headers: {
        'X-Auth-Token': localStorage.getItem('authorization-token'),
        'X-User-TZ': getTimeZone(),
      },
      params: {
        skip: page * EQUIPMENTS_PER_PAGE,
        limit: EQUIPMENTS_PER_PAGE,
      },
    };
    if (filters && Object.keys(filters).length) {
      if (filters.sortBy) options.params.sortBy = filters.sortBy;
      if (filters.sortOrder) options.params.sortOrder = filters.sortOrder;
      if (filters.companyWide) options.params.companyWide = true;
    }

    if (searchValue) options.params.searchValue = searchValue;

    // dispatch(equipmentPageActions.contractorsRequest(true))

    try {
      const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/equipment`, options);

      if (page === 0) {
        dispatch(
          equipmentPageActions.getEquipmentsData({
            data: response.data,
            hasMore: response.data.length === EQUIPMENTS_PER_PAGE,
          })
        );
      } else {
        dispatch(
          equipmentPageActions.getEquipmentsDataAdd({
            data: response.data,
            hasMore: response.data.length === EQUIPMENTS_PER_PAGE,
          })
        );
      }
    } catch (error) {
      dispatch(equipmentPageActions.equipmentsError());
    }
  };


export const fetchDeleteEquipment = (equipmentId) => async (dispatch) => {

  try {
    await $api.delete(`${process.env.REACT_APP_BASE_URL}/equipment/${equipmentId}
    `, getOptions());
    dispatch(equipmentPageActions.deleteEquipment(equipmentId));
  } catch (error) {
    dispatch(equipmentPageActions.equipmentsError(error));
  }
};

export const postCreateEquipment = (data) => async (dispatch) => {

    const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/equipment`, data, getOptions());
    dispatch(equipmentPageActions.createNewEquipment(res.data));
};

export const putUpdateEquipment = (data, equipmentId) => async (dispatch) => {

    const res = await $api.put(`${process.env.REACT_APP_BASE_URL}/equipment/${equipmentId}`, data, getOptions());
    dispatch(equipmentPageActions.updateEquipment(res.data));
};
