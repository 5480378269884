import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import Tabs from '../../../core/Tabs/Tabs';
import Tab from '../../../core/Tab/Tab';
import { styles } from './styles';
import DayPicker from '../DayPicker';

const useStyles = makeStyles(styles);

const PageTabs = ({
  tabs,
  currentTab,
  setCurrentTab,
  hasEmail,
  handleSendToEmail,
  showDatePicker,
  selectedDate,
  setSelectedDate,
  showSendButton,
  pto,
  locationSettings,
}) => {
  const classes = useStyles();
  const handleTabChange = (e, tab) => setCurrentTab(tab);

  return (
    <div className={classes.tabsWrapper}>
      <Tabs value={currentTab} onChange={handleTabChange} className={classes.tabs} centered={true}>
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            label={tab.title}
            value={i}
            disableRipple
            component="a"
            onClick={(event) => event.preventDefault()}
          />
        ))}
      </Tabs>
      <div className={classes.calendar}>
        {showDatePicker && (
          <DayPicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        )}
        {locationSettings?.timeclock && hasEmail && showSendButton && (
          <Button
            onClick={handleSendToEmail}
            className={classes.email}
            variant="text"
            color="secondary"
            startIcon={<EmailOutlinedIcon />}
          >
            Send to email
          </Button>
        )}
      </div>
      {currentTab !== 0 && pto && (
        <div className={classes.ptoDetails}>
          <div className={classes.ptoDetailsItem}>
            <Typography
              style={{ fontSize: '0.85rem', fontWeight: 400 }}
              variant="h6"
              color="secondary"
            >
              PTO Allowed:
            </Typography>
            <Typography style={{ marginLeft: 7 }} variant="h5" color="textPrimary">
              {pto.allowed}
            </Typography>
          </div>
          <div className={classes.ptoDetailsItem}>
            <Typography
              style={{ fontSize: '0.85rem', fontWeight: 400 }}
              variant="h6"
              color="secondary"
            >
              PTO Designated:
            </Typography>
            <Typography style={{ marginLeft: 7 }} variant="h5" color="textPrimary">
              {pto.designated}
            </Typography>
          </div>
          <div className={classes.ptoDetailsItem}>
            <Typography
              style={{ fontSize: '0.85rem', fontWeight: 400 }}
              variant="h6"
              color="secondary"
            >
              PTO Available:
            </Typography>
            <Typography style={{ marginLeft: 7 }} variant="h5" color="primary">
              {pto.available}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageTabs;
