import { getOptions } from './getOptions';
import store from 'store/store';
import permissionsActions from 'store/permissions/permissionsActions';
import { checkUserPermissions } from 'helpers/_helpers';

export const checkPermissions = (permission = '') => {
  const userId = localStorage.getItem('userId');
  if (!userId) return false;

  const { dispatch, getState } = store;
  const {fullUserPermissions} = getState().personalProfile;


  const permissions = getState().permissions;
  if (permissions.hasOwnProperty(permission)) return permissions[permission];

  dispatch(permissionsActions.setLoading(true));
try {
  const response = checkUserPermissions([permission], fullUserPermissions )

  dispatch(permissionsActions.setPermissions({ [permission]: response }));
  dispatch(permissionsActions.setLoading(false));

  return response;
} catch (error) {
  return false;
}
};
