export default (theme) => ({
  dndWrapper: {
    display: 'flex',
    gap: '10px',
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  dndItem: {
    transformOrigin: '50% 50%',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: isDragging
    //   ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
    //   : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
    // transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    background: 'rgba(90, 135, 239, 0.18)',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px 3px 9px',
    justifyContent: 'space-between',
    color: 'rgb(90, 135, 239)',
    lineHeight: '22px',
  },
  dndItemText: {
    fontSize: '14px',
    marginRight: '5px',
    fontWeight: '500',
  },
})