import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText, Typography } from '@material-ui/core';
import styles from '../ResourcesForm/styles';
import ChangeTimeModal from './ChangeTimeModal';
import DialogContent from '@material-ui/core/DialogContent';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { ReactComponent as BusinessIcon } from 'assets/images/ion_business.svg';
import { OVERLAPPING_RESOURCES_TYPE } from 'pages/Schedule/constants/constants';
import { ChangeEquipTimeModal } from './ChangeTimeModal/ChageEquipTimeModal/ChangeEquipTimeModal';
import { getEquipmentColorClass } from 'helpers/_helpers';
const useStyles = makeStyles(styles);

const OverlapsingModal = ({
  onClose,
  data,
  message,
  onSave,
  handleUpdateConflictWorkers,
  moveFrom,
  type,
}) => {
  const [changeTime, setChangeTime] = useState(null);
  const [overlappingData, setOverlappingData] = useState(data);
  const [overlappingMessage, setOverlappingMessage] = useState(message);

  useEffect(() => {
    setOverlappingData(data);
  }, [data]);

  useEffect(() => {
    setOverlappingMessage(message);
  }, [message]);

  const classes = useStyles();
  const showMessage = overlappingData.length === 1;

  const handleChangeTime = (data) => () => {
    setChangeTime(data);
  };

  const closeChangeTime = () => {
    setChangeTime(null);

    if (onClose) onClose();
  };
  const saveChangeTime = () => {
    const changeTimeUsername = changeTime.worker.username;
    const availableToChangeOverlappingData = overlappingData.filter(
      ({ worker }) => changeTime.worker._id !== worker._id
    );
    setChangeTime(null);
    setOverlappingData(availableToChangeOverlappingData);
    if (handleUpdateConflictWorkers) handleUpdateConflictWorkers(changeTime.workerId);
    const userMessage = overlappingMessage
      .split('\n')
      .filter((message) => !message.includes(changeTimeUsername))
      .join('\n');
    setOverlappingMessage(userMessage);
    if (!availableToChangeOverlappingData.length) {
      onSave ? onSave() : onClose();
    }
  };

  const saveEquipmentChangeTime = () => {
    const changeTimeEquipName = changeTime.equipment.name;
    const availableToChangeOverlappingData = overlappingData.filter(
      ({ equipment }) => changeTime.equipment._id !== equipment._id
    );
    setChangeTime(null);
    setOverlappingData(availableToChangeOverlappingData);
    if (handleUpdateConflictWorkers) handleUpdateConflictWorkers(changeTime.equipmentId); // was workerId
    const userMessage = overlappingMessage
      .split('\n')
      .filter((message) => !message.includes(changeTimeEquipName))
      .join('\n');
    setOverlappingMessage(userMessage);
    if (!availableToChangeOverlappingData.length) {
      onSave ? onSave() : onClose();
    }
  };

  const overlappingLocations = useMemo(() => {
    const overlapseLocationsSet = new Set();
    overlappingData.forEach(({ overlapseSheets }) => (
      overlapseSheets.forEach(({ sheet }) => {
        if (sheet.organizationLocation?.name) overlapseLocationsSet.add(sheet.organizationLocation.name)
      })
    ));

    return Array.from(overlapseLocationsSet);
  }, [showMessage])

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          {showMessage && <Typography variant="h3">{overlappingMessage.split(':')[0]}</Typography>}
          {!showMessage && <Typography variant="h3">Users already have worklogs</Typography>}
        </DialogTitle>
        <DialogContent>
          {showMessage && overlappingLocations?.length && (
            <span className={classes.location}>
              <BusinessIcon />
              {overlappingLocations.map(name => (
                name
              )).join(', ')}
            </span>
          )}
          {showMessage && <DialogContentText>{overlappingMessage}</DialogContentText>}
          {!showMessage &&
            overlappingData.map((overlappingObj, i) => (
              <>
                {overlappingObj?.worker && (
                  <div className={classes.inlineFlex}>
                    <div
                      className={classnames(
                        classes.photoWrapper,
                        classes[
                          `${
                            overlappingObj.worker?.profile?.shifts?.timeOfDay?.toLowerCase() ||
                            overlappingObj.worker?.shifts?.timeOfDay?.toLowerCase()
                          }Border`
                        ]
                      )}
                    >
                      <img src={photoPlaceholder} className={classes.photo} />
                    </div>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classnames(
                        classes.name,
                        classes[
                          overlappingObj.worker?.profile?.shifts?.timeOfDay?.toLowerCase() ||
                            overlappingObj.worker?.shifts?.timeOfDay?.toLowerCase()
                        ]
                      )}
                    >
                      {overlappingObj.worker.username}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classnames(classes.saveButton, classes.changeButton)}
                      disableTouchRipple
                      onClick={handleChangeTime(overlappingData[i])}
                    >
                      Change Time
                    </Button>
                  </div>
                )}
                {overlappingObj?.equipment && (
                  <div className={classes.inlineFlex}>
                    <div
                      className={classnames(
                        classes.equipmentBlock,
                        getEquipmentColorClass(overlappingObj.equipment.color)
                      )}
                      style={{ backgroundColor: overlappingObj.equipment.color }}
                    >
                      <div className={classes.equipmentNumber}>{overlappingObj.equipment.name}</div>
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classnames(classes.saveButton, classes.changeButton)}
                      disableTouchRipple
                      onClick={handleChangeTime(overlappingData[i])}
                    >
                      Change Time
                    </Button>
                  </div>
                )}
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className={classes.cancelButton}
            disableTouchRipple
          >
            Cancel
          </Button>
          {showMessage && (
            <Button
              onClick={handleChangeTime(overlappingData[0])}
              variant="outlined"
              color="primary"
              className={classes.saveButton}
              disableTouchRipple
            >
              Change Time
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {changeTime && (
        <>
          {type === OVERLAPPING_RESOURCES_TYPE.equipment ? (
            <>
              <ChangeEquipTimeModal
                save={saveEquipmentChangeTime}
                data={changeTime}
                onClose={closeChangeTime}
                moveFrom={moveFrom}
                type={type}
              />
            </>
          ) : (
            <ChangeTimeModal
              save={saveChangeTime}
              data={changeTime}
              onClose={closeChangeTime}
              moveFrom={moveFrom}
              type={type}
            />
          )}
        </>
      )}
    </>
  );
};

OverlapsingModal.propTypes = {
  message: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  data: propTypes.array.isRequired,
};

export default OverlapsingModal;
