import { convertTo24HourFormat } from 'common/dateFormatConfig';
export const generateCreateUserBody = (values) => {
  const userBody = {
    username: values.name.slice(0, 120),
    profile: {
      fullName: values.fullName.trim().slice(0, 120),
      employeeNum: values.employeeNum.slice(0, 50),
      HICode: values.HICode,
      phoneNumber: values.phoneNumber.trim().slice(0, 50) || undefined,
      email: values.email,
      shifts: {
        timeOfDay: values.timeOfDay,
        weekDays: values.weekDays,
      },
      timeoff: {
        allowedTime: parseInt(`${values.allowedTime}`.slice(0, 5)),
      },
      role: {
        roleName: values.userType,
        extPermissions: values.permissions,
      },
    },
    assignedToLocations: values.assignedToLocations,
    homeBaseLocationId: values.homeBaseLocationId,
  };

  const assignedToLocations = values?.assignedToLocations?.length ? values.assignedToLocations : [values.homeBaseLocationId];
  if (!assignedToLocations?.includes(values?.homeBaseLocationId)) {
    assignedToLocations.push(values.homeBaseLocationId);
  }
  userBody.assignedToLocations = assignedToLocations;

  if (values.individualHours || values.individuaMinutes || values.individualAmPm) {
    const individualStartTime = {
      hour: convertTo24HourFormat(values.individualHours, values.individualAmPm),
      minute: values.individuaMinutes,
      // amPm: values.individualAmPm,
    };
    userBody.profile.individualStartTime = individualStartTime;
  } else {
    userBody.profile.individualStartTime = null;
  }
  return userBody;
};

export const generateUpdateUserBody = (values) => {
  const userBody = {
    username: values.name.slice(0, 120),
    profile: {
      fullName: values.fullName.trim().slice(0, 120),
      employeeNum: values.employeeNum.slice(0, 50),
      HICode: values.HICode,
      phoneNumber: values.phoneNumber.trim().slice(0, 50) || '',
      email: values.email,
      shifts: {
        timeOfDay: values.timeOfDay,
        weekDays: values.weekDays,
      },
      timeoff: {
        allowedTime: parseInt(`${values.allowedTime}`.slice(0, 5)),
      },
      role: {
        roleName: values.userType,
        extPermissions: values.permissions,
      },
    },
    assignedToLocations: values.assignedToLocations,
    homeBaseLocationId: values.homeBaseLocationId,
  };

  const assignedToLocations = values?.assignedToLocations?.length ? values.assignedToLocations : [values.homeBaseLocationId];
  if (!assignedToLocations?.includes(values?.homeBaseLocationId)) {
    assignedToLocations.push(values.homeBaseLocationId);
  }
  userBody.assignedToLocations = assignedToLocations;

  if (values.individualHours || values.individuaMinutes || values.individualAmPm) {
    const individualStartTime = {
      hour: convertTo24HourFormat(values.individualHours, values.individualAmPm),
      minute: values.individuaMinutes,
      // amPm: values.individualAmPm,
    };
    userBody.profile.individualStartTime = individualStartTime;
  } else {
    userBody.profile.individualStartTime = null;
  }
  return userBody;
};
