import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ShiftsBlock from './components/ShiftsBlock';
import HomePageBlock from './components/HomePageBlock';
import styles from './styles';
import EmailNotifications from './components/EmailNotifications';
import LateClockIn from './components/LateClockIn/index';
import GearIcon from 'assets/images/i-gear.svg';
import HomeIcon from 'assets/images/i2.svg';
import MailIcon from 'assets/images/i3.svg';
import ReportIcon from 'assets/images/i4.svg';
import LocationIcon from 'assets/images/location.svg';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './components/TabPanel/TabPanel';
import cl from 'classnames';
import Locations from './components/Locations/Locations';

const SettingsForm = ({
  classes,
  pageType,
  hours,
  minutes,
  dayPart,
  errors,
  control,
  settings,
  tabValue,
  handleChange,
  getValues,
  watch,
  openSnackbar,
}) => {
  const { timeclock: timeclockValue } = ((getValues && getValues()) || {});

  function addProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className={classes.settingsWrapper}>
        <div className={classes.settingsTabs}>
          <Tabs
            orientation="vertical"
            value={tabValue}
            onChange={handleChange}
            aria-label="Vertical tabs"
            scrollButtons="off"
            className={classes.settingsTabsInner}
          >
            <Tab
              icon={
                <img
                  className={cl('superAdminTabIcon', classes.scaleUpI)}
                  src={GearIcon}
                  alt="shifts"
                />
              }
              label={<span>General</span>}
              {...addProps(0)}
            />
            <Tab
              icon={
                <img
                  className={cl('superAdminTabIcon', classes.scaleDownI)}
                  src={HomeIcon}
                  alt="home page"
                />
              }
              label={<span>Home Page</span>}
              {...addProps(1)}
            />
            <Tab
              icon={<img className={'superAdminTabIcon'} src={MailIcon} alt="email" />}
              label={<span>Email Notification</span>}
              {...addProps(2)}
            />
            {timeclockValue && (
              <Tab
                icon={<img className={'superAdminTabIcon'} src={ReportIcon} alt="reports" />}
                label={<span>Reports</span>}
                {...addProps(3)}
              />
            )}
            <Tab
              icon={<img className={cl('superAdminTabIcon', classes.scaleDownII)} src={LocationIcon} alt="locations" />}
              label={<span>Locations</span>}
              {...addProps(3 + (+timeclockValue))}
            />
          </Tabs>
        </div>

        <TabPanel classes={classes} value={tabValue} index={0}>
          <ShiftsBlock
            pageType={pageType}
            hours={hours}
            minutes={minutes}
            dayPart={dayPart}
            errors={errors}
            control={control}
            settings={settings}
            getValues={getValues}
            watch={watch}
          />
        </TabPanel>
        <TabPanel classes={classes} value={tabValue} index={1}>
          <HomePageBlock pageType={pageType} errors={errors} control={control} />
        </TabPanel>
        <TabPanel classes={classes} value={tabValue} index={2}>
          <EmailNotifications pageType={pageType} errors={errors} control={control} getValues={getValues} />
        </TabPanel>
        {timeclockValue && (
          <TabPanel classes={classes} value={tabValue} index={3}>
            <LateClockIn pageType={pageType} errors={errors} control={control} />
          </TabPanel>
        )}
        <TabPanel classes={classes} value={tabValue} index={3 + (+timeclockValue)}>
          <Locations pageType={pageType} errors={errors} control={control} openSnackbar={openSnackbar} />
        </TabPanel>
      </div>
    </>
  );
};

SettingsForm.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  hours: propTypes.array.isRequired,
  minutes: propTypes.array.isRequired,
  dayPart: propTypes.array.isRequired,
  control: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(SettingsForm);
