import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import PageLoader from '../../../../../PageLoader2';
import styles from './styles';
import { getDateFromHours, shortDate } from 'helpers/_date-helpers';
import AlertModal from '../../../../../AlertModal/AlertModal';
import {
  useSheetFormStyles,
  useResourcesFormStyles,
  useSheetDetailsStyles,
} from '../../../../hooks';
import { isIOS, Option } from '../../../../../Option';
import OverlappingModal from '../../../../../OverlapsingModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateSheetAddResource } from 'store/schedule/schedulePageOperation';
import { useTimeStates } from 'hooks/useTimeStates';
import { DateTime } from 'luxon';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import { isWithinCurrentWeek } from 'helpers/_date-helpers';
import {
  generateHoursObject,
  isSelectedTimeValid,
  isSelectedTravelTimeTimeValid,
} from 'helpers/helpers';
import { patchWorklogJobTime } from 'store/currentWorklog/operations';
import { useSettings } from '../../../../../../pages/Schedule/components/ScheduleCalendar/components/UpdateModal/hooks/useSettings';
import { useWeekStart } from 'hooks/useWeekStart';
import Checkbox from '@material-ui/core/Checkbox';
import { DAY_PART, convertTimeObject, convertTo24HourFormat } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';

const useStyles = makeStyles(styles);

const defaultModalState = { isOpen: false, text: '' };

const getHourValues = (values) => ({
  dayStart: values.dayStart,
  hourStart: values.hourStart,
  minuteStart: values.minuteStart,
  amPmStart: values.amPmStart,
  dayEnd: values.dayEnd,
  hourEnd: values.hourEnd,
  minuteEnd: values.minuteEnd,
  amPmEnd: values.amPmEnd,
  travelTimeToHours: values.travelTimeToHours,
  travelTimeToMinutes: values.travelTimeToMinutes,
  travelTimeFromHours: values.travelTimeFromHours,
  travelTimeFromMinutes: values.travelTimeFromMinutes,
});

export const TimeForm = ({ closeDrawer, openSnackbar }) => {
  const { hours, minutes, dayPart, days } = useTimeStates({
    minuteStep: 5,
    takeSettingsHours: true,
  });
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const { weekDayFromO } = useWeekStart();

  const dispatch = useDispatch();
  const locationSettings = useSelector((state) => state.settings?.locationSettings);
  const user = useSelector((store) => store.personalProfile.user) || {};
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);

  const [infoModal, setInfoModal] = useState(defaultModalState);
  const [shift, setShift] = useState('');
  const [startTime, setStartTime] = useState({
    hour: '',
    minute: '',
    amPm: '',
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const [overlappingModal, setOverlappingModal] = useState(null);

  const [isAccessGrantedEditStartTime, setIsAccessGrantedEditStartTime] = useState(false);

  const sheetFormClasses = useSheetFormStyles();
  const resourcesFormClasses = useResourcesFormStyles();
  const sheetDetailsClasses = useSheetDetailsStyles();
  const classes = useStyles();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    register,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getInitialHoursOnUpdate = (sheet) => {
    const { hours, createdAt } = sheet;
    return generateHoursObject(hours.start, hours.end, createdAt, getTimeFormatHook);
  };

  const isMiscStipendEnabled = watch('miscStipend');

  useEffect(() => {
    register({ name: 'timeOfDay' });
    register({ name: 'startTimeHours' });
    register({ name: 'startTimeMinutes' });
    register({ name: 'startTimeDayPart' });
    register({ name: 'travelTimeOnly' });
  }, [register]);

  useEffect(() => {
    if (!sheet || !Object.keys(sheet).length || isInitialized) return;
    if (sheet.timeOfDay) {
      setShift(sheet.timeOfDay);
      setValue('timeOfDay', sheet.timeOfDay);
    }
    setValue('travelTimeOnly', sheet.travelTimeOnly);

    if (sheet.hours) {
      const hours = getInitialHoursOnUpdate(sheet);
      Object.keys(hours).map((key) => setValue(key, hours[key]));
    }
    if (sheet.startTime) {
      const { hour, minute, amPm } = convertTimeObject(
        { hour: sheet.startTime.hour, minute: sheet.startTime.minute },
        is12Format
      );
      setStartTime({
        hour: String(Number(hour)),
        minute,
        amPm,
      });
      setValue('startTimeHours', hour);
      setValue('startTimeMinutes', minute);
      setValue('startTimeDayPart', amPm);
    }
    if (sheet.hours.travelTimeTo || sheet.hours.travelTimeFrom) {
      setValue('travelTimeToHours', sheet.hours.travelTimeTo?.hours || '0');
      setValue('travelTimeToMinutes', sheet.hours.travelTimeTo?.minutes || '0');
      setValue('travelTimeFromHours', sheet.hours.travelTimeFrom?.hours || '0');
      setValue('travelTimeFromMinutes', sheet.hours.travelTimeFrom?.minutes || '0');
    }
    if (sheet.rainPay) {
      setValue('miscStipend', sheet.isRainPayEnabled || false);
      setValue('rainPayHours', sheet.rainPay?.hours || '0');
      setValue('rainPayMinutes', sheet.rainPay?.minutes || '0');
      setValue('miscStipendDescription', sheet.rainPay?.description || '');
    }
    setIsAccessGrantedEditStartTime(hasStartTimeAccess);
    setIsInitialized(true);
  }, [sheet, isInitialized]);

  const onSubmit = async (values) => {
    if (errors && Object.keys(errors).length) return;
    const hoursValues = getHourValues(values);
    const submitData = {
      startTime: {
        hour: is12Format
          ? convertTo24HourFormat(values.startTimeHours, values.startTimeDayPart)
          : values.startTimeHours,
        minute: values.startTimeMinutes,
      },
      timeOfDay: values.timeOfDay,
      hours: getDateFromHours(hoursValues, sheet.createdAt, getTimeFormatHook),
      travelTimeOnly: values.travelTimeOnly,
      rainPay: {
        hours: values.rainPayHours || '0',
        minutes: values.rainPayMinutes || '0',
        description: values.miscStipendDescription || '',
      },
      isRainPayEnabled: values.miscStipend,
    };

    if (values.travelTimeOnly) {
      delete submitData.hours.start;
      delete submitData.hours.end;
    }

    if (!locationSettings?.miscStipend) {
      delete submitData.isRainPayEnabled;
      delete submitData.rainPay;
    }

    try {
      await dispatch(patchWorklogJobTime(submitData, sheet._id));
      dispatch(updateSheetAddResource(sheet._id, 1));
      closeDrawer();
    } catch (e) {
      if (e.response && e.response.data.type === 'sheet-time-overlapse') {
        setOverlappingModal({
          reason: e.response.data.message,
          data: e.response.data.data,
          type: e.response.data.resourceType,
        });
      }
      if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
        setInfoModal({ isOpen: true, text: e.response.data.message });
      } else {
        openSnackbar && openSnackbar('error', e.response.data.message || e.message);
      }
    }
  };

  const closeModal = () => setInfoModal(defaultModalState);
  const closeOverlappingModal = () => setOverlappingModal(null);

  const dateValid = () => {
    const values = getValues();
    if (values.travelTimeOnly) {
      return true;
    }
    const hours = getInitialHoursOnUpdate(sheet);
    const hoursValues = getHourValues({ ...hours, ...values });
    const { isValid, errorsOnFields } = isSelectedTimeValid(hoursValues, getTimeFormatHook);
    clearErrors();
    errorsOnFields.map((field) => setError(field, 'dateValid'));
    return isValid;
  };

  const travelTimeValid = () => {
    const values = getValues();
    const hoursValues = getHourValues({ ...hours, ...values });
    const { isValid, errorsOnFields } = isSelectedTravelTimeTimeValid(hoursValues, true);
    clearErrors();
    errorsOnFields.map((field) => setError(field, 'dateValid'));

    return isValid;
  };

  const updateOnJobTime = ({ hour, minute, amPm }) => {
    let hourStart = +hour + 2;
    let hourEnd = +hour + 4;

    const getOpposite = (v) => (v === DAY_PART[0] ? DAY_PART[1] : DAY_PART[0]);
    if (is12Format) {
      setValue('amPmStart', hourStart >= 12 ? getOpposite(amPm) : amPm);
      if (hourStart >= 12) {
        hourStart -= 12;
        if (hourStart === 0) hourStart = 12;
      }

      setValue('amPmEnd', hourEnd >= 12 ? getOpposite(amPm) : amPm);
      if (hourEnd >= 12) {
        hourEnd -= 12;
        if (hourEnd === 0) hourEnd = 12;
      }
    } else {
      hourStart = hourStart % 24;
      hourEnd = hourEnd % 24;
    }

    setValue('hourStart', String(hourStart));
    setValue('minuteStart', String(minute).padStart(2, '0'));
    setValue('hourEnd', String(hourEnd));
    setValue('minuteEnd', String(minute).padStart(2, '0'));
  };

  const handleShiftChange = (e) => {
    const { value } = e.target;
    setShift(value);
    setValue('timeOfDay', value);

    const defaultTime = value === 'Day' ? 'defaultDayTime' : 'defaultNightTime';
    if (!locationSettings[defaultTime]) return;
    const { hour, minute, amPm } = convertTimeObject(locationSettings[defaultTime], is12Format);
    setStartTime({ amPm, hour: String(Number(hour)), minute: minute });
    setValue('startTimeHours', String(Number(hour)));
    setValue('startTimeMinutes', minute);
    setValue('startTimeDayPart', amPm);

    updateOnJobTime({ hour: String(Number(hour)), minute, amPm });
  };

  const handleStartTimeChange = (type) => (e) => {
    const { value } = e.target;
    const valuesTypes = {
      hour: 'startTimeHours',
      minute: 'startTimeMinutes',
      amPm: 'startTimeDayPart',
    };
    setValue(valuesTypes[type], value);
    // let startTime = {};
    setStartTime((prev) => {
      const nextValue = { ...prev, [type]: value };
      updateOnJobTime({
        hour: nextValue.hour,
        minute: nextValue.minute,
        amPm: nextValue.amPm,
      });
      // startTime = nextValue;
      return nextValue;
    });
  };

  const hasStartTimeAccess = useCanEditWorklog(sheet);

  return (
    <>
      <div className={classes.formWrapper} role="presentation">
        {!Object.keys(sheet).length ? (
          <PageLoader loading>
            <div />
          </PageLoader>
        ) : (
          <PageLoader loading={false} small>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={resourcesFormClasses.formFlex}>
                <div>
                  <Typography variant="h3" style={{ marginBottom: '26px' }}>
                    Edit On Job Time
                  </Typography>

                  <IconButton
                    className={classnames(classes.closeButton, resourcesFormClasses.closeButton)}
                    onClick={closeDrawer}
                    aria-label="Close"
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>

                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label htmlFor="timeOfDay">
                        <Typography variant="body1" color="textSecondary">
                          Shift:
                        </Typography>
                        <div style={{ marginRight: '10px' }}>
                          <TextField
                            select
                            variant="outlined"
                            inputProps={{ 'aria-label': 'Shift' }}
                            fullWidth
                            onChange={handleShiftChange}
                            value={shift}
                            SelectProps={{
                              native: isIOS,
                            }}
                          >
                            <Option key={0} value="Day">
                              {isIOS ? (
                                '🌤 '
                              ) : (
                                <img
                                  src={IconSunny}
                                  alt="sunny"
                                  className={sheetDetailsClasses.shiftImage}
                                />
                              )}
                              Day
                            </Option>
                            <Option key={1} value="Night">
                              {isIOS ? (
                                '🌙 '
                              ) : (
                                <img
                                  src={IconMoon}
                                  alt="moon"
                                  className={sheetDetailsClasses.shiftImage}
                                />
                              )}
                              Night
                            </Option>
                          </TextField>
                        </div>
                      </label>
                    </Grid>
                    <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label>
                        <Typography variant="body1" color="textSecondary">
                          Start time:
                        </Typography>
                      </label>
                      <div className={sheetDetailsClasses.tripleSelectWrapper}>
                        <TextField
                          select
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Start time hours' }}
                          fullWidth
                          onChange={handleStartTimeChange('hour')}
                          value={startTime.hour}
                          SelectProps={{
                            native: isIOS,
                          }}
                          disabled={!isAccessGrantedEditStartTime}
                        >
                          {hours.map((value, index) => (
                            <Option key={index} value={value}>
                              {value}
                            </Option>
                          ))}
                        </TextField>
                        :
                        <TextField
                          select
                          name="startTimeMinutes"
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Start time minutes' }}
                          fullWidth
                          onChange={handleStartTimeChange('minute')}
                          value={startTime.minute}
                          SelectProps={{
                            native: isIOS,
                          }}
                          disabled={!isAccessGrantedEditStartTime}
                        >
                          {minutes.map((value, index) => (
                            <Option key={index} value={value}>
                              {value}
                            </Option>
                          ))}
                        </TextField>
                        {is12Format && (
                          <TextField
                            select
                            name="startTimeDayPart"
                            variant="outlined"
                            inputProps={{ 'aria-label': 'Start time' }}
                            fullWidth
                            onChange={handleStartTimeChange('amPm')}
                            value={startTime.amPm}
                            SelectProps={{
                              native: isIOS,
                            }}
                            disabled={!isAccessGrantedEditStartTime}
                          >
                            {dayPart.map((value, index) => (
                              <Option key={index} value={value}>
                                {value}
                              </Option>
                            ))}
                          </TextField>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      item
                      xs={12}
                      lg={4}
                      className={sheetDetailsClasses.zeroPaddingTop}
                    >
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <Controller
                          name="travelTimeOnly"
                          control={control}
                          defaultValue={false}
                          type="checkbox"
                          render={(value) => {
                            return (
                              <Checkbox
                                {...value}
                                checked={value?.value}
                                onChange={(e) => {
                                  clearErrors();
                                  value.onChange(e.target.checked);
                                }}
                                // onChange={(e) => setValue('travelTimeOnly', e.target.checked)}

                                color="primary"
                                className={classes.primaryCheckbox}
                              />
                            );
                          }}
                        />
                        <Typography variant="body1" className={classes.primaryAdd}>
                          {isMiscStipendEnabled ? 'Omit Job Time' : 'Travel Time Only'}
                        </Typography>
                      </label>
                    </Grid>
                    {locationSettings?.miscStipend && (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item
                        xs={12}
                        lg={4}
                        className={sheetDetailsClasses.zeroPaddingTop}
                      >
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                          <Controller
                            name="miscStipend"
                            control={control}
                            defaultValue={false}
                            type="checkbox"
                            render={(value) => {
                              return (
                                <Checkbox
                                  {...value}
                                  checked={value?.value}
                                  onChange={(e) => {
                                    clearErrors();
                                    value.onChange(e.target.checked);
                                  }}
                                  // onChange={(e) => setValue('miscStipend', e.target.checked)}

                                  color="primary"
                                  className={classes.primaryCheckbox}
                                />
                              );
                            }}
                          />
                          <Typography variant="body1" className={classes.primaryAdd}>
                            Misc. Stipend
                          </Typography>
                        </label>
                      </Grid>
                    )}

                    <Grid container spacing={4} style={{ position: 'relative', margin: 0 }}>
                      <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                        <label>
                          <Typography variant="body1" color="textSecondary">
                            On Job Time:
                          </Typography>
                        </label>
                        <div className={classes.flex}>
                          <Controller
                            name="dayStart"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: { dateValid },
                            }}
                            as={
                              <TextField
                                select
                                className={classes.onJobTimeField}
                                variant="outlined"
                                error={Boolean(errors.dayStart)}
                                disabled={getValues().travelTimeOnly}
                                SelectProps={{
                                  native: isIOS,
                                }}
                              >
                                {days.map((value, index) => (
                                  <Option key={index} value={value}>
                                    {shortDate(
                                      sheet.createdAt,
                                      value,
                                      getTimeFormatHook().localDayMonth
                                    )}
                                  </Option>
                                ))}
                              </TextField>
                            }
                          />
                          <div className={classes.tripleSelectWrapper}>
                            <Controller
                              name="hourStart"
                              control={control}
                              defaultValue=""
                              rules={{
                                validate: { dateValid },
                              }}
                              as={
                                <TextField
                                  select
                                  className={sheetDetailsClasses.onJobTimeField}
                                  error={Boolean(errors.hourStart)}
                                  disabled={getValues().travelTimeOnly}
                                  variant="outlined"
                                  SelectProps={{
                                    native: isIOS,
                                  }}
                                >
                                  {hours.map((value, index) => (
                                    <Option key={index} value={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </TextField>
                              }
                            />
                            :
                            <Controller
                              name="minuteStart"
                              control={control}
                              defaultValue=""
                              rules={{
                                validate: { dateValid },
                              }}
                              as={
                                <TextField
                                  select
                                  className={sheetDetailsClasses.onJobTimeField}
                                  error={Boolean(errors.minuteStart)}
                                  disabled={getValues().travelTimeOnly}
                                  variant="outlined"
                                  SelectProps={{
                                    native: isIOS,
                                  }}
                                >
                                  {minutes.map((value, index) => (
                                    <Option key={index} value={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </TextField>
                              }
                            />
                            {is12Format && (
                              <Controller
                                name="amPmStart"
                                control={control}
                                defaultValue=""
                                rules={{
                                  validate: { dateValid },
                                }}
                                as={
                                  <TextField
                                    select
                                    className={sheetDetailsClasses.onJobTimeField}
                                    error={Boolean(errors.amPmStart)}
                                    disabled={getValues().travelTimeOnly}
                                    variant="outlined"
                                    SelectProps={{
                                      native: isIOS,
                                    }}
                                  >
                                    {dayPart.map((value, index) => (
                                      <Option key={index} value={value}>
                                        {value}
                                      </Option>
                                    ))}
                                  </TextField>
                                }
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                      <div className={classes.dash}>
                        <Typography variant="body1" color="textSecondary">
                          &mdash;
                        </Typography>
                      </div>
                      <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                        <label>
                          <Typography variant="body1" color="textSecondary">
                            &nbsp;
                          </Typography>
                        </label>
                        <div className={classes.flex}>
                          <Controller
                            name="dayEnd"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: { dateValid },
                            }}
                            as={
                              <TextField
                                select
                                className={classes.onJobTimeField}
                                variant="outlined"
                                error={Boolean(errors.dayEnd)}
                                disabled={getValues().travelTimeOnly}
                                SelectProps={{
                                  native: isIOS,
                                }}
                              >
                                {days.map((value, index) => (
                                  <Option key={index} value={value}>
                                    {shortDate(
                                      sheet.createdAt,
                                      value,
                                      getTimeFormatHook().localDayMonth
                                    )}
                                  </Option>
                                ))}
                              </TextField>
                            }
                          />
                          <div className={classes.tripleSelectWrapper}>
                            <Controller
                              name="hourEnd"
                              control={control}
                              defaultValue=""
                              rules={{
                                validate: { dateValid },
                              }}
                              as={
                                <TextField
                                  select
                                  name="hourEnd"
                                  className={sheetDetailsClasses.onJobTimeField}
                                  error={Boolean(errors.hourEnd)}
                                  disabled={getValues().travelTimeOnly}
                                  variant="outlined"
                                  SelectProps={{
                                    native: isIOS,
                                  }}
                                >
                                  {hours.map((value, index) => (
                                    <Option key={index} value={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </TextField>
                              }
                            />
                            :
                            <Controller
                              name="minuteEnd"
                              control={control}
                              defaultValue=""
                              rules={{
                                validate: { dateValid },
                              }}
                              as={
                                <TextField
                                  select
                                  name="minuteEnd"
                                  className={sheetDetailsClasses.onJobTimeField}
                                  error={Boolean(errors.minuteEnd)}
                                  disabled={getValues().travelTimeOnly}
                                  variant="outlined"
                                  SelectProps={{
                                    native: isIOS,
                                  }}
                                >
                                  {minutes.map((value, index) => (
                                    <Option key={index} value={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </TextField>
                              }
                            />
                            {is12Format && (
                              <Controller
                                name="amPmEnd"
                                control={control}
                                defaultValue=""
                                rules={{
                                  validate: { dateValid },
                                }}
                                as={
                                  <TextField
                                    select
                                    name="amPmEnd"
                                    className={sheetDetailsClasses.onJobTimeField}
                                    error={Boolean(errors.amPmEnd)}
                                    disabled={getValues().travelTimeOnly}
                                    variant="outlined"
                                    SelectProps={{
                                      native: isIOS,
                                    }}
                                  >
                                    {dayPart.map((value, index) => (
                                      <Option key={index} value={value}>
                                        {value}
                                      </Option>
                                    ))}
                                  </TextField>
                                }
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container lg={12} spacing={4} style={{ position: 'relative', margin: 0 }}>
                      <Grid item xs={12} lg={3} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Typography variant="body1" color="textSecondary">
                          Travel Time To Job:
                        </Typography>
                        <div
                          className={classnames(
                            classes.doubleSelectWrapper,
                            sheetDetailsClasses.alignTop
                          )}
                        >
                          <div>
                            <Controller
                              name="travelTimeToHours"
                              control={control}
                              rules={{
                                validate: { travelTimeValid },
                              }}
                              defaultValue=""
                              as={
                                <TextField
                                  className={sheetDetailsClasses.textField}
                                  error={Boolean(errors.travelTimeToHours)}
                                  variant="outlined"
                                  inputProps={{
                                    'aria-label': 'Travel time hours',
                                    maxLength: 2,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className={sheetDetailsClasses.textField}
                                      >
                                        <label
                                          htmlFor="travelTimeToHours"
                                          className={classes.adornment}
                                        >
                                          h
                                        </label>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              }
                            />
                            {errors.travelTimeToHours ? (
                              <div className={classes.error}>
                                {errors.travelTimeToHours?.message}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <Controller
                              name="travelTimeToMinutes"
                              control={control}
                              rules={{
                                validate: { travelTimeValid },
                              }}
                              defaultValue=""
                              as={
                                <TextField
                                  className={sheetDetailsClasses.textField}
                                  error={Boolean(errors.travelTimeToMinutes)}
                                  variant="outlined"
                                  inputProps={{
                                    'aria-label': 'Travel time minutes',
                                    maxLength: 2,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <label
                                          htmlFor="travelTimeToMinutes"
                                          className={classes.adornment}
                                        >
                                          min
                                        </label>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              }
                            />
                            {errors.travelTimeToMinutes ? (
                              <div className={classes.error}>
                                {errors.travelTimeToMinutes?.message}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} lg={3} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Typography variant="body1" color="textSecondary">
                          Travel Time From Job:
                        </Typography>
                        <div
                          className={classnames(
                            classes.doubleSelectWrapper,
                            sheetDetailsClasses.alignTop
                          )}
                        >
                          <div>
                            <Controller
                              name="travelTimeFromHours"
                              control={control}
                              rules={{
                                validate: { travelTimeValid },
                              }}
                              defaultValue=""
                              as={
                                <TextField
                                  className={sheetDetailsClasses.textField}
                                  error={Boolean(errors.travelTimeFromHours)}
                                  variant="outlined"
                                  inputProps={{
                                    'aria-label': 'Travel time hours',
                                    maxLength: 2,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <label
                                          htmlFor="travelTimeFromHours"
                                          className={classes.adornment}
                                        >
                                          h
                                        </label>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              }
                            />
                            {errors.travelTimeFromHours ? (
                              <div className={classes.error}>
                                {errors.travelTimeFromHours?.message}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <Controller
                              name="travelTimeFromMinutes"
                              control={control}
                              rules={{
                                validate: { travelTimeValid },
                              }}
                              defaultValue=""
                              as={
                                <TextField
                                  className={sheetDetailsClasses.textField}
                                  error={Boolean(errors.travelTimeFromMinutes)}
                                  variant="outlined"
                                  inputProps={{
                                    'aria-label': 'Travel time minutes',
                                    maxLength: 2,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <label
                                          htmlFor="travelTimeFromMinutes"
                                          className={classes.adornment}
                                        >
                                          min
                                        </label>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              }
                            />
                            {errors.travelTimeFromMinutes ? (
                              <div className={classes.error}>
                                {errors.travelTimeFromMinutes?.message}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {getValues().miscStipend && locationSettings?.miscStipend && (
                      <Grid
                        container
                        lg={12}
                        spacing={4}
                        style={{ position: 'relative', margin: 0 }}
                      >
                        <Grid item xs={12} lg={3} className={sheetDetailsClasses.zeroPaddingTop}>
                          <Typography variant="body1" color="textSecondary">
                            Misc. Stipend:
                          </Typography>
                          <div
                            className={classnames(
                              classes.doubleSelectWrapper,
                              sheetDetailsClasses.alignTop
                            )}
                          >
                            <div>
                              <Controller
                                name="rainPayHours"
                                control={control}
                                rules={{
                                  validate: {
                                    isNumeric: (value) => value === '' || /^[0-9]+$/.test(value),
                                    lessThan24: (value) => value === '' || parseInt(value) <= 24,
                                  },
                                }}
                                defaultValue={sheet.rainPay?.hours || '0'}
                                as={
                                  <TextField
                                    className={sheetDetailsClasses.textField}
                                    error={Boolean(errors.rainPayHours)}
                                    variant="outlined"
                                    inputProps={{
                                      'aria-label': 'Travel time hours',
                                      maxLength: 2,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <label
                                            htmlFor="rainPayHours"
                                            className={classes.adornment}
                                          >
                                            h
                                          </label>
                                        </InputAdornment>
                                      ),
                                    }}
                                    fullWidth
                                  />
                                }
                              />
                              {errors.rainPayHours ? (
                                <div className={classes.error}>{errors.rainPayHours?.message}</div>
                              ) : null}
                            </div>
                            <div>
                              <Controller
                                name="rainPayMinutes"
                                control={control}
                                rules={{
                                  validate: {
                                    isNumeric: (value) => value === '' || /^[0-9]+$/.test(value),
                                    lessThan60: (value) => value === '' || parseInt(value) <= 59,
                                  },
                                }}
                                defaultValue={sheet.rainPay?.minutes || '0'}
                                as={
                                  <TextField
                                    className={sheetDetailsClasses.textField}
                                    error={Boolean(errors.rainPayMinutes)}
                                    variant="outlined"
                                    inputProps={{
                                      'aria-label': 'Travel time minutes',
                                      maxLength: 2,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <label
                                            htmlFor="rainPayMinutes"
                                            className={classes.adornment}
                                          >
                                            min
                                          </label>
                                        </InputAdornment>
                                      ),
                                    }}
                                    fullWidth
                                  />
                                }
                              />
                              {errors.rainPayMinutes ? (
                                <div className={classes.error}>
                                  {errors.rainPayMinutes?.message}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                          <Typography variant="body1" color="textSecondary">
                            Misc. Stipend Description:
                          </Typography>
                          <div className={classnames(sheetDetailsClasses.alignTop)}>
                            <div>
                              <Controller
                                name="miscStipendDescription"
                                control={control}
                                defaultValue={sheet.rainPay?.description || ''}
                                as={
                                  <TextField
                                    className={sheetDetailsClasses.textField}
                                    error={Boolean(errors.miscStipendDescription)}
                                    variant="outlined"
                                    inputProps={{
                                      'aria-label': 'Travel time hours',
                                    }}
                                    fullWidth
                                  />
                                }
                              />
                              {errors.miscStipendDescription ? (
                                <div className={classes.error}>
                                  {errors.miscStipendDescription?.message}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>

                <div className={sheetFormClasses.buttonHolder}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={sheetFormClasses.cancelButton}
                    onClick={closeDrawer}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={sheetFormClasses.saveButton}
                    disableTouchRipple
                    disabled={errors && !!Object.keys(errors).length}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </PageLoader>
        )}
      </div>
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          type={overlappingModal?.type}
        />
      )}
    </>
  );
};

TimeForm.propTypes = {
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
};

export default TimeForm;
