import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Field } from 'formik';
import DndComponent from 'components/DndComponent/DndComponent';
import SearchLocationsInput from './components/LocationsSelectInput';
import styles from './styles';
import { isIOS, Option } from 'components/Option/Option';


const LocationAccessPermission = ({
  homeBaseLocationId,
  allLocations,
  values = [],
  remove,
  push,
  move,
  replace,
  classes,
  setFieldValue,
  inputId,
  error,
  onChange: onValuesChange,
  useSettingsLocations,
}) => {
  const personalProfileLocations = useSelector((state) => state.personalProfile.activeLocations);
  const settingsLocations = useSelector((state) => state.settings.locations)
  const locations = useMemo(() => (
    allLocations || (useSettingsLocations ? settingsLocations : personalProfileLocations)
  ), [allLocations, settingsLocations, personalProfileLocations, useSettingsLocations]);
  const activeLocations = useMemo(() => locations.filter(location => location.active), [locations]);

  const alternateLocationsOptions = useMemo(
    () => activeLocations.filter(location => location._id !== homeBaseLocationId),
    [locations, homeBaseLocationId]
  )

  const locationValues = useMemo(() => (
    values.map((locationId) => locations.find(({ _id }) => _id === locationId))
  ), [values]);

  const onChange = useCallback((locations = []) => {
    const ids = locations.map(({ _id }) => _id);
    setFieldValue('assignedToLocations', ids);
    if (onValuesChange) onValuesChange();
  }, [values]);

  const onHomeLocationChange = useCallback((e) => {
    const locationId = e.target.value;

    const assignedToLocations = values.slice().filter(location => location !== homeBaseLocationId);

    if (!values?.includes(locationId)) {
      assignedToLocations.push(locationId)
    }

    setFieldValue('assignedToLocations', assignedToLocations)
    setFieldValue('homeBaseLocationId', locationId);

    if (onValuesChange) onValuesChange();
  }, [values])

  const displayAlternateLocations = useMemo(
    () => values?.filter(_id => _id !== homeBaseLocationId && alternateLocationsOptions.some(loc => loc._id === _id)),
    [values, homeBaseLocationId, alternateLocationsOptions]
  )

  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px', paddingTop: '16px' }}>
      <Grid item xs={8} md={9} style={{ marginBottom: '17px', marginTop: '10px' }}>
        <Typography variant="h5">Location Access Permission:</Typography>
      </Grid>

      <Grid item xs={12}>
        <Field name="homeBaseLocationId">
          {({
            field,
            form,
          }) => (
            <label htmlFor="homeBaseLocationId">
              <Typography variant="body1" color="textSecondary">
                Primary Location:
              </Typography>
              <TextField
                select
                id="homeBaseLocationId"
                name={field.name}
                value={field.value}
                onChange={onHomeLocationChange}
                onBlur={field.onBlur}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Home Base Location' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {Object.values(activeLocations || []).map(location => (
                  <Option key={location._id} value={location._id}>
                    {location.name}
                  </Option>
                ))}
              </TextField>
              {form.errors.homeBaseLocationId && form.touched.homeBaseLocationId ? (
                <div className={classes.error}>{form.errors.homeBaseLocationId}</div>
              ) : null}
            </label>
          )}
        </Field>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography variant="body1" color="textSecondary">
          Alternate Locations:
        </Typography>
      </Grid>
      
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <div style={{ position: 'relative' }}>
          <SearchLocationsInput
            options={alternateLocationsOptions}
            getOptionLabel={option => option?.name}
            getOptionKey={option => option?._id}
            onChange={onChange}
            values={locationValues}
            error={error}
          />
        </div>
      </Grid>

      <Grid container xs={12} className={classes.zeroPaddingTop}>
        <DndComponent
          typeOfLabel='location'
          values={displayAlternateLocations}
          remove={remove}
          move={move}
          replace={replace}
          getItemName={(locationId) => locations.find(({ _id }) => _id === locationId)?.name}
          findItemById={(items, id) => items.find(locationId => locationId === id)}
        />
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(LocationAccessPermission);
