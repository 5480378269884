import React, { useState, useMemo, useCallback } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { isIOS, Option } from '../../../../../../../../components/Option';
import btnStyles from '../../styles';
import { withStyles } from '@material-ui/styles';
import { getGlassBeadsTypes } from '../../constants';
import { GlassBeadsMaterialSchema } from '../../schemas';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDndLabels } from '../../../../../../../../components/DndComponent/hooks/useDndLabels';
import { GridDND, SortableItem, Item } from '../materialDndComponents/materialDndComponents';
import { MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

const GlassBeats = ({ values, remove, push, replace, move, type, classes, measurement }) => {
  const glassBeadsTypes = useMemo(() => getGlassBeadsTypes(measurement), [measurement]);

  const [id, setId] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [name, setName] = useState('');
  const [ratio, setRatio] = useState('');
  const [unitType, setUnitType] = useState(
    glassBeadsTypes.find((props) => props.materialType === type).type
  );
  const [errors, setErrors] = useState({});

  const { items, setItems, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel } =
    useDndLabels({ values, move });

  const glassBeadsData = useSelector((state) => state.supplies.glassBeads.data);
  const sortedGlassBeadsData = useMemo(
    () =>
      [...glassBeadsData].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      ),
    [glassBeadsData]
  );
  const clearErrors = useCallback(
    (prop) => {
      if (!prop) {
        setErrors({});
      } else {
        const errorsObj = Object.assign({}, errors);
        delete errorsObj[prop];
        setErrors(errorsObj);
      }
    },
    [errors]
  );

  const resetForm = useCallback(() => {
    setName('');
    setRatio('');
    setUnitType(glassBeadsTypes.find((props) => props.materialType === type).type);
    setId('');
    setErrors({});
  }, [type]);

  const addGlassBead = useCallback(() => {
    try {
      const result = {
        beadId: name,
        ratio,
        unitType,
        _id: uuidv4(),
      };

      const validated = GlassBeadsMaterialSchema(values || []).validateSync(result, {
        abortEarly: false,
        stripUnknown: true,
      });

      push(validated);
      setItems((dragItems) => [...dragItems, validated]);
      resetForm();
    } catch (err) {
      const errObject = {};
      (err.inner || []).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  }, [push, name, ratio, unitType]);
  const editSave = () => {
    try {
      const result = {
        ...values[editIndex],
        beadId: name,
        ratio,
        unitType,
        _id: id,
      };

      if (!!result.oldBeadId) {
        result.oldBeadId = parseInt(result.oldBeadId) || undefined;
      }

      const validated = GlassBeadsMaterialSchema(values || [], editIndex).validateSync(result, {
        abortEarly: false,
        stripUnknown: true,
      });
      replace(editIndex, validated);
      setItems((dragItems) => dragItems.map((el) => (el._id === validated._id ? validated : el)));
      resetForm();
      setEditIndex(null);
    } catch (err) {
      const errObject = {};
      (err.inner || []).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  };

  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px' }}>
      <Grid item xs={8} md={9} style={{ marginBottom: '17px', marginTop: '10px' }}>
        <Typography variant="h5">Link of Reflective Media:</Typography>
      </Grid>
      <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
        <Grid item xs={6} lg={4}>
          <label htmlFor="glassBeadsName">
            <Typography variant="body1" color="textSecondary">
              Type of Reflective Media
            </Typography>
            <TextField
              select
              id="glassBeadsName"
              value={name}
              variant="outlined"
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                style: {
                  height: '35px',
                },
                className: `${errors.beadId ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              onChange={(e) => {
                setName(e.target.value);
                clearErrors('beadId');
              }}
            >
              {sortedGlassBeadsData.map((m) => (
                <Option key={m._id} value={m._id}>
                  {m.name}
                </Option>
              ))}
            </TextField>
          </label>
          {errors.beadId && <span className="materials-input--error-text">{errors.beadId}</span>}
        </Grid>
        <Grid item xs={6} lg={4}>
          <label htmlFor="glassBeadsType">
            <Typography variant="body1" color="textSecondary">
              Unit of Measurement:
            </Typography>
            <TextField
              select
              id="glassBeadsType"
              name="unitType"
              variant="outlined"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                style: {
                  height: '35px',
                },
              }}
              onChange={(e) => {
                setUnitType(e.target.value);
              }}
              fullWidth={true}
              value={unitType}
              SelectProps={{
                native: isIOS,
              }}
            >
              {glassBeadsTypes.map((m) => (
                <Option key={m.type} value={m.type}>
                  {m.name}
                </Option>
              ))}
            </TextField>
          </label>
        </Grid>
        <Grid item xs={6} lg={4}>
          <label htmlFor="glassBeadsRatio">
            <Typography variant="body1" color="textSecondary">
              {`Ratio ${MEASURE_UNITS_BY_SYSTEM[measurement][unitType]}`}
            </Typography>
            <TextField
              id="glassBeadsRatio"
              value={ratio}
              type="number"
              variant="outlined"
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.ratio ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
              onChange={(e) => {
                setRatio(e.target.value);
                clearErrors('ratio');
              }}
            />
          </label>
          {errors.ratio && <span className="materials-input--error-text">{errors.ratio}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
        {!Number.isInteger(editIndex) && (
          <Grid item xs={'auto'}>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              style={{ padding: '2px 7px 2px 3px', marginTop: '15px' }}
              onClick={addGlassBead}
              className={classes.addButton}
            >
              <AddIcon fontSize="small" color="primary" />
              <span>Add</span>
            </Button>
          </Grid>
        )}
        {!!Number.isInteger(editIndex) && (
          <>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                style={{ padding: '2px', marginTop: '15px' }}
                onClick={(e) => {
                  setEditIndex(null);
                  resetForm();
                }}
                className={classes.cancelEddingButton}
              >
                <span>Cancel</span>
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                style={{ padding: '2px 10px', marginTop: '15px' }}
                onClick={editSave}
                className={classes.addButton}
              >
                <span>Save</span>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={1} style={{ margin: 0 }}>
        <Typography variant="body1" color="textSecondary">
          Click to edit
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: '15px' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragEnd={handleDragCancel}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragEnd}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            <GridDND columns={5} classes={classes}>
              <ul className={classes.itemsList}>
                {items.map((elem, index) => (
                  <li key={index} style={{ margin: '0 8px 8px 0' }}>
                    <SortableItem
                      classes={classes}
                      key={elem._id}
                      id={elem._id}
                      elem={elem}
                      index={index}
                      editIndex={editIndex}
                      remove={remove}
                      typeOfLabel={'glassBead'}
                      measurement={measurement}
                      setItems={setItems}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setErrors({});
                        setEditIndex(index);
                        setName(elem.beadId);
                        setRatio(elem.ratio);
                        setUnitType(elem.unitType);
                        setId(elem._id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </GridDND>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Item
                classes={classes}
                id={activeId}
                editIndex={editIndex}
                elem={items.find((el) => el._id === activeId)}
                typeOfLabel={'glassBead'}
                measurement={measurement}
                isDragging
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Grid>
    </Grid>
  );
};
export default withStyles(btnStyles)(GlassBeats);
