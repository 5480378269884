import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import classnames from 'classnames';
import {
  startOfDay,
  endOfDay,
  isBefore,
  isAfter,
  format,
  parse,
  getHours,
  getMinutes,
  addDays,
  parseISO,
} from 'date-fns';
import _ from 'lodash';
import theme from 'theme';
import WorklogDetails from 'components/WorklogDetails';
import ConfirmDialog from 'components/ConfirmDialog';
import AlertModal from 'components/AlertModal';
import { calcPrevNextForResources, getSheetsWithoutForeman } from './scheduleHelpers';
import ScheduleEvent from './components/ScheduleEvent';
import UpdateModal from './components/UpdateModal';
import GridTable from './components/GridTable';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import store from 'store/store';
import {
  getSheets,
  publish,
  reconcile,
  sendReconcileEmail,
  updateSheets,
} from 'store/schedule/schedulePageOperation';
import { WorklogDrawer } from './components/WorklogDrawer/index';
import { SCHEDULE_TYPES } from '../SchedulePage/helpers/scheduleType';
import { getSettings } from './helpers/getSettings';
import { checkAccessRTS } from './components/ScheduleEvent/helpers/checkAccessRTS';
import { FORMATS } from 'helpers/formats';
import { CrewLeaderHeader } from './CrewLeaderHeader';
import { CrewLeaderGrid } from './CrewLeaderGrid';
import './styles.scss';
import ClScheduleEvent from './components/ScheduleEvent/ClScheduleEvent';
import { rolePermissions } from 'common/permissions/rolePermissions';
import { findEquipment } from './helpers/findEquipment';
import {
  globalBEDateFormat,
  getTimeFormat,
  allDatesFormat,
  convertTime,
} from 'common/dateFormatConfig';
import { getHandleMouseMove } from './helpers/handleMouseMove';
import ResourceActionsBar from './components/ResourceActionsBar/ResourceActionsBar';
import PublishModal from './components/PublishModal/index';

const isSafari = () =>
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

class ScheduleCalendar extends Component {
  state = {
    sheetsInView: [],
    sheetsWithoutForemanIds: [],
    additionalRowsCount: 0,
    savedScheduleType: SCHEDULE_TYPES.time,
    savedDate: format(new Date(), this.props.dateFormat),
    isDraggingChecked: false,
    canDragResources: false,
    drawer: {
      type: drawerTypes.update,
      isOpen: false,
      sheet: {},
    },
    additionalDrawer: {
      type: 'resources',
      subType: 'people',
      isOpen: false,
      material: {},
    },
    confirmModal: {
      isOpen: false,
      text: '',
      type: 'publish',
    },
    infoModal: {
      isOpen: false,
      text: '',
    },
    updateModal: {
      isOpen: false,
      data: {},
    },
    publishModalData: {
      isOpen: false,
      data: {},
    },
    rtsAccess: {},
    crewLeaders: [],
    details: {
      sheetId: '',
      clId: '',
    },
  };

  calendarRef = React.createRef();
  calendarWrapperRef = React.createRef();

  getSheets = () => {
    const { selectedDate, filters, scheduleType } = this.props;
    const date = format(selectedDate, globalBEDateFormat);
    const isGridView = scheduleType === SCHEDULE_TYPES.grid;

    this.props.getSheets({
      date,
      filters,
      // isGenerateSheetObjects: 1,
      // isSorted: 1,
      isCrewLeader: this.props.scheduleType === SCHEDULE_TYPES.cl,
      isGridView,
      showAllLocations: this.props.showAllLocations,
    });
  };

  updateRTSAccess = async () => {
    const { rtsAccess } = this.state;
    const { user, sheets, showAllLocations } = this.props;

    if (!user) return;
    const sheetIdsWithCheckedAccess = Object.keys(rtsAccess);
    const sheetsWithoutCheck = sheets.filter(
      (s) => !sheetIdsWithCheckedAccess.includes(s.sheet._id)
    );

    const checks = sheets.map((sheet) => !showAllLocations && checkAccessRTS({ sheet, user }));

    const checkedSheets = sheets.reduce(
      (res, sheet, i) => ({ ...res, [sheet.sheet._id]: checks[i] }),
      {}
    );

    this.setState({ rtsAccess: { ...rtsAccess, ...checkedSheets } });
    this.updateVisibleSheets();
  };

  componentDidMount() {
    this.getSheets();
    const handleMouseMoveActions = getHandleMouseMove(this.calendarRef)

    this.setState({
      ...this.state,
      handleMouseMoveActions,
    });
    handleMouseMoveActions.init();
  }

  componentWillUnmount() {
    this.state.handleMouseMoveActions && this.state.handleMouseMoveActions.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const isDataLoaded =
      prevProps.isDataLoading !== this.props.isDataLoading && !this.props.isDataLoading;
    const isNewSheets = prevProps.sheets !== this.props.sheets;
    const prevDate = this.state.savedDate;
    const nextDate = format(this.props.selectedDate, this.props.dateFormat);
    const isNewDate = prevDate !== nextDate;
    const isNewSheetsInView = prevState.sheetsInView !== this.state.sheetsInView;
    const isNewScheduleType = this.state.savedScheduleType !== this.props.scheduleType;
    const isNewFilter = prevProps.filters !== this.props.filters;
    const needToPublishSchedule =
      prevProps.showPublishModal !== this.props.showPublishModal && this.props.showPublishModal;
    const reconcileModalShown =
      prevProps.reconcileModalShown !== this.props.reconcileModalShown &&
      this.props.reconcileModalShown;
    const needToSetWorklogs =
      prevProps.needToSetWorklogs !== this.props.needToSetWorklogs && this.props.needToSetWorklogs;
    const clDetailsChanged = prevState.details !== this.state.details;
    const showAllLocationsChanged = prevProps.showAllLocations !== this.props.showAllLocations;

    if (isNewSheets) {
      this.getSheets();
    }

    if (isNewDate || isNewScheduleType || isNewFilter) {
      this.getSheets();
      this.scrollUp();
    }
    if (clDetailsChanged) {
      this.updateVisibleSheets();
    }
    if (
      isDataLoaded ||
      isNewSheets ||
      this.props.sheets.length !== this.state.sheetsInView.length
    ) {
      this.updateRTSAccess();
    }
    if (needToPublishSchedule) {
      this.publishSchedule();
    }
    if (isNewSheetsInView) {
      const isReconcileBtn = Boolean(this.state.sheetsInView.length);
      const sheetsToUpdate = this.state.sheetsInView.filter((s) => !s.sheet.reconciled);
      const isReconcileBtnDisabled = !sheetsToUpdate.length;
      this.props.updateReconcileBtn(isReconcileBtn, isReconcileBtnDisabled);
    }
    if (reconcileModalShown) {
      this.openReconcileConfirm();
    }
    if (needToSetWorklogs) {
      this.printSchedule();
    }
    if (showAllLocationsChanged) {
      this.getSheets();
    }

    if (isNewDate) {
      this.setState({
        savedDate: format(this.props.selectedDate, this.props.dateFormat),
        details: {
          sheetId: '',
          clId: '',
        },
      });
    }
    if (isNewScheduleType) {
      this.setState({
        savedScheduleType: this.props.scheduleType,
        details: {
          sheetId: '',
          clId: '',
        },
      });
    }

    if ((this.props.user && !this.state.isDraggingChecked) || showAllLocationsChanged) {
      const userPermissions = {
        ...(rolePermissions[this.props.user.profile?.role?.roleName] || {}),
        ...(this.props.user.profile?.role?.extPermissions || {}),
      };

      this.setState({
        canDragResources: !!(
          userPermissions['scheduleEdit'] || userPermissions['scheduleFullAccess']
        ) && !this.props.showAllLocations,
        isDraggingChecked: true,
      });
    }

    const elHeader = document.querySelector('.fc-time-grid__header');
    if (elHeader && this.props.scheduleType !== SCHEDULE_TYPES.cl) {
      elHeader.remove();
    }

    if (!elHeader && this.props.scheduleType === SCHEDULE_TYPES.cl) {
      const el = document.querySelector('.fc-timeGrid-view');
      if (!el) return;

      const div = document.createElement('div');
      div.className = 'fc-time-grid__header';
      el.prepend(div);
    }

    this.toggleTimeView();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const condition1 = !nextProps.isDataLoading;
    const condition2 =
      this.props.needToSetWorklogs !== nextProps.needToSetWorklogs && !nextProps.needToSetWorklogs;
    return condition1 && !condition2;
  }

  updateVisibleSheets = () => {
    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(this.props.selectedDate);
    calendarApi.refetchEvents();
  };

  scrollUp = () => {
    const container =
      this.props.scheduleType === SCHEDULE_TYPES.grid
        ? document.getElementById('gridWrapper')
        : document.querySelector('.fc-view');
    container.scrollTo(0, 0);
  };

  openDrawer =
    (type, sheet = {}) =>
    () => {
      this.setState({ drawer: { type, isOpen: true, sheet } });
    };
  closeDrawer = () => {
    this.setState((prevState) => ({
      drawer: { ...prevState.drawer, type: 'details', isOpen: false },
    }));
  };
  openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      this.setState({
        additionalDrawer: { type, subType, isOpen: true, material },
      });
    };
  closeAdditionalDrawer = () => {
    this.setState((prevState) => ({
      additionalDrawer: { ...prevState.additionalDrawer, isOpen: false },
    }));
  };
  closeModal = (type) => (e, reason) => {
    if (reason == 'escapeKeyDown' || reason == 'backdropClick') return null;
    this.setState({ [type]: { isOpen: false, text: '' } });
  };

  openDetails = (sheetId, clId) => this.setState({ details: { sheetId, clId } });

  submitPublishSchedule = async () => {
    const { sheetsInView } = this.state;
    const { scheduleType, publishSchedule } = this.props;
    const ids = sheetsInView
      .filter((s) => !s.noWorkers)
      .filter((s) => !s.sheet.published)
      .map((s) => s.sheet._id);

    try {
      await publishSchedule({ sheetIds: ids });

      this.setState({
        sheetsWithoutForemanIds: [],
        confirmModal: { isOpen: false, text: '', type: 'publish' },
      });
    } catch (err) {
      this.setState({
        sheetsWithoutForemanIds: [],
        infoModal: { isOpen: true, text: err?.response?.data?.message || err.message },
        confirmModal: { isOpen: false, text: '', type: 'publish' },
      });
    }
  };

  submitReconcile = () => {
    const { sheetsInView } = this.state;
    const { selectedDate, scheduleType, openSnackbar, reconcile, sendReconcileEmail } = this.props;

    const isGridView = scheduleType === SCHEDULE_TYPES.grid;
    const currentDate = format(selectedDate, globalBEDateFormat);
    const sheetIds = sheetsInView.filter((s) => !s.sheet.reconciled).map((s) => s.sheet._id);

    const onError = () => openSnackbar('error', 'Error during reconcile');

    const onSuccess = () => {
      this.printSchedule();
    };

    reconcile({
      viewType: scheduleType,
      gridDate: isGridView && currentDate,
      date: !isGridView && selectedDate,
      sheetIds,
      onSuccess,
      onError,
    });
    this.setState({
      confirmModal: { isOpen: false, text: '', type: 'publish' },
    });
  };

  publishSchedule = async () => {
    const { sheetsInView } = this.state;
    const { scheduleType, selectedDate, updatePublishControl, unpublishedSchedule } = this.props;
    const isGridView = scheduleType === SCHEDULE_TYPES.grid;
    const currentDate = format(selectedDate, this.props.dateFormat);
    const settings = await getSettings();
    let sheetsThisDay = sheetsInView.filter((s) => !s.noWorkers);

    if (unpublishedSchedule) {
      //* for publish
      let notPublishedSheets = sheetsThisDay.filter((s) => !s.sheet.published);
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(notPublishedSheets);
      if (sheetsWithoutForemanIds.length) {
        this.setState({
          sheetsWithoutForemanIds,
          infoModal: { isOpen: true, text: 'Crew Leader missing from highlighted project(s).' },
        });
      } else {
        let ids = notPublishedSheets.map((s) => s.sheet._id);
        let workers = [];
        notPublishedSheets.forEach((s) => {
          let project = s.sheet.project;
          const title = project && project.route + (project.section ? ', ' + project.section : '');
          s.sheet.workers.forEach((add) => {
            let w = workers.find((worker) => worker._id === add._id);
            if (w) {
              w.addedTo.push(title);
            } else {
              workers.push({ _id: add._id, addedTo: [title] });
            }
          });
        });
        workers = workers.map((w) => {
          w.user = this.props.workers.find((worker) => worker._id === w._id);
          return w;
        });

        if (!ids.length) {
          //! new feature for deny request if  you have no data for publish
          this.setState({
            infoModal: { isOpen: true, text: 'No sheets for publish' },
          });
          updatePublishControl(false)();
          this.updateVisibleSheets();
          return;
        }

        if (settings.scheduleNotifications) {
          this.setState({
            sheetsWithoutForemanIds: [],
            publishModalData: {
              isOpen: true,
              data: { workers, ids, isGridView, currentDate },
            },
          });
        } else {
          const requiredTextPart = 'Do you want to publish this schedule?';
          const confirmText = `Notifications are currently turned off. Notices will not be sent. ${requiredTextPart}`;
          this.setState({
            confirmModal: { isOpen: true, text: confirmText, type: 'publish' },
          });
        }
      }
    } else {
      //* for update
      let publishedSheets = sheetsThisDay.filter(
        (s) => s.sheet.published && s.sheet.unpublishedChanges
      );
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(publishedSheets);
      if (sheetsWithoutForemanIds.length) {
        this.setState({
          sheetsWithoutForemanIds,
          infoModal: { isOpen: true, text: 'Crew Leader missing from highlighted project(s).' },
        });
      } else {
        let ids = publishedSheets.map((s) => s.sheet._id);
        let workers = [];
        publishedSheets.forEach((s) => {
          let project = s.sheet.project;
          const title = project && project.route + (project.section ? ', ' + project.section : '');
          s.sheet.workers.forEach((add) => {
            let w = workers.find((worker) => worker._id === add._id);
            if (w) {
              w.addedTo.push(title);
            } else {
              workers.push({ _id: add._id, addedTo: [title] });
            }
          });
        });
        workers = workers.map((w) => {
          w.user = this.props.workers.find((worker) => worker._id === w._id);
          return w;
        });

        if (!ids.length) {
          //! new feature for deny request if  you have no data for update
          this.setState({
            infoModal: { isOpen: true, text: 'No sheets for update' },
          });
          updatePublishControl(false)();
          this.updateVisibleSheets();
          return;
        }

        this.setState({
          sheetsWithoutForemanIds: [],
          updateModal: {
            isOpen: true,
            data: { workers, ids, isGridView, currentDate },
          },
        });
      }
    }
    updatePublishControl(false)();
    this.updateVisibleSheets();
  };

  openReconcileConfirm = () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        text: 'Do you wish to Reconcile Schedule?',
        type: 'reconcile',
      },
    });
    this.props.closeReconcileModal();
  };

  printSchedule = () => {
    const calendarApi = this.calendarRef.current.getApi();
    const rawEvents = calendarApi.getEvents();
    const events = rawEvents.map((el) => ({
      ...el.extendedProps,
      start: el.start,
      end: el.end,
    }));
    const { sheetsInView } = this.state;
    const { scheduleType, selectedDate, setWorklogsForPrint, equipmentList, workers } = this.props;
    const isGridView = scheduleType === SCHEDULE_TYPES.grid;
    let currentEvents = isGridView ? sheetsInView : events;

    if (scheduleType === SCHEDULE_TYPES.cl) {
      const ids = {};
      currentEvents = events.filter((e) => {
        if (ids[e.sheet._id]) return false;
        ids[e.sheet._id] = 1;
        return true;
      });
    }
    const worklogs = currentEvents
      .sort((a, b) =>
        a.project.contractor.nickname && b.project.contractor.nickname
          ? a.project.contractor.nickname.localeCompare(b.project.contractor.nickname)
          : 0
      )
      .map((e) => {
        e.originalEnd = isGridView ? e.originalEnd : e.originalEnd || e.start;
        const equipment = e.equipment.length
          ? e.equipment.map(({ _id }) => findEquipment(_id, equipmentList, this.props.sheets))
          : null;
        let startTime;
        if (e.sheet.startTime) {
          const is12Format = getTimeFormat(
            [],
            this.props.timeFormat,
            this.props.dateFormat
          ).is12Format;

          startTime = convertTime(e.sheet.startTime, is12Format);
        }
        return {
          createdAtDate: e.sheet.createdAtDate,
          start: e.originalStart,
          // start: isGridView ? e.originalStart : e.start,
          end: e.originalEnd,
          contractorNickname: e.project.contractor.nickname || e.project.contractor.name,
          county: e.project.county,
          routeSection: `${e.project.route || ''}${
            e.project.section ? '– ' + e.project.section : ''
          }`,
          jobNumber: e.project.jobNumber,
          rated: e.project.rated,
          workers: e.workers.map((worker) => {
            const currentWorker = this.props.workers.find((w) => w._id === worker._id);
            const { start, end } =
              worker.hours ||
              (isGridView
                ? { start: e.originalStart, end: e.originalEnd }
                : { start: e.start, end: e.originalEnd }
              );

            return {
              ...worker,
              username: currentWorker && currentWorker.username ? currentWorker.username : '',
              employeeNum: currentWorker && currentWorker.profile && currentWorker.profile.employeeNum
                ? currentWorker.profile.employeeNum
                : '',
              startTime,
              start: currentWorker && currentWorker.username
                ? format(
                    start ? new Date(start) : new Date(),
                    getTimeFormat([], this.props.timeFormat, this.props.dateFormat).formatForTimePars
                  )
                : '',
              end: currentWorker && currentWorker.username
                ? format(
                    end ? new Date(end) : new Date(),
                    getTimeFormat([], this.props.timeFormat, this.props.dateFormat).formatForTimePars
                  )
                : '',
            };
          }),
          notes: e.sheet.notes || ' ',
          equipment,
          isCanceled: e.sheet.canceledAt || false,
          canceledAt: e.sheet.canceledAt,
          cancelNote: e.sheet.cancelNote,
          canceledBy:
            e.sheet.canceledBy &&
            (workers.find((w) => w._id === e.sheet.canceledBy) || {}).username,
          id: e.sheet._id,
        };
      })
      .filter((e) => {
        const startThisDay = !isBefore(new Date(e.start), startOfDay(new Date(selectedDate)));
        if (isGridView) {
          return (e.workers.length && e.workers.length > 0) || e.equipment || e.isCanceled;
        }
        if (startThisDay) {
          return (e.workers.length && e.workers.length > 0) || e.equipment || e.isCanceled;
        } else {
          return false;
        }
      });
    setWorklogsForPrint(worklogs);
  };

  sortEvents = (a, b) => {
    return (
      new Date(a.extendedProps.sheet.hours.start || a.extendedProps.sheet.createdAt).valueOf() -
      new Date(b.extendedProps.sheet.hours.start || b.extendedProps.sheet.createdAt).valueOf()
    );
  };

  createEvents = ({ start, end }, callback) => {
    const methods = {
      [SCHEDULE_TYPES.time]: this.createTimeEvents,
      [SCHEDULE_TYPES.grid]: this.createGridEvents,
      [SCHEDULE_TYPES.cl]: this.createClEvents,
    };

    return methods[this.props.scheduleType]({ start, end }, callback);
  };

  createTimeEvents = ({ start, end }, callback) => {
    const currentDate = this.props.selectedDate;
    const dataSheet = _.cloneDeep(this.props.sheets);

    const data = dataSheet.map((sheet, index, arr) => {
      sheet.workers = sheet.workers.map((resource, i) =>
        calcPrevNextForResources(resource, i, index, arr, 'workers')
      );
      sheet.equipment = sheet.equipment.map((resource, i) =>
        calcPrevNextForResources(resource, i, index, arr, 'equipment')
      );

      sheet.end = parseISO(sheet.originalEnd);
      sheet.start = parseISO(sheet.originalStart);

      const isStartOnPrevDay = isBefore(sheet.start, startOfDay(currentDate));
      const additionalHeight = sheet.submittedAt || !sheet.published ? 22 : 0;
      const defaultHeaderHeight = 148 + additionalHeight;
      const headerMaxHeight = isStartOnPrevDay ? defaultHeaderHeight + 36 : defaultHeaderHeight;

      const notesMaxHeight = sheet.sheet.notes && sheet.sheet.notes.trim() ? 72 : 0;
      const workersHeight = sheet.workers.length ? sheet.workers.length * 30 + 23 : 0;
      const equipmentHeight = sheet.equipment.length ? sheet.equipment.length * 30 + 23 : 0;
      const resourcesHeight =
        workersHeight + equipmentHeight ? workersHeight + equipmentHeight + 16 : 0;

      const maxHeight = resourcesHeight + headerMaxHeight + notesMaxHeight + 16;
      sheet.maxHeight = maxHeight;

      const minHeightInHours = 5;
      const jobTime = Math.floor(Math.abs(sheet.end - sheet.start) / 36e5);
      const isHoursDiffTooLow = jobTime <= minHeightInHours;

      const hoursByHeight = Math.max(Math.ceil(maxHeight / 40), minHeightInHours);

      if (isHoursDiffTooLow || isStartOnPrevDay) {
        const selectedDate = format(currentDate, allDatesFormat[7]);
        const end = format(sheet.end, allDatesFormat[7]);

        let startHours = getHours(sheet.start);
        const startMinutes = getMinutes(sheet.start);
        let endHours = getHours(sheet.end);
        const endMinutes = getMinutes(sheet.end);

        startHours = isStartOnPrevDay && end === selectedDate ? 0 : startHours;
        endHours = end === selectedDate ? endHours : 24;
        endHours =
          hoursByHeight > endHours - startHours ? startHours + hoursByHeight : getHours(sheet.end);
        endHours = endHours > 24 ? 24 : endHours;

        const renderEndHours = startOfDay(currentDate);
        const renderedMinutes =
          hoursByHeight === minHeightInHours && startMinutes === endMinutes
            ? startMinutes
            : endMinutes;

        renderEndHours.setHours(endHours);
        renderEndHours.setMinutes(renderedMinutes);

        sheet.end = renderEndHours;
      } else {
        let startHours = getHours(sheet.start);
        const endMinutes = getMinutes(sheet.end);
        const renderEndHours = startOfDay(currentDate);
        renderEndHours.setHours(Math.min(startHours + hoursByHeight, 24));
        renderEndHours.setMinutes(endMinutes);
        sheet.end = renderEndHours;
      }
      return sheet;
    });

    this.setState({ sheetsInView: data }, () => {
      if (data) callback(data);
    });
  };

  createGridEvents = ({ start, end }, callback) => {
    const dataSheet = _.cloneDeep(this.props.sheets);
    const usedPositions = new Set();
    const getFirstAvailablePosition = (pos) => {
      let newPos = pos;

      while (usedPositions.has(newPos)) {
        newPos += 1;
      };

      usedPositions.add(newPos);
      return newPos;
    }

    const data = dataSheet.sort((a, b) => a.grid?.position - b.grid?.position).map((sheet, index, arr) => {
      return {
        ...sheet,
        workers: sheet.workers.map((resource, i) =>
          calcPrevNextForResources(resource, i, index, arr, 'workers')
        ),
        equipment: sheet.equipment.map((resource, i) =>
          calcPrevNextForResources(resource, i, index, arr, 'equipment')
        ),
        originalStart: sheet.originalStart,
        originalEnd: sheet.originalEnd,
        start: parse(sheet.grid.date, globalBEDateFormat, new Date()),
        gridPosition: this.props.showAllLocations ? getFirstAvailablePosition(sheet.grid?.position) : sheet.grid?.position
      };
    });

    let additionalRowsCount = 0;
    if (data) {
      const maxPosition = data.length
        ? data.reduce((prev, current) =>
            prev.grid.position > current.grid.position ? prev : current
          ).grid.position + 1
        : 0;
      const cellsCount = 30;
      if (maxPosition >= cellsCount) {
        let newCellsCount = maxPosition;
        while (newCellsCount % 5 || newCellsCount === maxPosition) {
          newCellsCount++;
        }
        additionalRowsCount = (newCellsCount - cellsCount) / 5;
      }
    }

    this.setState({ sheetsInView: data, additionalRowsCount }, () => {
      if (data) callback(data);
    });
  };

  createClEvents = ({ start, end }, callback) => {
    const currentDate = this.props.selectedDate;
    const dataSheet = _.cloneDeep(this.props.sheets);

    const getForemanId = (sheet) => sheet.workers.filter((w) => w.foreman);
    const crewLeaders = [...new Set(dataSheet.map(getForemanId).flat())];
    const crewLeaderIds = [...new Set(crewLeaders.map((cl) => cl._id))].map((_id) =>
      crewLeaders.find((cl) => cl._id === _id)
    );
    const sortedCL = crewLeaderIds
      // .map((cl) => this.props.workers.find((w) => w._id === cl))
      .sort((a, b) => {
        if (a.username > b.username) return 1;
        else return -1;
      });
    // const sortedCL = newSort.map((cl) => (cl ? cl._id : ''));
    const getSheetStartTime = (sheet) => {
      const cl = sheet.workers.find((w) => w._id === getForemanId(sheet)) || {};
      return cl.startTime || sheet.originalStart;
    };

    const data = dataSheet
      .map((sheet) => {
        sheet.end = sheet.individualTime?.end
          ? parseISO(sheet.individualTime.end)
          : sheet.originalEnd;
        sheet.start = sheet.individualTime?.start
          ? parseISO(sheet.individualTime.start)
          : sheet.originalStart;
        const isStartOnPrevDay = isBefore(parseISO(sheet.start), startOfDay(new Date()));
        const additionalHeight = sheet.submittedAt || !sheet.published ? 22 : 0;
        const defaultHeaderHeight = 148 + additionalHeight;
        const headerMaxHeight = isStartOnPrevDay ? defaultHeaderHeight + 36 : defaultHeaderHeight;

        const maxHeight = headerMaxHeight + 16;
        sheet.maxHeight = maxHeight;

        const minHeightInHours = 5;
        const jobTime = Math.floor(Math.abs(parseISO(sheet.end) - parseISO(sheet.start)) / 36e5);
        const isHoursDiffTooLow = jobTime <= minHeightInHours;

        const hoursByHeight = Math.max(Math.ceil(maxHeight / 40), minHeightInHours);

        if (isHoursDiffTooLow || isStartOnPrevDay) {
          const selectedDate = format(currentDate, allDatesFormat[7]);
          const end = format(parseISO(sheet.end), allDatesFormat[7]);

          let startHours = getHours(parseISO(sheet.start));
          const startMinutes = getMinutes(parseISO(sheet.start));
          let endHours = getHours(parseISO(sheet.end));
          const endMinutes = getMinutes(parseISO(sheet.end));

          startHours = isStartOnPrevDay && end === selectedDate ? 0 : startHours;
          endHours = end === selectedDate ? endHours : 24;
          endHours =
            hoursByHeight > endHours - startHours
              ? startHours + hoursByHeight
              : getHours(parseISO(sheet.end));
          endHours = endHours > 24 ? 24 : endHours;

          const renderEndHours = startOfDay(currentDate);
          const renderedMinutes =
            hoursByHeight === minHeightInHours && startMinutes === endMinutes
              ? startMinutes
              : endMinutes;

          renderEndHours.setHours(endHours);
          renderEndHours.setMinutes(renderedMinutes);

          sheet.end = renderEndHours;
        } else {
          let startHours = getHours(parseISO(sheet.start));
          const endMinutes = getMinutes(parseISO(sheet.end));
          const renderEndHours = startOfDay(currentDate);
          renderEndHours.setHours(Math.min(startHours + hoursByHeight, 24));
          renderEndHours.setMinutes(endMinutes);
          sheet.end = renderEndHours;
        }

        // const cl = getForemanId(sheet);
        const cl = sheet.cl;

        const COLUMN = cl
          ? sortedCL.findIndex((foreman) => cl._id === foreman._id)
          : sortedCL.length;
        // const COLUMN = sortedCL.findIndex(foreman/=>cl?._id === foreman._id) ;

        return {
          COLUMN,
          ...sheet,
        };
      })
      .sort((sheetA, sheetB) => +getSheetStartTime(sheetB) - +getSheetStartTime(sheetA))
      .sort((sheetA, sheetB) => sheetB.COLUMN - sheetA.COLUMN);
    // .sort((sheetA, sheetB) => (!sheetB.cl ? 1 : -1));
    // .sort((sheetA, sheetB) => (getForemanId(sheetB) === '' ? 1 : -1)); // sort so without crew leader ('') are the first,
    // so we can rely on event leveling provided by fullcalendar

    this.setState(
      {
        allSheetsThisDay: this.props.sheets,
        sheetsInView: data,
        crewLeaders: sortedCL,
      },
      () => {
        if (data) callback(data);
      }
    );
  };

  renderEvents = ({ event, el: element }) => {
    const { selectedDate, scheduleType, sheets, locations, showAllLocations } = this.props;
    if (scheduleType === SCHEDULE_TYPES.grid) return;

    const { sheetsWithoutForemanIds, rtsAccess, canDragResources } = this.state;
    const {
      sheet,
      originalEnd,
      originalStart,
      maxHeight,
      cl,
      COLUMN: column,
    } = event.extendedProps;
    const calendarApi = this.calendarRef.current.getApi();
    const rawEvents = calendarApi.getEvents();
    const sheetsInView = rawEvents.map((el) => {
      return {
        ...el.extendedProps,
        start: el.start,
        end: el.end,
      };
    });

    const selectedDateString = format(selectedDate, allDatesFormat[7]);
    const originalStartDateString = format(event.start, allDatesFormat[7]);
    const originalEndDateString = format(parseISO(originalEnd), allDatesFormat[7]);
    let originalStartTime = event.start.getTime();
    let originalEndTime = parseISO(originalEnd).getTime();
    if (selectedDateString > originalStartDateString) {
      originalStartTime = startOfDay(selectedDate).getTime();
    }
    if (selectedDateString < originalEndDateString) {
      originalEndTime = endOfDay(selectedDate).getTime();
    }
    const diffMinutes = (originalEndTime - originalStartTime) / 1000 / 60;
    const originalTimeHeight = Math.floor((diffMinutes * 2) / 3);

    element.style.overflow = 'hidden';
    const nextDay = startOfDay(addDays(selectedDate, 1));
    const endTime = event.end || parseISO(originalEnd);
    const isEndOverflowCalendar =
      !isAfter(endTime, nextDay) && !isBefore(endTime, endOfDay(selectedDate));
    const isEndOnNextDay =
      !isAfter(startOfDay(parseISO(originalEnd)), nextDay) &&
      isAfter(startOfDay(parseISO(originalEnd)), endOfDay(selectedDate));
    const needToChangeHeight = maxHeight > originalTimeHeight + 40; // >=  -36

    element.style.height = 'fit-content';
    if ((isEndOverflowCalendar || isEndOnNextDay) && needToChangeHeight) {
      element.style.height = 'fit-content';
      if (isSafari()) {
        element.style.display = 'table';
      }
    }
    element.classList.add('fc-fix-order');
    const isStartOnPrevDay = isBefore(parseISO(originalStart), startOfDay(selectedDate));
    const isEndNotToday = isAfter(parseISO(originalEnd), endOfDay(selectedDate));

    const detailsShown =
      this.state.details.sheetId === sheet._id && this.state.details.clId === cl?._id;

    const { navigate } = this.props;

    const locationColor = showAllLocations
      ? locations.find(loc => loc._id === sheet.tenantLocationId)?.color
      : false

    ReactDOM.render(
      // <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          {scheduleType === SCHEDULE_TYPES.cl && (
            <ClScheduleEvent
              selectedDate={selectedDate}
              el={element}
              cl={cl}
              column={column}
              sheet={sheet}
              hasAccess={rtsAccess[sheet._id]}
              sheetsWithoutForemanIds={sheetsWithoutForemanIds}
              allSheetsThisDay={sheets}
              sheetsInView={sheetsInView}
              openDetails={this.openDetails}
              detailsShown={detailsShown}
              navigate={navigate}
              locationColor={locationColor}
            />
          )}
          {scheduleType !== SCHEDULE_TYPES.cl && (
            <ScheduleEvent
              sheet={sheet}
              hasAccess={rtsAccess[sheet._id]}
              originalTimeHeight={originalTimeHeight}
              sheetsWithoutForemanIds={sheetsWithoutForemanIds}
              allSheetsThisDay={sheets}
              sheetsInView={sheetsInView}
              shouldRenderNextDayBlock={isEndOnNextDay}
              shouldRenderPrevDayBlock={isStartOnPrevDay}
              isEndNotToday={isEndNotToday}
              needToChangeHeight={needToChangeHeight}
              navigate={navigate}
              canDragResources={canDragResources}
              locationColor={locationColor}
            />
          )}
        </Provider>
      </MuiThemeProvider>,
      // </BrowserRouter>
      element
    );
    return element;
  };

  renderSegments = ({ view }) => {
    const segs = view.timeGrid.eventRenderer.segs;
    const levels = segs.map((el) => el.level);
    const maxLvl = Math.max.apply(null, levels);
    const lvlCounts = maxLvl + 1 + 1;
    const eventsWitdh = lvlCounts * 203 + 3 * maxLvl;
    const container = document.querySelector('.fc-time-grid');
    const containerWidth = container.offsetWidth;
    if (containerWidth < eventsWitdh) {
      container.style.width = `${eventsWitdh}px`;
    }
    const step = 203;
    segs.forEach((seg) => {
      const isCLView = seg.eventRange.def.extendedProps.COLUMN !== undefined;
      const withoutCL = !seg.eventRange.def.extendedProps.cl;

      let left =
        (!isCLView
          ? seg.level
          : withoutCL
          ? seg.eventRange.def.extendedProps.COLUMN + seg.level
          : seg.eventRange.def.extendedProps.COLUMN) * step;

      seg.el.style.left = `${left}px`;
      seg.el.style.right = '0';
      const detailsShown =
        isCLView &&
        this.state.details.sheetId === seg.eventRange.def.extendedProps.sheet._id &&
        this.state.details.clId === seg.eventRange.def.extendedProps.cl?._id;
      if (detailsShown) seg.el.style.zIndex = 999;

      if (!isCLView) return;
      const timeGridEl = document.querySelector('.fc-time-grid');
      const elHeader = document.querySelector('.fc-time-grid__header');
      if (left + step > timeGridEl.clientWidth) {
        timeGridEl.style.width = `${left + step + 55}px`;
        if (elHeader) {
          elHeader.style.width = `${left + step + 19}px`;
        }
      }
    });
  };

  toggleTimeView = () => {
    const isGridView = this.props.scheduleType === SCHEDULE_TYPES.grid;
    const container = document.querySelector('.fc-timeGrid-view');
    const isContainerShown = container.style.height !== '0px';
    if (isGridView && isContainerShown) {
      container.style.height = '0px';
    }
    if (!isGridView && !isContainerShown) {
      container.removeAttribute('style');
    }
  };

  getEvents = () => {
    const calendarApi = this.calendarRef.current.getApi();
    return calendarApi.getEvents();
  };

  destroyEvents = ({ el }) => {
    ReactDOM.unmountComponentAtNode(el);
  };

  render() {
    const { openSnackbar, scheduleType, sheets, workers, isShow, navigate, showAllLocations, locations } = this.props;
    const {
      drawer,
      additionalDrawer,
      confirmModal,
      infoModal,
      updateModal,
      publishModalData,
      additionalRowsCount,
      sheetsWithoutForemanIds,
      sheetsInView,
      rtsAccess,
      crewLeaders,
      canDragResources,
    } = this.state;

    return (
      <>
        {scheduleType === SCHEDULE_TYPES.cl && (
          <CrewLeaderHeader crewLeaders={crewLeaders} workers={workers} />
        )}
        {scheduleType === SCHEDULE_TYPES.cl && <CrewLeaderGrid crewLeaders={crewLeaders} />}

        <WorklogDetails
          hideResourses={this.hideResourses}
          openSnackbar={openSnackbar}
          updateVisibleSheets={() => {}}
          openDrawer={this.openDrawer}
          openAdditionalDrawer={this.openAdditionalDrawer}
          updateDrawerFromRouter={this.closeDrawer}
          sheetIds={[]}
          hasMore={false}
          pageCount={0}
          isSchedule
        />
        <div
          ref={this.calendarWrapperRef}
          className={classnames(
            'fullCalendarWrapper',
            scheduleType === SCHEDULE_TYPES.grid && 'lessMarginLeft',
            scheduleType === SCHEDULE_TYPES.cl && 'moreMarginTop',
            isShow && 'showeRes'
          )}
        >
          <FullCalendar
            key={scheduleType}
            ref={this.calendarRef}
            eventOrder={this.sortEvents}
            header={false}
            handleWindowResize={false}
            defaultView="timeGrid"
            slotDuration="02:00:00"
            contentHeight={1080}
            height="auto"
            editable={false}
            allDaySlot={false}
            slotEventOverlap={false}
            aspectRatio={0.1}
            minTime="00:00:00"
            maxTime="24:00:01"
            locale={
              getTimeFormat([], this.props.timeFormat, this.props.dateFormat).is12Format
                ? ''
                : 'en-GB'
            }
            nowIndicator={true}
            plugins={[timeGridPlugin]}
            events={this.createEvents}
            eventRender={this.renderEvents}
            eventDestroy={this.destroyEvents}
            eventPositioned={this.renderSegments}
            slotLabelFormat={
              getTimeFormat([], this.props.timeFormat, this.props.dateFormat).is12Format
                ? ''
                : { hour: '2-digit', minute: '2-digit' }
            }
          />

          {scheduleType === SCHEDULE_TYPES.grid && (
            <GridTable
              rtsAccess={rtsAccess}
              additionalRowsCount={additionalRowsCount}
              getEvents={this.getEvents}
              allSheetsThisDay={sheets}
              sheetsInView={sheetsInView}
              navigate={navigate}
              sheetsWithoutForemanIds={sheetsWithoutForemanIds}
              canDragResources={canDragResources}
              locations={locations}
              showAllLocations={showAllLocations}
            />
          )}

          <ResourceActionsBar />
        </div>

        <WorklogDrawer
          drawer={drawer}
          // sheetId={sheetId}//!changed logic
          additionalDrawer={additionalDrawer}
          openSnackbar={openSnackbar}
          closeDrawer={this.closeDrawer}
          closeAdditionalDrawer={this.closeAdditionalDrawer}
        />

        {confirmModal.isOpen && (
          <ConfirmDialog
            isOpen={confirmModal.isOpen}
            onClose={this.closeModal('confirmModal')}
            onSubmit={
              confirmModal.type === 'publish' ? this.submitPublishSchedule : this.submitReconcile
            }
            text={confirmModal.text}
            loadingOnSubmit={true}
          />
        )}
        {infoModal.isOpen && (
          <AlertModal
            isOpen={infoModal.isOpen}
            info={infoModal.text}
            onClose={this.closeModal('infoModal')}
          />
        )}
        {updateModal.isOpen && (
          <UpdateModal
            isOpen={updateModal.isOpen}
            onClose={this.closeModal('updateModal')}
            data={updateModal.data}
            openSnackbar={openSnackbar}
          />
        )}
        {publishModalData.isOpen && (
          <PublishModal
            isOpen={publishModalData.isOpen}
            onClose={this.closeModal('publishModalData')}
            data={publishModalData.data}
            openSnackbar={openSnackbar}
          />
        )}
      </>
    );
  }
}

ScheduleCalendar.propTypes = {
  isDataLoading: propTypes.bool.isRequired,
  sheets: propTypes.array.isRequired,
  equipmentList: propTypes.array,
  openSnackbar: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  scheduleType: propTypes.string.isRequired,
  showPublishModal: propTypes.bool.isRequired,
  updatePublishControl: propTypes.func.isRequired,
  updateReconcileBtn: propTypes.func.isRequired,
  reconcileModalShown: propTypes.bool.isRequired,
  closeReconcileModal: propTypes.func.isRequired,
  needToSetWorklogs: propTypes.bool.isRequired,
  setWorklogsForPrint: propTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSheets: (arg) => {
      dispatch(getSheets(arg));
    },
    reconcile: (arg) => {
      dispatch(reconcile(arg));
    },
    sendReconcileEmail: (arg) => {
      dispatch(sendReconcileEmail(arg));
    },
    publishSchedule: async (arg) => {
      try {
        await dispatch(publish(arg));
      } catch (e) {
        throw new Error(e.message);
      }
    },
  };
};

export default connect(
  (state) => ({
    sheets: state.schedule.sheets,
    scheduleType: state.schedule.view,
    selectedDate: state.schedule.selectedDate,
    isDataLoading: state.schedule.loading > 0,
    equipmentList: state.schedule.resources.equipment,
    unpublishedSchedule: state.schedule.unpublishedSchedule,
    workers: state.schedule.resources.workers,
    filters: state.schedule.filters,
    user: state.personalProfile.user,
    dateFormat: state.personalProfile.organization?.settings?.dateFormat,
    timeFormat: state.personalProfile.organization?.settings?.timeFormat,
    locations: state.personalProfile.locations,
  }),
  mapDispatchToProps
)(ScheduleCalendar);
