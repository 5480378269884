import React, { useState } from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';

import EquipmentCard from './components/EquipmentCard';

const EquipmentList = ({ equipment, openDrawer, updateVisibleEquipment, isLoading, hasMore, openSnackbar, initialLoad, pageStart }) => {
  const loadMore = (page) => {
    if (isLoading) return
    if (hasMore) {
      updateVisibleEquipment(null, page, null);
    }
  };

  if (!equipment.length) {
    return (
      <Typography variant="h3" align="center" style={{ padding: '1.72em 0 2em', opacity: 0.5 }}>
        {!initialLoad && 'No Results'}
      </Typography>
    )
  }

  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={loadMore}
      // loading={isLoading}
      hasMore={hasMore}
      threshold={300}
      // loader={<span style={{ fontSize: 14 }}>Loading...</span>}
    >
      <Grid container spacing={2} justifyContent="flex-start" style={{ paddingBottom: 20 }}>
        {equipment.map((item) => (
          <Grid key={item._id} item xs={12} lg={3} xl="auto">
            <EquipmentCard
              key={item._id}
              equipment={item}
              openDrawer={openDrawer}
              updateVisibleEquipment={updateVisibleEquipment}
              openSnackbar={openSnackbar}
            />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  )
};

EquipmentList.propTypes = {
  openDrawer: propTypes.func.isRequired,
  equipment: propTypes.array.isRequired,
  updateVisibleEquipment: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default EquipmentList;
