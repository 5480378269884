import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfDay, compareAsc } from 'date-fns';
import { useLocation } from 'react-router-dom';
import KioskLogo from 'assets/images/Remote-Kiosk-Logo.png';
import routePermissions from 'api/routes/routes.permissions';
import { checkUserPermissions } from 'helpers/_helpers';
import { sendEmail, clearAlert, getPto } from 'store/kiosk/kioskPageOperations';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import Grid from '@material-ui/core/Grid';
import { Snackbar, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { makeStyles } from '@material-ui/core/styles';

import CurrentTime from './components/CurrentTime';
import CurrentUserBlock from './components/CurrentUserBlock';
import ClockInBlock from './components/ClockInBlock';
import PageTabs from './components/PageTabs';
import { useTabs } from './hooks/useTabs';
import { styles } from './styles';
import { useSettings } from './components/AddTimeOff/hooks/useSettings';
import { USER_TIME_OF_DAY } from 'api/users/users.constants';
import DayPicker from './components/DayPicker/DayPicker';
import { SnackbarProvider } from 'notistack';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
const useStyles = makeStyles(styles);

const Kiosk = ({
  selectedUser,
  logoutFromStore,
  userList,
  authorize,
  isRemoteKiosk,
  logout,
  authError,
  clearAuthError,
}) => {
  const { dateFormat } = useDateSettingsFormat();
  const location = useLocation();
  const userPermissions = useSelector((store) => store.personalProfile.fullUserPermissions);
  const locationSettings = useSelector((store) => store.personalProfile.organizationLocation?.settings);
  const currentRoute = location.pathname === '/kiosk';
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [shiftTime, setShiftTime] = useState(null);
  const [currentDate, setCurrentDate] = useState(startOfDay(new Date()));
  const [fullAccess, setFullAccess] = useState(false);
  const { tabs, currentTab, setCurrentTab } = useTabs(fullAccess, locationSettings);
  const { alert, authorizedUser, pto } = useSelector((state) => ({
    authorizedUser: state.kiosk.authorizedUser,
    alert: state.kiosk.alert,
    pto: state.kiosk.pto,
  }));
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { Component } = tabs[currentTab];

  const setNewPto = useCallback((newPto) => {
    dispatch(kioskPageActions.setPto(newPto));
  }, []);

  useEffect(() => {
    const user = authorizedUser || selectedUser;
    const id = user ? user._id : null;
    if (!id) return;
    if (currentTab === tabs.length - 1) {
      authorize({ user, employeeNum: user.profile.employeeNum });
    }
    dispatch(getPto(id));
  }, [currentTab]);

  useEffect(() => {
    if (!settings) return setShiftTime(null);
    const { defaultDayTime, defaultNightTime } = settings;
    if (!authorizedUser && !selectedUser) return setShiftTime(null);
    const {
      profile: {
        shifts: { timeOfDay },
      },
    } = authorizedUser || selectedUser;
    const { hour, minute, amPm } =
      timeOfDay === USER_TIME_OF_DAY.day ? defaultDayTime : defaultNightTime;
    setShiftTime({ hour, minute, amPm });
  }, [authorizedUser, selectedUser, settings]);

  const openSnackbar = (type, text) => {
    if (type === 'error') {
      dispatch(kioskPageActions.setError(text));
    }
    dispatch(kioskPageActions.setAlert({ type, text }));
  };

  const closeSnackbar = () => {
    dispatch(clearAlert());
  };

  const userLogout = () => {
    if (!window.location.pathname.split('/').includes('remote-kiosk')) {
      logoutFromStore();
    }
    if (logout) logout();
  };

  const handleSetError = (payload) => dispatch(kioskPageActions.setError(payload));

  const handleSendToEmail = async () => {
    const obj = {
      email: (authorizedUser && authorizedUser.profile.email) || selectedUser.profile.email,
      date: format(selectedDate, 'M-d-yy'),
      userId: (authorizedUser && authorizedUser._id) || selectedUser._id,
    };
    dispatch(sendEmail(obj));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const dayStart = startOfDay(new Date());
      if (compareAsc(dayStart, currentDate) === 0) return;
      setCurrentDate(dayStart);
    }, 3000);
    return () => clearInterval(timer);
  }, [currentDate]);

  useEffect(() => {
    if (isRemoteKiosk) {
      setFullAccess(checkUserPermissions(routePermissions['Remote Kiosk'], userPermissions));
    } else {
      setFullAccess(true);
    }
  }, [isRemoteKiosk]);

  const displayDate = useMemo(
    () => format(currentDate, GLOBAL_DATE_FORMAT.fullDateWithDay),
    [currentDate]
  );

  return (
    <div className={currentRoute && !selectedUser ? classes.background : null}>
      {alert.type && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={closeSnackbar}
        >
          <Alert
            style={{ display: 'flex', alignItems: 'center' }}
            onClose={closeSnackbar}
            severity={alert.type === 'success' ? 'success' : 'error'}
          >
            <AlertTitle style={{ marginBottom: 0 }}>{alert.text}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
      <Box className={classes.headingWrapper}>
        <Grid style={{ marginLeft: '-10px' }}>
          <img className={classes.imgLogo} src={KioskLogo} alt="logo" />
        </Grid>
        <Box className={classes.emailWrap}>
          {selectedUser && selectedUser.profile.email && fullAccess && (
            <Button
              onClick={handleSendToEmail}
              className={classes.email}
              variant="text"
              color="secondary"
            >
              <EmailOutlinedIcon fontSize="medium" />
            </Button>
          )}
        </Box>
        <Typography variant="caption" className={classes.time}>
          {displayDate}
        </Typography>
      </Box>
      {fullAccess && (
        <div>
          <Box className={classes.mobileCalendar}>
            <DayPicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <CurrentTime classes={classes} />
          </Box>
        </div>
      )}
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <CurrentUserBlock
          authError={authError}
          clearAuthError={clearAuthError}
          selectedUser={selectedUser}
          userList={userList}
          authorize={(data) => {
            authorize(data);
            setCurrentTab(0);
          }}
          showLoginButton={!isRemoteKiosk}
        />
        {locationSettings?.timeclock && fullAccess && (
          <ClockInBlock
            visible={!!selectedUser}
            userId={selectedUser && selectedUser._id}
            openSnackbar={openSnackbar}
            setError={handleSetError}
            showExitButton={!isRemoteKiosk}
            logout={userLogout}
            date={currentDate}
            shouldUpdate={format(currentDate, dateFormat) === format(selectedDate, dateFormat)}
            settings={settings}
          />
        )}
      </Box>
      {selectedUser && (
        <>
          <PageTabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            handleSendToEmail={handleSendToEmail}
            hasEmail={selectedUser && selectedUser.profile.email}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            showDatePicker={currentTab === 0 && selectedUser}
            showSendButton={currentTab === 0 && selectedUser}
            pto={pto}
            locationSettings={locationSettings}
          />
          <SnackbarProvider>
            <Component
              authorizedUser={authorizedUser}
              openSnackbar={openSnackbar}
              setNewPto={setNewPto}
              date={selectedDate}
              userId={selectedUser._id}
              user={selectedUser}
              pto={pto}
              shiftTime={shiftTime}
            />
          </SnackbarProvider>
        </>
      )}
    </div>
  );
};

export default Kiosk;
