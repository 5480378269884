import contractorsPageActions from './contractorsPageActions';
import { getTimeZone } from '../../helpers/getTimeZone';
import $api from 'http/index';

const CONTRACTORS_PER_PAGE = 40

export const fetchContractorsData = (filters = {}, page = 0, searchValue = null) => async (dispatch,getState) => {
  const hasMore = getState().projectTypes.projects;

  if (!hasMore) return

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: {},
  };

  if (page >= 0) {
    options.params.skip = page * CONTRACTORS_PER_PAGE;
    options.params.limit = CONTRACTORS_PER_PAGE;
  }

  if (filters && Object.keys(filters).length) {
    if (filters.sortBy) options.params.sortBy = filters.sortBy;
    if (filters.sortOrder) options.params.sortOrder = filters.sortOrder;
    if (filters.status) options.params.status = filters.status;
  }

  if (searchValue) options.params.searchValue = searchValue;
  // dispatch(contractorsPageActions.contractorsRequest(true))
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/contractors`, options);
    if(page === 0){
      dispatch(contractorsPageActions.getContractorsData({data:response.data, hasMore:response.data.length === CONTRACTORS_PER_PAGE}));
    }else{
      dispatch(contractorsPageActions.getContractorsDataAdd({data:response.data, hasMore:response.data.length === CONTRACTORS_PER_PAGE}));
    }
  } catch (error) {
    dispatch(contractorsPageActions.contractorsError())
  }
};
export const fetchAllContractorsData = (worklogHeaders) => async (dispatch,getState) => {

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: {
      skip:0,
      limit: Infinity,
      status:  "Active",
    },
  };

  if (worklogHeaders?.id) options.headers['x-worklog-id'] = worklogHeaders.id;
  if (worklogHeaders?.create) options.headers['x-worklog-create'] = worklogHeaders.create;
  if (worklogHeaders?.tenantLocationId) options.headers['x-user-location-id'] = worklogHeaders.tenantLocationId;

  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/contractors`, options);
      dispatch(contractorsPageActions.getContractorsData({data:response.data, hasMore:false}));
  } catch (error) {
    dispatch(contractorsPageActions.contractorsError())
  }
};

export const patchContractorsStatus = (data, contractorId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    await $api.patch(`${process.env.REACT_APP_BASE_URL}/contractors/${contractorId}/status`, data, options);
    dispatch(contractorsPageActions.toggleContractorStatus(contractorId));
  } catch (error) {
    dispatch(contractorsPageActions.contractorsError(error));
  }
};

export const fetchDeleteContractors = (contractorId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    await $api.delete(`${process.env.REACT_APP_BASE_URL}/contractors/${contractorId}`, options);
    dispatch(contractorsPageActions.deleteContractor(contractorId));
  } catch (error) {
    dispatch(contractorsPageActions.contractorsError(error));
  }
};

export const postCreateContractor = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

    const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/contractors`, data, options);
    dispatch(contractorsPageActions.createNewContractor(res.data));
};


export const postUpdateContractor = (data, contractorId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

    const res = await $api.put(`${process.env.REACT_APP_BASE_URL}/contractors/${contractorId}`, data, options);
    dispatch(contractorsPageActions.updateContractor(res.data));

};