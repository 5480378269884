export const getSettings = async () => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  if(localStorage.getItem('x-admin-kiosk-token')){
    options.headers['x-admin-kiosk-token'] = localStorage.getItem('x-admin-kiosk-token')
  }else{
    options.headers['X-Auth-Token'] = localStorage.getItem('authorization-token')
  };


  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/organization-locations/settings`, {
    ...options,
    method: 'GET',
  }).then((res) => res.json());

  if (response.statusCode > 299) throw new Error(response.message);

  return response;
};
