import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { states } from 'api/data/states';
import PageLoader from 'components/PageLoader';
import ProjectDetails from './components/ProjectDetails';
// import { geoCoder } from 'api/geocomplete';
import styles from './styles';
import { postCreateProject, postUpdateProject } from 'store/projectTypes/projectTypesOperations';
import _ from 'lodash';
import { MEASUREMENT_SYSTEM } from 'common/measurements';

function validateURL(url) {
  const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\- .\/?%&=+*!'();:@$,]+)?$/;
  return urlPattern.test(url);
}

const validation = (values) => {
  const errors = {};
  if (!values.contractorId.trim()) {
    errors.contractorId = "This field can't be blank";
  }
  if (!values.route.trim()) {
    errors.route = "This field can't be blank";
  }
  if (!values.jobNumber.trim()) {
    errors.jobNumber = "This field can't be blank";
  }
  if (!values.type.trim()) {
    errors.type = "This field can't be blank";
  }
  if (values.baseRate?.trim()) {
    if (!/^\$?\d+((,|\.)\d{1,2})?$/i.test(values.baseRate)) {
      errors.baseRate = 'The number is invalid. The field can contain figures only';
    }
  }
  if (values.fringeBenefit?.trim()) {
    if (!/^\$?\d+((,|\.)\d{1,2})?$/i.test(values.fringeBenefit)) {
      errors.fringeBenefit = 'The number is invalid. The field can contain figures only';
    }
  }
  const locations = {};
  values.locations.forEach((el, i) => {
    if (!el.name.trim() && !el.address.trim() && !el.zipcode.trim()) return; //if 3 location fields are empty - it's not an error

    const locationsErr = {};
    if (!el.name.trim()) {
      locationsErr.name = "This field can't be blank";
    }
    if (!el.address.trim()) {
      locationsErr.address = "This field can't be blank";
    }
    if (Object.keys(locationsErr).length) {
      locations[i] = locationsErr;
    }
  });
  if (Object.keys(locations).length) {
    errors.locations = locations;
  }

  const rallyPoints = {};
  values.rallyPoints.forEach((el, i) => {
    if (!el.name.trim() && !el.address.trim() && !el.zipcode.trim()) return; //if 3 location fields are empty - it's not an error

    const locationsErr = {};
    if (!el.name.trim()) {
      locationsErr.name = "This field can't be blank";
    }
    if (!el.address.trim()) {
      locationsErr.address = "This field can't be blank";
    }
    if (Object.keys(locationsErr).length) {
      rallyPoints[i] = locationsErr;
    }
  });
  if (Object.keys(rallyPoints).length) {
    errors.rallyPoints = rallyPoints;
  }
  const newDocument = {};

  if (values.newDocument.name.trim() || values.newDocument.url.trim()) {
    if (!values.newDocument.name.trim()) {
      newDocument.name = "This field can't be blank";
    }
    if (values.newDocument.url.trim() && !validateURL(values.newDocument.url)) {
      newDocument.url = 'This field must be a URL address';
    }
    if (!values.newDocument.url.trim()) {
      newDocument.url = "This field can't be blank";
    }
  }

  if (Object.keys(newDocument).length) {
    errors.newDocument = newDocument;
  }

  return errors;
};

const ProjectForm = ({ classes, closeDrawer, openSnackbar, project, type, locationSettings }) => {
  const contractors = useSelector((store) => store.contractors.data);
  const settings = useSelector((store) => store.personalProfile?.organization?.settings)
  const dispatch = useDispatch();
  const [statesData, setStatesData] = useState([]);
  const [fullyProjectsDocuments, setFullyProjectsDocuments] = useState([]);
  const [addForm, setAddForm] = useState(false);

  useEffect(() => {
    if (project) {
      setFullyProjectsDocuments(
        (project?.documentStorage?.length && project.documentStorage) || []
      );
    }
  }, [project]);

  const handleSelectDocument = (setFieldValue) => (e) => {
    const id = e.target.value;

    if (id === 'add' || id === '') {
      handleDocumentAddForm(true);
      setFieldValue('selectedDocumentId', '');
      setFieldValue('newDocument', {
        name: '',
        url: '',
      });
      return;
    }

    handleDocumentAddForm(true);
    const currentFullyContact = fullyProjectsDocuments.find((el) => el.name === id);
    setFieldValue('newDocument.name', currentFullyContact.name);
    setFieldValue('newDocument.url', currentFullyContact.url);
    setFieldValue('selectedDocumentId', id);
  };

  const handleDocumentAddForm = (value) => {
    setAddForm(value);
  };

  useEffect(() => {
    setStatesData(states);
  }, []);

  const removeLocationFields = (arrayHelpers, index, values) => () => {
    if (values[arrayHelpers.name].length == 1) {
      arrayHelpers.remove(index);
      arrayHelpers.push({
        name: '',
        address: '',
        zipcode: '',
        type: arrayHelpers.name === 'rallyPoints' ? 'rallyPoint' : 'projectLocation',
      });
    } else {
      arrayHelpers.remove(index);
    }
  };

  const getLatLong = (setFieldValue, index) => (latLong) => {
    setFieldValue(`locations[${index}].address`, latLong);
  };

  const handleChangeAddress = (setFieldValue, index, values, arrayHelpers, fieldName) => (e) => {
    setFieldValue(`${fieldName}[${index}].address`, e.target.value);
    if (values[fieldName].length === index + 1) {
      arrayHelpers.push({
        name: '',
        address: '',
        zipcode: '',
        type: fieldName === 'rallyPoints' ? 'rallyPoint' : 'projectLocation',
      });
    }
    // geoCoder(e.target.value, getLatLong(setFieldValue, index));//!epic mock
  };

  const handleTimeFieldsKeyDown = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
      e.preventDefault();
    }
  };

  const handleSelectContractor = (setFieldValue) => (e) => {
    const contractorId = e.target.value;
    setFieldValue('contractorId', contractorId);
  };

  const initialValues = project
    ? {
        jobNumber: project.jobNumber,
        type: project.type,
        rated: project.rated,
        route: project.route,
        section: project.section || '',
        earningsCode: project.earningsCode || '',
        shift: project.shift,
        contractorId: project.contractorId,
        state: project.state || '',
        county: project.county || '',
        id: project.id || '',
        locations: [
          ...(project.locations || []),
          {
            name: '',
            address: '',
            zipcode: '',
            type: 'projectLocation',
          },
        ],
        rallyPoints: [
          ...(project.rallyPoints || []),
          {
            name: '',
            address: '',
            zipcode: '',
            type: 'rallyPoint',
          },
        ],
        // removeLocations: [],
        baseRate: project.baseRate ? String(project.baseRate) : '',
        fringeBenefit: project.fringeBenefit ? String(project.fringeBenefit) : '',
        isUpdateType: true,
        active: project.active,
        documentStorage: project.documentStorage || [],
        selectedDocumentId: '',
        newDocument: {
          name: '',
          url: '',
        },
        measurement: project.measurement || settings?.measurement || MEASUREMENT_SYSTEM.imperial
      }
    : {
        jobNumber: '',
        type: '',
        rated: false,
        route: '',
        section: '',
        earningsCode: '',
        shift: 'Day',
        contractorId: '',
        state: '',
        county: '',
        id: '',
        locations: [
          {
            name: '',
            address: '',
            zipcode: '',
            type: 'projectLocation',
          },
        ],
        rallyPoints: [
          {
            name: '',
            address: '',
            zipcode: '',
            type: 'rallyPoint',
          },
        ],
        baseRate: '',
        fringeBenefit: '',
        isUpdateType: false,
        active: true,
        documentStorage: [],
        selectedDocumentId: '',
        newDocument: {
          name: '',
          url: '',
        },
        measurement: settings?.measurement || MEASUREMENT_SYSTEM.imperial,
      };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        // enableReinitialize={true}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const data = {
            active: values.active,
            jobNumber: values.jobNumber,
            type: values.type,
            rated: values.rated,
            route: values.route,
            section: values.section,
            earningsCode: values.earningsCode,
            shift: values.shift,
            contractorId: values.contractorId,
            state: values.state,
            county: values.county,
            id: values.id,
            locations: values.locations.filter((location) => location.address.trim()),
            // addLocations: addLocations,
            rallyPoints: values.rallyPoints.filter((location) => location.address.trim()),
            // removeLocations: removeLocations,
            baseRate: values.baseRate?.trim(),
            fringeBenefit: values.fringeBenefit?.trim(),
            documentStorage:
              fullyProjectsDocuments
                .filter((doc) => doc.name.trim() && doc.url.trim())
                .map((doc) => ({
                  name: doc.name.trim(),
                  url: doc.url.trim(),
                })) || [],
            measurement: values.measurement,
          };

          if (type === 'create') {
            try {
              await dispatch(postCreateProject(data));
              closeDrawer();
              openSnackbar('success', 'Successfully Added!');
            } catch (e) {
              openSnackbar('error', e.response.data.message);
              setSubmitting(false);
              if (e.error === 'job-number-not-unique') {
                setFieldError('jobNumber', 'This Job Number is already taken');
              }
            }
          } else {
            try {
              await dispatch(postUpdateProject(data, project._id));
              closeDrawer();
              openSnackbar('success', 'Successfully Updated!');
            } catch (e) {
              openSnackbar('error', e.response.data.message);
              setSubmitting(false);
              if (e.error === 'job-number-not-unique') {
                setFieldError('jobNumber', 'This Job Number is already taken');
              }
            }
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setErrors,
          validateForm,
        }) => (
          <PageLoader loading={false}>
            {/* <PageLoader loading={isSubmitting}> */}
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '40px' }}>
                  {type === 'create' ? 'Create' : 'Edit'} Project
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <ProjectDetails
                  contractors={contractors}
                  openSnackbar={openSnackbar}
                  isSubmitting={isSubmitting}
                  project={project}
                  states={statesData}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  removeLocationFields={removeLocationFields}
                  handleChangeAddress={handleChangeAddress}
                  type={type}
                  handleTimeFieldsKeyDown={handleTimeFieldsKeyDown}
                  handleSelectContractor={handleSelectContractor}
                  setErrors={setErrors}
                  validateForm={validateForm}
                  fullyProjectsDocuments={fullyProjectsDocuments}
                  handleSelectDocument={handleSelectDocument}
                  addForm={addForm}
                  handleDocumentAddForm={handleDocumentAddForm}
                  setFullyProjectsDocuments={setFullyProjectsDocuments}
                  locationSettings={locationSettings}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

ProjectForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  project: propTypes.object,
};

export default withStyles(styles)(ProjectForm);
