export default (theme) => ({
  error: {
    color: theme.palette.textColor.red,
    // padding: '0 5px',
    fontSize: 12
  },
  textField: {
    maxWidth: '100%',
    '@media (max-width: 573px)': {
      width: 500,
    },
  },
})