import settingsAction from './settingsAction';
import $api from 'http/index';
import { getTimeZone } from 'helpers/getTimeZone';
import { getOptions } from 'helpers/getOptions';
import personalProfile from 'store/personalProfile/personalProfileReducer';
import personalProfileActions from 'store/personalProfile/personalProfileActions';

export const getSettings = (cb) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const locationResponse = await $api.get(`${process.env.REACT_APP_BASE_URL}/organization-locations/settings`, options);
    dispatch(settingsAction.getLocationSettings(locationResponse.data));

    const organizationResponse = await $api.get(`${process.env.REACT_APP_BASE_URL}/organization/settings`, options);
    dispatch(settingsAction.getSettings(organizationResponse.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  } finally {
    if (cb) cb();
  }
};

export const patchSettings = (data) => async (dispatch) => {
  const options =
    data?.requestType === 'form-data'
      ? {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Auth-Token': localStorage.getItem('authorization-token'),
            'X-User-TZ': getTimeZone(),
          },
        }
      : getOptions();
  try {
    const locationSettings = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/organization-locations/${data?.path}`,
      data?.data,
      options
    );
    dispatch(settingsAction.updateLocationSettings(locationSettings.data));
    dispatch(personalProfileActions.setOrganizationLocationSettings(locationSettings.data));
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchGetImage = (imageKey) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/organization-locations/settings/homepage-image?imageKey=${imageKey}`,
      options
    );

    dispatch(settingsAction.getImage(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};


export const getLocations = (filters = {}) => async (dispatch) => {
  const options = getOptions();

  options.params = {
    ...(options.params || {}),
    ...filters,
  }

  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/organization-locations`,
      options,
    )

    dispatch(settingsAction.getLocations(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
}

export const postLocation = (data) => async (dispatch) => {
  const options = getOptions()

  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/organization-locations`,
      data,
      options,
    );

    dispatch(settingsAction.postLocation(response.data))
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
}

export const updateLocation = (locationId, data) => async (dispatch) => {
  const options = getOptions()

  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/organization-locations/${locationId}`,
      data,
      options,
    );

    dispatch(settingsAction.updateLocation(response.data))
    dispatch(personalProfileActions.updateOrganizationLocation(response.data))
  } catch (error) {
    if (error?.response?.data?.statusCode > 299) throw new Error(error?.response?.data?.message || error.message);
  }
}

export const updateMultiLocation = (data) => async (dispatch) => {
  const options = getOptions();

  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/organization/settings/multi-location`,
      data,
      options,
    );

    dispatch(settingsAction.updateSettings(response.data?.settings))
    dispatch(personalProfileActions.setOrganization(response.data))
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
}