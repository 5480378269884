import scheduleActions from '../schedulePageActions';
import { WORKER_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';


const handlers = {
  [WORKER_EVENT.created]: (store) => (data) => {
    if (data?._id && data?.profile?.shifts?.timeOfDay !== 'Inactive' && data?.profile?.role?.extPermissions?.activeSchedule) {
      store.dispatch(scheduleActions.addWorker(data));
    }
    store.dispatch(scheduleActions.addToAllWorkers(data));
  },
  [WORKER_EVENT.shiftChanged]: (store) => (data) => {
    if (data._id) {
      if (data.profile?.shifts?.timeOfDay === 'Inactive') {
        store.dispatch(scheduleActions.removeWorkerResource(data));
      } else {
        const workerInWorkers = store.getState().schedule.resources.workers.find(w => w._id === data._id);
        const workerInAllWorkers = store.getState().schedule.resources.allWorkers.find(w => w._id === data._id);

        if (!workerInWorkers) store.dispatch(scheduleActions.addWorker(data));
        if (!workerInAllWorkers) store.dispatch(scheduleActions.addToAllWorkers(data));

        if (workerInWorkers || workerInAllWorkers) {
          store.dispatch(scheduleActions.updateWorkerResource(data));
        }
      }
    }
  },
  [WORKER_EVENT.activeScheduleChanged]: (store) => (data) => {
    if (data?._id) {
      const workers = store.getState().schedule.resources.workers;
      const allWorkers = store.getState().schedule.resources.allWorkers;

      const workerInWorkers = workers.find(w => w._id === data._id);
      const workerInAllWorkers = allWorkers.find(w => w._id === data._id);

      if (data?.profile?.role?.extPermissions?.activeSchedule) {
        if (!workerInWorkers) store.dispatch(scheduleActions.addWorker(data));
        if (!workerInAllWorkers) store.dispatch(scheduleActions.addToAllWorkers(data));
      } else {
        if (workerInWorkers) store.dispatch(scheduleActions.removeWorkerResource(data));
      }
    }
  },
  [WORKER_EVENT.usernameChanged]: (store) => (data) => {
    if (data?._id) {
      const workerInWorkers = store.getState().schedule.resources.workers.find(w => w._id === data._id);
      const workerInAllWorkers = store.getState().schedule.resources.allWorkers.find(w => w._id === data._id);
      if (workerInWorkers || workerInAllWorkers) {
        store.dispatch(scheduleActions.updateWorkerResource(data));
      }
    }
  },
  [WORKER_EVENT.deleted]: (store) => (data) => {
    if (data._id) {
      store.dispatch(scheduleActions.removeWorkerResource(data));
    }
  }
}

export const workersSubscriptionMiddleware = subscriptionsFabric(
  scheduleActions,
  WORKER_EVENT,
  handlers,
  { assignedToLocations: (store) => store.getState().schedule.showAllLocations }
);