import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import styles from './styles';
import { isIOS, Option } from '../../../../../../components/Option';
import { Controller } from 'react-hook-form';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';
import WeekShift from './WeekShift/WeekShift';
import IOSSwitch from 'components/IOSSwitch/IOSSwitch';

const ShiftsBlock = ({ classes, pageType, hours, minutes, dayPart, errors, control, getValues, watch }) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const {
    defaultDayTime = {},
    defaultNightTime = {},
    scheduleNotifications,
  } = useSelector((state) => state.settings.locationSettings);

  const renderShiftTime = (shiftType, shiftTypeValue) => {
    const { hour, minute, amPm } = convertTimeObject(shiftTypeValue, is12Format);

    if (!shiftTypeValue?.hour) return;
    return (
      <Grid item xs={12}>
        <label>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Start Time:
          </Typography>
        </label>
        <div className={classes.tripleSelectWrapper}>
          <Controller
            name={`${shiftType}.hour`}
            control={control}
            defaultValue={hour}
            as={
              <TextField
                select
                id={`${shiftType}.hour`}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Start time hours' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {hours.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </TextField>
            }
          />
          :
          <Controller
            name={`${shiftType}.minute`}
            control={control}
            defaultValue={minute}
            as={
              <TextField
                select
                id={`${shiftType}.minute`}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Start time minutes' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {minutes.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </TextField>
            }
          />
          {is12Format && (
            <Controller
              name={`${shiftType}.amPm`}
              control={control}
              defaultValue={amPm}
              as={
                <TextField
                  select
                  id={`${shiftType}.amPm`}
                  className={classes.textField}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Start time' }}
                  fullWidth
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {dayPart.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
              }
            />
          )}
        </div>
      </Grid>
    );
  };

  const renderAllowedTimeoff = () => {
    return (
      <Grid item direction={'column'}>
        <label htmlFor="allowedTimeoff">
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Default Paid Time Off:
          </Typography>
        </label>
        <Grid container item direction={'row'} alignItems={'center'}>
          <Controller
            name="allowedTimeoff"
            control={control}
            as={
              <TextField
                id="allowedTimeoff"
                type="number"
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Default Paid Time Off' }}
              />
            }
          />
          <Typography variant="body1" className={classes.ptoHoursText}>
            hours
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderHQLocation = () => {
    return (
      <Grid container item direction={'column'} alignItems={'left'}>
        <label htmlFor="headquartersLocation">
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Headquarters Address:
          </Typography>
        </label>

        <Controller
          name="headquartersLocation"
          control={control}
          as={
            <TextField
              id="headquartersLocation"
              name="headquartersLocation"
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Headquarter Location' }}
              fullWidth={true}
            />
          }
        />
      </Grid>
    );
  };

  return (
    <Grid item xs={12} md={12}>
      <Grid xs={12} container spacing={5}>
        <Grid item container xs={12} md={6}>
          <Typography variant="h4">General</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5}>
        <Grid container item alignItems={'center'} xs={12} md={4}>
          {renderHQLocation()}
        </Grid>
        <Grid container item alignItems={'center'} xs={12} md={8}>
          {renderAllowedTimeoff()}
        </Grid>
        <Grid item xs={12} md={3} className={classes.scheduleNotificationBlock}>
          <Controller
            name="updateScheduleNotifications"
            control={control}
            defaultValue={scheduleNotifications || false}
            render={({ name, value, onChange }) => (
              <IOSSwitch
                name={name}
                small
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
          <span className={classnames(classes.secondaryText, classes.inlineBlock)}>
            Schedule Notifications
          </span>
        </Grid>

        <>
          <WeekShift pageType={pageType} errors={errors} control={control} getValues={getValues} watch={watch} />
        </>
        <Grid container item alignItems={'center'} xs={12} md={12}>
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
              Shift:
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(classes.fieldValue, classes.paddingTop)}
            >
              <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
              Day
            </Typography>
          </Grid>
          <Grid container item alignItems={'center'} xs={9} md={4} lg={3}>
            {renderShiftTime('defaultDayTime', defaultDayTime)}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} style={{ marginBottom: 20 }}>
        <Grid container item alignItems={'center'} xs={12} md={12}>
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
              Shift:
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(classes.fieldValue, classes.paddingTop)}
            >
              <img src={IconMoon} alt="moon" className={classes.shiftImage} />
              Night
            </Typography>
          </Grid>
          <Grid container item alignItems={'center'} xs={9} md={4} lg={3}>
            {renderShiftTime('defaultNightTime', defaultNightTime)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ShiftsBlock.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  hours: propTypes.array.isRequired,
  minutes: propTypes.array.isRequired,
  dayPart: propTypes.array.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(ShiftsBlock);
