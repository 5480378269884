import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { FieldArray } from 'formik';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import ProjectTypes from '../ProjectTypes';
import styles from './styles';
import { isIOS, Option } from '../../../../../../../../components/Option';
import DocumentStorage from '../DocumentStorage/DocumentStorage';
import { MEASUREMENT_SYSTEM } from 'common/measurements';

const ProjectDetails = ({
  classes,
  contractors,
  states,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  removeLocationFields,
  handleChangeAddress,
  type,
  project,
  openSnackbar,
  isSubmitting,
  handleSelectContractor,
  fullyProjectsDocuments,
  handleSelectDocument,
  addForm,
  handleDocumentAddForm,
  setFullyProjectsDocuments,
  validateForm,
  setErrors,
  locationSettings,
}) => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="jobNumber">
            <Typography variant="body1" color="textSecondary">
              Job number<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              id="jobNumber"
              name="jobNumber"
              value={values.jobNumber}
              error={Boolean(errors.jobNumber) && Boolean(touched.jobNumber)}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Job Number' }}
              fullWidth
              disabled={type === 'update'}
            />
            {errors.jobNumber && touched.jobNumber ? (
              <div className={classes.error}>{errors.jobNumber}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="type">
            <Typography variant="body1" color="textSecondary">
              Type<span className={classes.requiredSign}>*</span>:
            </Typography>
            <ProjectTypes
              value={values.type}
              setFieldValue={setFieldValue}
              project={project}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              openSnackbar={openSnackbar}
              isSubmitting={isSubmitting}
            />
            {errors.type && touched.type ? (
              <div className={classes.error}>{errors.type}</div>
            ) : null}
          </label>
        </Grid>
        {locationSettings?.timeclock && (
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="rated">
              <Typography variant="body1" color="textSecondary">
                Certified Payroll:
              </Typography>
              <TextField
                select
                id="rated"
                name="rated"
                className={classes.textField}
                value={values.rated}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                inputProps={{ 'aria-label': 'Rated' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                <Option key={1} value={false}>
                  No
                </Option>
                <Option key={2} value={true}>
                  Yes
                </Option>
              </TextField>
            </label>
          </Grid>
        )}
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="route">
            <Typography variant="body1" color="textSecondary">
              Project Name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              id="route"
              name="route"
              className={classes.textField}
              value={values.route}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.route) && Boolean(touched.route)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Route' }}
              fullWidth
            />
            {errors.route && touched.route ? (
              <div className={classes.error}>{errors.route}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="section">
            <Typography variant="body1" color="textSecondary">
              Section:
            </Typography>
            <TextField
              id="section"
              name="section"
              className={classes.textField}
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.section) && Boolean(touched.section)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Section' }}
              fullWidth
            />
            {errors.section && touched.section ? (
              <div className={classes.error}>{errors.section}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="shift">
            <Typography variant="body1" color="textSecondary">
              Shift:
            </Typography>
            <TextField
              select
              id="shift"
              name="shift"
              className={classes.textField}
              value={values.shift}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Shift' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value="Day">
                {isIOS ? '🌤 ' : <img src={IconSunny} alt="sunny" className={classes.shiftImage} />}
                Day
              </Option>
              <Option key={2} value="Night">
                {isIOS ? '🌙 ' : <img src={IconMoon} alt="moon" className={classes.shiftImage} />}
                Night
              </Option>
            </TextField>
          </label>
        </Grid>
        {locationSettings?.timeclock && (
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="earningsCode">
              <Typography variant="body1" color="textSecondary">
                Earnings code:
              </Typography>
              <TextField
                id="earningsCode"
                name="earningsCode"
                className={classes.textField}
                value={values.earningsCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.earningsCode) && Boolean(touched.earningsCode)}
                variant="outlined"
                inputProps={{ 'aria-label': 'Earnings code' }}
                fullWidth
              />
              {errors.earningsCode && touched.earningsCode ? (
                <div className={classes.error}>{errors.earningsCode}</div>
              ) : null}
            </label>
          </Grid>
        )}
        {locationSettings?.timeclock && (
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="baseRate">
              <Typography variant="body1" color="textSecondary">
                Base rate:
              </Typography>
              <TextField
                id="baseRate"
                name="baseRate"
                className={classes.textField}
                value={values.baseRate}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.baseRate) && Boolean(touched.baseRate)}
                variant="outlined"
                inputProps={{ 'aria-label': 'Base rate' }}
                fullWidth
              />
              {errors.baseRate && touched.baseRate ? (
                <div className={classes.error}>{errors.baseRate}</div>
              ) : null}
            </label>
          </Grid>
        )}
        {locationSettings?.timeclock && (
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="fringeBenefit">
              <Typography variant="body1" color="textSecondary">
                Fringe benefit:
              </Typography>
              <TextField
                id="fringeBenefit"
                name="fringeBenefit"
                className={classes.textField}
                value={values.fringeBenefit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.fringeBenefit) && Boolean(touched.fringeBenefit)}
                variant="outlined"
                inputProps={{ 'aria-label': 'Fringe benefit' }}
                fullWidth
              />
              {errors.fringeBenefit && touched.fringeBenefit ? (
                <div className={classes.error}>{errors.fringeBenefit}</div>
              ) : null}
            </label>
          </Grid>
        )}
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="active">
            <Typography variant="body1" color="textSecondary">
              Status:
            </Typography>
            <TextField
              select
              id="active"
              name="active"
              value={values.active}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Status' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value={true}>
                Active
              </Option>
              <Option key={2} value={false}>
                Inactive
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
          <label htmlFor="measurement">
            <Typography variant="body1" color="textSecondary">
              Measurement System:
            </Typography>
            <TextField
              select
              id="measurement"
              name="measurement"
              value={values.measurement}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Measurement System' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option value={MEASUREMENT_SYSTEM.imperial}>
                Imperial System
              </Option>
              <Option value={MEASUREMENT_SYSTEM.metric}>
                Metric System
              </Option>
            </TextField>
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <label htmlFor="contractorId">
            <Typography variant="body1" color="textSecondary">
              Contractor<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              select
              id="contractorId"
              name="contractorId"
              className={classes.textField}
              value={values.contractorId}
              onChange={handleSelectContractor(setFieldValue)}
              onBlur={handleBlur}
              error={Boolean(errors.contractorId) && Boolean(touched.contractorId)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Contractor' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={0} value="" />
              {contractors
                .filter((el) => el.active)
                .map((contractor) => (
                  <Option key={contractor._id} value={contractor._id}>
                    {contractor.name}
                  </Option>
                ))}
            </TextField>
            {errors.contractorId && touched.contractorId ? (
              <div className={classes.error}>{errors.contractorId}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={4}>
          <label htmlFor="state">
            <Typography variant="body1" color="textSecondary">
              State:
            </Typography>
            <TextField
              select
              id="state"
              name="state"
              className={classes.textField}
              value={values.state}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'State' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={0} value="" />
              {states.map((state) => (
                <Option key={state.name} value={state.abrev}>
                  {state.name}
                </Option>
              ))}
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} lg={4}>
          <label htmlFor="county">
            <Typography variant="body1" color="textSecondary">
              County:
            </Typography>
            <TextField
              id="county"
              name="county"
              className={classes.textField}
              value={values.county}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.county) && Boolean(touched.county)}
              variant="outlined"
              inputProps={{ 'aria-label': 'County' }}
              fullWidth
            />
            {errors.county && touched.county ? (
              <div className={classes.error}>{errors.county}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ padding: 0, margin: 16 }}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <label htmlFor="id">
            <Typography variant="body1" color="textSecondary">
              Project ID:
            </Typography>
            <TextField
              id="id"
              name="id"
              className={classes.textField}
              value={values.id}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.id) && Boolean(touched.id)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Project ID' }}
              fullWidth
            />
            {errors.id && touched.id ? <div className={classes.error}>{errors.id}</div> : null}
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Typography variant="h4" style={{ margin: '15px 0' }}>
        Rally Points
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FieldArray
            name="rallyPoints"
            render={(arrayHelpers) => (
              <div>
                {values.rallyPoints.map((location, index) => (
                  <Grid container spacing={4} key={index} className={classes.locationContainer}>
                    <Grid
                      item
                      xs={10}
                      lg={4}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          Rally Point:
                        </Typography>
                      )}
                      <TextField
                        id={`rallyPoints[${index}].name`}
                        name={`rallyPoints[${index}].name`}
                        className={classes.textField}
                        error={Boolean(
                          errors.rallyPoints &&
                            errors.rallyPoints[index] &&
                            errors.rallyPoints[index].name
                        )}
                        value={values.rallyPoints[index].name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Name' }}
                        placeholder="Nickname"
                        fullWidth
                      />
                      {errors.rallyPoints &&
                      errors.rallyPoints[index] &&
                      errors.rallyPoints[index].name ? (
                        <div className={classes.error}>{errors.rallyPoints[index].name}</div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      lg={4}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          Street:
                        </Typography>
                      )}
                      <TextField
                        id={`rallyPoints[${index}].address`}
                        name={`rallyPoints[${index}].address`}
                        className={classes.textField}
                        error={Boolean(
                          errors.rallyPoints &&
                            errors.rallyPoints[index] &&
                            errors.rallyPoints[index].address
                        )}
                        value={values.rallyPoints[index].address}
                        onChange={handleChangeAddress(
                          setFieldValue,
                          index,
                          values,
                          arrayHelpers,
                          'rallyPoints'
                        )}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Address' }}
                        placeholder="Street Address or Lat,Long"
                        fullWidth
                      />
                      {errors.rallyPoints &&
                      errors.rallyPoints[index] &&
                      errors.rallyPoints[index].address ? (
                        <div className={classes.error}>{errors.rallyPoints[index].address}</div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      lg={3}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          ZIP:
                        </Typography>
                      )}
                      <TextField
                        id={`rallyPoints[${index}].zipcode`}
                        name={`rallyPoints[${index}].zipcode`}
                        className={classes.textField}
                        value={values.rallyPoints[index].zipcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Zip Code' }}
                        placeholder="Zip Code"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      {/* {index !== 0 && ( */}
                      <IconButton
                        className={classnames(
                          classes.deleteButton,
                          index == 0 && classes.legendAlign
                        )}
                        onClick={removeLocationFields(arrayHelpers, index, values)}
                        disableRipple
                      >
                        <CloseIcon />
                      </IconButton>
                      {/* )} */}
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Typography variant="h4" style={{ margin: '15px 0' }}>
        Project Locations
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FieldArray
            name="locations"
            render={(arrayHelpers) => (
              <div>
                {values.locations.map((location, index) => (
                  <Grid container spacing={4} key={index} className={classes.locationContainer}>
                    <Grid
                      item
                      xs={10}
                      lg={4}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          Project Location:
                        </Typography>
                      )}
                      <TextField
                        id={`locations[${index}].name`}
                        name={`locations[${index}].name`}
                        className={classes.textField}
                        error={Boolean(
                          errors.locations &&
                            errors.locations[index] &&
                            errors.locations[index].name
                        )}
                        value={values.locations[index].name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Name' }}
                        placeholder="Nickname"
                        fullWidth
                      />
                      {errors.locations &&
                      errors.locations[index] &&
                      errors.locations[index].name ? (
                        <div className={classes.error}>{errors.locations[index].name}</div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      lg={4}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          Street:
                        </Typography>
                      )}
                      <TextField
                        id={`locations[${index}].address`}
                        name={`locations[${index}].address`}
                        className={classes.textField}
                        error={Boolean(
                          errors.locations &&
                            errors.locations[index] &&
                            errors.locations[index].address
                        )}
                        value={values.locations[index].address}
                        onChange={handleChangeAddress(
                          setFieldValue,
                          index,
                          values,
                          arrayHelpers,
                          'locations'
                        )}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Address' }}
                        placeholder="Street Address or Lat,Long"
                        fullWidth
                      />
                      {errors.locations &&
                      errors.locations[index] &&
                      errors.locations[index].address ? (
                        <div className={classes.error}>{errors.locations[index].address}</div>
                      ) : null}
                      {/* <GoogleSearchField
                        name={`locations[${index}].address`}//!epic mock google location
                        value={values.locations[index].address}
                        onChange={handleChangeAddress(setFieldValue, index, values, arrayHelpers)}
                        onBlur={handleBlur}
                        index={index}
                        setFieldValue={setFieldValue}
                      /> */}
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      lg={3}
                      className={classnames(index && classes.zeroPaddingTop)}
                    >
                      {index === 0 && (
                        <Typography variant="body1" color="textSecondary">
                          ZIP:
                        </Typography>
                      )}
                      <TextField
                        id={`locations[${index}].zipcode`}
                        name={`locations[${index}].zipcode`}
                        className={classes.textField}
                        value={values.locations[index].zipcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Zip Code' }}
                        placeholder="Zip Code"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      <IconButton
                        className={classnames(
                          classes.deleteButton,
                          index == 0 && classes.legendAlign
                        )}
                        onClick={removeLocationFields(arrayHelpers, index, values)}
                        disableRipple
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <DocumentStorage
        classes={classes}
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        fullyProjectsDocuments={fullyProjectsDocuments}
        handleSelectDocument={handleSelectDocument}
        addForm={addForm}
        handleDocumentAddForm={handleDocumentAddForm}
        setFullyProjectsDocuments={setFullyProjectsDocuments}
        validateForm={validateForm}
        setErrors={setErrors}
      />
    </>
  );
};

ProjectDetails.propTypes = {
  classes: propTypes.object.isRequired,
  contractors: propTypes.array.isRequired,
  states: propTypes.array.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  removeLocationFields: propTypes.func.isRequired,
  handleChangeAddress: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  handleTimeFieldsKeyDown: propTypes.func.isRequired,
};

export default withStyles(styles)(ProjectDetails);
