import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { FieldArray } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { permLabels, extPermissions, changePermission, userTypesArray, filterSettingsTimeclockPermissions } from 'common/permissions';
import IOSSwitch from 'components/IOSSwitch';
import { isIOS, Option } from 'components/Option';
import UserTypeInfo from './components/UserTypeInfo';

import styles from './styles';
import LocationAccessPermission from 'components/LocationAccessPermission/LocationAccessPermission';
import { useSelector } from 'react-redux';


const UserPermissions = ({
  classes,
  values,
  setFieldValue,
  handleChange,
  currentUserId,
  errors,
  setErrors,
}) => {
  const [permissions, setPermissions] = useState({});
  const locationSettings = useSelector(store => store.personalProfile?.organizationLocation?.settings);

  const updatePermissions = (role) => {
    if (!extPermissions[role]) {
      return setPermissions({});
    }

    let userPermissions = extPermissions[role];
    if (!locationSettings?.timeclock) {
      userPermissions = userPermissions.filter(permission => !filterSettingsTimeclockPermissions.includes(permission));
    }
    const nextPermissions = userPermissions.reduce((res, permission) => ({ ...res, [permission]: !!values.permissions[permission] }), {});

    setFieldValue(`permissions`, nextPermissions);
    setPermissions(nextPermissions)
  }

  useEffect(() => {
    updatePermissions(values?.userType);
  }, [values?.userType, locationSettings]);


  const handleChangePermission = useCallback((permission) => () => {
    const newPermissions = changePermission(values.userType, values.permissions, permission);
    
    setPermissions({ ...newPermissions });
    setFieldValue('permissions', { ...newPermissions });
  }, [values, setFieldValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor="userType">
            <Typography variant="body1" color="textSecondary">
              User type:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  id="userType"
                  name="userType"
                  value={values.userType}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'User type' }}
                  fullWidth
                  SelectProps={{
                    native: isIOS,
                  }}
                  disabled={values.userId === currentUserId}
                >
                  {userTypesArray.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={8}>
                <UserTypeInfo type={values.userType} />
              </Grid>
            </Grid>
          </label>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {Object.keys(permissions).length !== 0 && (
        <Grid
          container
          spacing={2}
          className={classes.marginSmall}
          justifyContent="space-between"
        >
          {Object.keys(permissions)
            .sort((a, b) => a.localeCompare(b))
            .map((permission) => (
              <Grid
                item
                xs={12}
                md={5}
                lg={5}
                key={`__user-permission__${permission}__`}
              >
                <FormControlLabel
                  label={permLabels[permission] + ':'}
                  labelPlacement="start"
                  control={
                    <IOSSwitch
                      checked={permissions[permission]}
                      onChange={handleChangePermission(permission)}
                      name={permission}
                      value={permissions[permission]}
                      disabled={values.userId === currentUserId}
                    />
                  }
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* {permissions.multiLocations && <Divider />} */}
      {permissions.multiLocations && (  
        <Grid
          container
        >
          <FieldArray name="assignedToLocations">
            {({ remove, push, move, replace }) => (
              <LocationAccessPermission
                homeBaseLocationId={values.homeBaseLocationId}
                useSettingsLocations={true}
                values={values.assignedToLocations}
                remove={remove}
                push={push}
                move={move}
                replace={replace}
                setFieldValue={setFieldValue}
                error={errors?.assignedToLocations}
                onChange={() => setErrors({ 'assignedToLocations': '' })}
              />
            )}
          </FieldArray>
        </Grid>
      )}
    </>
  )
}

UserPermissions.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object,
  handleChange: propTypes.func,
  setFieldValue: propTypes.func,
  currentUserId: propTypes.string.isRequired,
};

export default withStyles(styles)(UserPermissions);
